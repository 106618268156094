/** @format */

import {
  Grid,
  Typography,
  Divider,
  Button,
  Drawer,
  Chip,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  getOffersByToken,
  nextStepByToken,
  acceptOfferByToken,
} from "../../api/negotiation";
import getSymbolFromCurrency from "@rea-app/currency-symbol-map";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PreferencesPage from "./PreferencesPage";

const OffersPageComponent = (props) => {
  const [openDetails, setOpenDetails] = useState({});
  const [openNeither, setOpenNeither] = useState();
  const [selectedOffer, setSelectedOffer] = useState();
  const [openNonCustomized, setOpenNonCustomized] = useState(false);
  const [Continue, setContinue] = useState(false);
  const [value, setValue] = useState(0);
  const [text, setText] = useState("");
  const { token, negotiation, setNegotiation, setMessage } = props;
  const [offers, setOffers] = useState(null);
  const [note, setNote] = useState("");
  const [showFeatures, setShowFeatures] = useState(false);
  useEffect(() => {
    getOffersByToken(token)
      .then((resp) => setOffers(resp.data))
      .catch(() => setMessage("An error occured."));
  }, [token, negotiation]);

  const handleSelected = () => {
    const choice = selectedOptions[0];
    nextStepByToken(token, { choice })
      .then((resp) => setNegotiation(resp.data))
      .catch(() => setMessage("An error occured."));
  };
  const [hasDeclined, setHasDeclined] = useState(false);

  const handleDeclineOffers = () => {
    setOpenNeither(true);
    if (!hasDeclined) {
      nextStepByToken(token, {}).then(() => {
        setHasDeclined(true);
      });
    }
  };

  const handleAcceptOffer = (offerPieceId) => {
    acceptOfferByToken(token, offerPieceId)
      .then((resp) => setNegotiation(resp.data))
      .catch(() => setMessage("An error occured."));
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSendNote = () => {
    nextStepByToken(token, { note })
      .then((resp) => setNegotiation(resp.data))
      .catch(() => setMessage("An error occured."));
  };
  const toggleOption = (option) => {
    setSelectedOptions([option]);
  };
  function numberToWords(number) {
    const words = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];

    if (number >= 0 && number < words.length) {
      return words[number];
    } else {
      return number?.toString(); 
    }
  }
  return (
    <Grid container>
      {!showFeatures ? (
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#F7F7F8",
            height: "100%",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingTop: "5%",
          }}
        >
          <Grid item xs={11} md={8} sx={{ paddingBottom: "5%" }}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "white",
                borderRadius: "24px",
                padding: "5%",
              }}
            >
              <Typography
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: "2%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="116"
                  height="20"
                  viewBox="0 0 116 20"
                  fill="none"
                >
                  <path
                    d="M18.2188 17.1545C16.2057 19.0515 13.7559 20 10.8692 20C7.98254 20 5.53267 19.0515 3.5196 17.1545C1.50653 15.2575 0.5 12.9178 0.5 10.1355C0.5 7.35321 1.50653 5.01355 3.5196 3.11653C5.53267 1.21951 7.98254 0.271002 10.8692 0.271002C13.7559 0.271002 16.2057 1.21951 18.2188 3.11653C20.2319 5.01355 21.2384 7.35321 21.2384 10.1355C21.2384 12.9178 20.2319 15.2575 18.2188 17.1545ZM6.71013 14.2005C7.83061 15.2484 9.21697 15.7724 10.8692 15.7724C12.5214 15.7724 13.8983 15.2484 14.9998 14.2005C16.1203 13.1527 16.6805 11.7977 16.6805 10.1355C16.6805 8.47335 16.1203 7.11834 14.9998 6.07046C13.8983 5.02258 12.5214 4.49864 10.8692 4.49864C9.21697 4.49864 7.83061 5.02258 6.71013 6.07046C5.60864 7.11834 5.05789 8.47335 5.05789 10.1355C5.05789 11.7977 5.60864 13.1527 6.71013 14.2005Z"
                    fill="#101828"
                  />
                  <path
                    d="M31.4961 4.17344C29.7299 4.02891 28.8468 4.66125 28.8468 6.07046H31.4961V9.9729H28.8468V19.6206H24.5738V9.9729H22.6652V6.07046H24.5738C24.5738 4.11924 25.1531 2.63776 26.3115 1.62602C27.47 0.596206 29.1982 0.144535 31.4961 0.271002V4.17344Z"
                    fill="#101828"
                  />
                  <path
                    d="M41.0381 4.17344C39.2719 4.02891 38.3888 4.66125 38.3888 6.07046H41.0381V9.9729H38.3888V19.6206H34.1158V9.9729H32.2072V6.07046H34.1158C34.1158 4.11924 34.695 2.63776 35.8535 1.62602C37.012 0.596206 38.7402 0.144535 41.0381 0.271002V4.17344Z"
                    fill="#101828"
                  />
                  <path
                    d="M46.007 14.4715C46.5008 15.7362 47.6403 16.3686 49.4255 16.3686C50.5839 16.3686 51.4955 16.0253 52.1602 15.3388L55.5786 17.2087C54.1733 19.0696 52.1032 20 49.3685 20C46.9756 20 45.0575 19.3225 43.6141 17.9675C42.1898 16.6125 41.4776 14.9051 41.4776 12.8455C41.4776 10.804 42.1803 9.10569 43.5857 7.75068C45.01 6.3776 46.8332 5.69106 49.0551 5.69106C51.1252 5.69106 52.8439 6.3776 54.2113 7.75068C55.5976 9.10569 56.2908 10.804 56.2908 12.8455C56.2908 13.4237 56.2338 13.9657 56.1199 14.4715H46.007ZM45.9216 11.4363H52.0463C51.6284 10.009 50.6219 9.29539 49.0266 9.29539C47.3744 9.29539 46.3394 10.009 45.9216 11.4363Z"
                    fill="#101828"
                  />
                  <path
                    d="M62.9874 8.48239C63.2533 7.61518 63.785 6.95574 64.5827 6.50407C65.3993 6.03433 66.2919 5.79946 67.2604 5.79946V10.4065C66.2159 10.2439 65.2379 10.4155 64.3263 10.9214C63.4337 11.4092 62.9874 12.2674 62.9874 13.4959V19.6206H58.7144V6.07046H62.9874V8.48239Z"
                    fill="#101828"
                  />
                  <path
                    d="M73.4739 19.6206L67.976 0.650406H72.7617L76.2086 13.9295L80.0544 0.650406H83.7577L87.6034 13.9295L91.0503 0.650406H95.8361L90.3381 19.6206H85.1535L81.906 8.42818L78.6585 19.6206H73.4739Z"
                    fill="#101828"
                  />
                  <path
                    d="M101.064 4.17344C100.57 4.64318 99.9719 4.87805 99.2692 4.87805C98.5665 4.87805 97.9588 4.64318 97.446 4.17344C96.9523 3.68564 96.7054 3.1075 96.7054 2.43903C96.7054 1.77055 96.9523 1.20145 97.446 0.731708C97.9588 0.243903 98.5665 0 99.2692 0C99.9719 0 100.57 0.243903 101.064 0.731708C101.577 1.20145 101.833 1.77055 101.833 2.43903C101.833 3.1075 101.577 3.68564 101.064 4.17344ZM97.1327 19.6206V6.07046H101.406V19.6206H97.1327Z"
                    fill="#101828"
                  />
                  <path
                    d="M109.831 15.8266H115.5V19.6206H104.105V16.9106L109.489 9.8645H104.39V6.07046H115.215V8.78049L109.831 15.8266Z"
                    fill="#101828"
                  />
                </svg>
              </Typography>

              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#58606E",
                  textAlign: "center",
                }}
              >
                {value == 0
                  ? `Here are your ${numberToWords(
                      offers?.offer_pieces?.length
                    )} top optimal offers`
                  : "Select the best offer"}{" "}
                <br />
                Pr = {negotiation.showData.Pr}
                <br />
                Pc = {negotiation.showData.Pc}
                <br />
                PrNext = {negotiation.showData.PrNext}
                <br />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                marginTop: "3%",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Chip
                onClick={() => setShowFeatures(true)}
                sx={{
                  width: "3em", // Örneğin, 2em genişlik
                  height: "3em",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginRight: "1%",
                  paddingLeft: "0.8%",
                  "& .MuiChip-icon": {
                    color: "black",
                  },
                }}
                icon={<ArrowBackIcon />}
              />
              <Typography
                sx={{
                  color: "black",
                  fontSize: "24px",
                  fontWeight: 700,
                  "&.MuiTypography-root": {
                    userSelect: "none",
                  },
                }}
              >
                View preferences
              </Typography>
            </Grid>
            {offers?.offer_pieces.map((offer) => (
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "24px",
                  marginTop: "5%",
                  paddingBottom: "2%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "2%",
                  }}
                >
                  <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                    Offer(ID:{offer.id})
                  </Typography>{" "}
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: "#0B5CFF0A",
                      color: "#0B5CFF",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#8E99AB",
                      },
                    }}
                  >
                    {" "}
                    Purchase Later
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleAcceptOffer(offer.id)}
                    sx={{
                      backgroundColor: "#0B5CFF",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",

                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#8E99AB",
                      },
                      marginLeft: "2%",
                    }}
                  >
                    Purchase Subscription
                  </Button>
                </Grid>
                <Divider width="100%" />
                <Grid
                  item
                  xs={12}
                  sx={{
                    margin: "2%",
                    backgroundColor: "#FBFBFC",
                    borderRadius: "12px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "2%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "1%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                          stroke="rgba(128, 128, 128, 1)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>
                    <Typography
                      onClick={() => (
                        setOpenNonCustomized({
                          ...openNonCustomized,
                          [offer.id]: true,
                        }),
                        setSelectedOffer(offer)
                      )}
                      sx={{
                        color: "#0B5CFF",
                        textDecoration: "underline",
                        cursor: "pointer",

                        "&.MuiTypography-root": {
                          userSelect: "none",
                        },
                      }}
                    >
                      {" "}
                      View non-customized plan
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingLeft: "2%" }}>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "34px",
                        fontWeight: "600",
                        color: "#101828",
                      }}
                    >
                      {(offer.val_feats.some( valFeat=>valFeat.amount != valFeat.planAmount ) ? 'Customized ' : '') + offer.planName} Plan
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#FFFFFF",
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginTop: "0.5%",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_575_44238)">
                            <path
                              d="M4.9987 7.99967L6.9987 9.99967L10.9987 5.99967M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z"
                              stroke="#FFFFFF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_575_44238">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {offer.explanation}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Divider flexItem />
                  <Grid
                    container
                    xs={12}
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "2%",
                      paddingBottom: "2%",
                    }}
                  >
                    {offer.val_feats.map((item) => (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "black",
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                        }}
                      >
                        {" "}
                        <span style={{ marginRight: "4px" }}>•</span>
                        {item?.valFeat.description} -
                        <span style={{ marginRight: "4px" }}></span>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color:
                              item.amount > item.planAmount ||
                              (item.amount == -1 && item.planAmount !== -1)
                                ? item.planAmount == -1
                                  ? "red"
                                  : "#0B5CFF"
                                : item.amount < item.planAmount
                                ? "red"
                                : "black",
                            marginBottom: "1%",
                          }}
                        >
                          {item?.valFeat.typ == "yesno"
                            ? item.amount === 1
                              ? "Yes"
                              : "No"
                            : item.amount === -1
                            ? "Unlimited"
                            : item.amount}
                        </Typography>
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    margin: "2%",
                    backgroundColor: "#FBFBFC",
                    borderRadius: "12px",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "2%",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                  }}
                >
                  <Grid
                    item
                    xs={9}
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      paddingLeft: "2%",
                      gap: "8%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#171717",
                      }}
                    >
                      Subscription Term
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "#101828",
                        }}
                      >
                        {offer.commitment}{" "}
                      </Typography>{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#171717",
                      }}
                    >
                      Subscription Billing
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "#101828",
                        }}
                      >
                        {offer.billingPeriod}{" "}
                      </Typography>{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#171717",
                      }}
                    >
                      Pricing
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "#101828",
                        }}
                      >
                        {getSymbolFromCurrency(offer?.currency)}
                        {offer.price}
                      </Typography>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        setOpenDetails({ ...openDetails, [offer.id]: true })
                      }
                      sx={{
                        color: "#0B5CFF",
                        width: "90%",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                      }}
                    >
                      {" "}
                      Companion Details
                    </Button>
                  </Grid>
                </Grid>
                <Drawer
                  anchor="right"
                  sx={{ zIndex: 1, position: "absolute" }}
                  open={openDetails[offer.id] || false}
                  onClose={() =>
                    setOpenDetails({ ...openDetails, [offer.id]: false })
                  }
                >
                  <Grid
                    item
                    sx={{
                      width: { xs: "80vw", lg: "25vw" },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginTop: "7%",
                      }}
                    >
                      Companion Details
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: "#FBFBFC",
                        borderRadius: "100px",
                        padding: "1%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        width: "20%",
                        height: "20%",
                        marginTop: "10%",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                          stroke="rgba(128, 128, 128, 1)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Offer {offer.id}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginTop: "7%",
                        color: "#58606E",
                        padding: "5%",
                        textAlign: "center",
                      }}
                    >
                      {offer.details1}
                    </Typography>
                    <Typography sx={{ marginTop: "5%" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                      >
                        <path
                          d="M15.9075 15.75C16.3189 14.5804 17.131 13.5942 18.1999 12.966C19.2688 12.3378 20.5256 12.1081 21.7476 12.3177C22.9696 12.5274 24.0779 13.1627 24.8764 14.1112C25.6748 15.0597 26.1118 16.2602 26.11 17.5C26.11 21 20.86 22.75 20.86 22.75M21 29.75H21.0175M38.5 21C38.5 30.665 30.665 38.5 21 38.5C11.335 38.5 3.5 30.665 3.5 21C3.5 11.335 11.335 3.5 21 3.5C30.665 3.5 38.5 11.335 38.5 21Z"
                          stroke="rgba(128, 128, 128, 1)"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#58606E",
                        padding: "5%",
                        textAlign: "center",
                      }}
                    >
                      {offer.details2}
                    </Typography>{" "}
                    <Typography sx={{ marginTop: "5%" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                      >
                        <path
                          d="M15.125 21L20.375 26.25L30.875 15.75M40.5 21C40.5 30.665 32.665 38.5 23 38.5C13.335 38.5 5.5 30.665 5.5 21C5.5 11.335 13.335 3.5 23 3.5C32.665 3.5 40.5 11.335 40.5 21Z"
                          stroke="rgba(128, 128, 128, 1)"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#58606E",
                        padding: "5%",
                        textAlign: "center",
                      }}
                    >
                      {offer.details3}
                    </Typography>{" "}
                    <Button
                      variant="contained"
                      onClick={() =>
                        setOpenDetails({ ...openDetails, [offer.id]: false })
                      }
                      sx={{
                        backgroundColor: "#0B5CFF",
                        color: "white",
                        width: "90%",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                      }}
                    >
                      {" "}
                      Close
                    </Button>
                  </Grid>
                </Drawer>
                <Drawer
                  anchor="right"
                  sx={{ zIndex: 1, position: "absolute" }}
                  open={openNonCustomized[offer.id] || false}
                  onClose={() =>
                    setOpenNonCustomized({
                      ...openNonCustomized,
                      [offer.id]: false,
                    })
                  }
                >
                  <Grid
                    item
                    sx={{
                      width: { xs: "80vw", lg: "25vw" },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginTop: "7%",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                      >
                        <path
                          d="M37.3346 29.333H21.3346M26.668 39.9997H21.3346M42.668 18.6663H21.3346M53.3346 18.133V45.8663C53.3346 50.3468 53.3346 52.587 52.4627 54.2983C51.6957 55.8036 50.4719 57.0274 48.9666 57.7944C47.2553 58.6663 45.0151 58.6663 40.5346 58.6663H23.468C18.9875 58.6663 16.7473 58.6663 15.036 57.7944C13.5307 57.0274 12.3069 55.8036 11.5399 54.2983C10.668 52.587 10.668 50.3468 10.668 45.8663V18.133C10.668 13.6526 10.668 11.4124 11.5399 9.70108C12.3069 8.19579 13.5307 6.97194 15.036 6.20496C16.7473 5.33301 18.9875 5.33301 23.468 5.33301H40.5346C45.0151 5.33301 47.2553 5.33301 48.9666 6.20496C50.4719 6.97194 51.6957 8.19579 52.4627 9.70108C53.3346 11.4124 53.3346 13.6526 53.3346 18.133Z"
                          stroke="rgba(128, 128, 128, 1)"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginTop: "7%",
                      }}
                    >
                      {selectedOffer?.planName} Non-Customized
                    </Typography>
                    {selectedOffer?.val_feats.map((item) => (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#171717",
                          flexDirection: "row",
                          display: "flex",
                          marginTop: "2%",
                        }}
                      >
                        {" "}
                        <span style={{ marginRight: "4px" }}>•</span>
                        {item.valFeat.description} -
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#171717",
                          }}
                        >
                          {" "}
                          <span style={{ marginRight: "4px" }}></span>
                          {item.planAmount == -1
                            ? "Unlimited"
                            : item.valFeat.typ == "yesno"
                            ? item.planAmount == 0
                              ? "No"
                              : "Yes"
                            : item.planAmount}
                        </Typography>{" "}
                      </Typography>
                    ))}
                    <Button
                      variant="contained"
                      onClick={() =>
                        setOpenNonCustomized({
                          ...openNonCustomized,
                          [offer.id]: false,
                        })
                      }
                      sx={{
                        backgroundColor: "#0B5CFF",
                        color: "white",
                        width: "90%",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                        marginTop: "5%",
                      }}
                    >
                      {" "}
                      Close
                    </Button>
                  </Grid>
                </Drawer>
              </Grid>
            ))}
            <Drawer
              anchor={"right"}
              sx={{ zIndex: 1, position: "absolute" }}
              open={openNeither}
              onClose={() => (setOpenNeither(false), setContinue(false))}
            >
              {value == 1 ? (
                <Grid
                  item
                  sx={{
                    width: { xs: "80vw", lg: "25vw" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "6%",
                  }}
                >
                  {" "}
                  <Typography sx={{ marginTop: "10%" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <path
                        d="M16.2503 29.9431C16.0846 28.8749 15.9986 27.7806 15.9986 26.6663C15.9986 14.8843 25.6127 5.33301 37.4723 5.33301C49.3319 5.33301 58.946 14.8843 58.946 26.6663C58.946 29.3279 58.4554 31.8756 57.5591 34.225C57.373 34.713 57.2799 34.957 57.2376 35.1474C57.1958 35.3362 57.1796 35.4689 57.1751 35.6622C57.1704 35.8573 57.1969 36.0721 57.2498 36.5019L58.3234 45.2224C58.4396 46.1664 58.4977 46.6384 58.3407 46.9816C58.2031 47.2823 57.9587 47.5211 57.6549 47.6517C57.3083 47.8008 56.8377 47.7318 55.8966 47.5938L47.4026 46.3488C46.9591 46.2838 46.7373 46.2513 46.5353 46.2524C46.3355 46.2535 46.1973 46.2683 46.0018 46.3094C45.8041 46.3509 45.5516 46.4455 45.0466 46.6347C42.6912 47.5169 40.1385 47.9997 37.4723 47.9997C36.3571 47.9997 35.2618 47.9152 34.1925 47.7524M20.3496 58.6663C28.256 58.6663 34.6654 52.0999 34.6654 43.9997C34.6654 35.8995 28.256 29.333 20.3496 29.333C12.4432 29.333 6.03379 35.8995 6.03379 43.9997C6.03379 45.6279 6.29277 47.1942 6.77083 48.6576C6.97291 49.2762 7.07395 49.5855 7.10712 49.7968C7.14174 50.0174 7.1478 50.1413 7.13491 50.3643C7.12256 50.5778 7.06913 50.8191 6.96228 51.3018L5.33203 58.6663L13.3182 57.5757C13.7541 57.5161 13.972 57.4864 14.1623 57.4877C14.3627 57.489 14.4691 57.4999 14.6657 57.5391C14.8523 57.5763 15.1298 57.6742 15.6848 57.8701C17.1469 58.3861 18.7163 58.6663 20.3496 58.6663Z"
                        stroke="#404040"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "600",
                      marginTop: "7%",
                      textAlign: "center",
                      padding: "5%",
                    }}
                  >
                    A customer success manager will be in touch shortly to
                    discuss your needs. Please let us know how we can do better.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    We appreciate your business{" "}
                  </Typography>
                  <TextField
                    label="Please send us a feedback about your offer"
                    value={note}
                    multiline={true}
                    variant="standard"
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ width: "100%", marginTop: "10%" }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => (setOpenNeither(false), handleSendNote())}
                    sx={{
                      backgroundColor: "#0B5CFF",
                      color: "white",
                      width: "100%",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#8E99AB",
                      },
                      marginTop: "5%",
                    }}
                  >
                    Send
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setOpenNeither(false)}
                    sx={{
                      backgroundColor: "rgba(218, 218, 218, 1)",
                      color: "black",
                      width: "100%",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#8E99AB",
                      },
                      marginTop: "5%",
                    }}
                  >
                    Close
                  </Button>
                </Grid>
              ) : (
                <Grid
                  item
                  sx={{
                    width: { xs: "80vw", lg: "25vw" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "6%",
                  }}
                >
                  {" "}
                  {Continue ? (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                        width: "60%",
                        textAlign: "center",
                        marginTop: "10%",
                      }}
                    >
                      Select One Option from below
                    </Typography>
                  ) : (
                    <Typography sx={{ marginTop: "10%" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="58"
                        height="58"
                        viewBox="0 0 58 58"
                        fill="none"
                      >
                        <path
                          d="M38.332 6.33301V2.33301M48.8369 9.16143L51.6654 6.33301M51.6927 19.6663H55.6927M55.5337 31.6663C54.1958 45.1419 42.8263 55.6663 28.9987 55.6663C14.2711 55.6663 2.33203 43.7273 2.33203 28.9997C2.33203 15.1721 12.8565 3.80261 26.332 2.46468M28.9987 18.333H39.6654V28.9997M38.6512 18.333C32.3729 27.2066 22.029 32.9997 10.332 32.9997C7.65762 32.9997 5.05394 32.6968 2.55342 32.1236"
                          stroke="#404040"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>
                  )}
                  {Continue ? (
                    <Grid container>
                      {negotiation.selections.map((selections) => (
                        <Typography
                          key={selections}
                          onClick={() => toggleOption(selections)}
                          sx={{
                            backgroundColor: selectedOptions.includes(
                              selections
                            )
                              ? "#0B5CFF"
                              : "rgba(11, 92, 255, 0.04)",
                            borderRadius: "100px",
                            cursor: "pointer",
                            color: selectedOptions.includes(selections)
                              ? " white"
                              : "#0B5CFF",
                            margin: "8px",
                            padding: "8px",
                            "&.MuiTypography-root": {
                              userSelect: "none",
                            },
                          }}
                          variant="body1"
                        >
                          {selections}
                        </Typography>
                      ))}
                    </Grid>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginTop: "7%",
                        textAlign: "center",
                        padding: "5%",
                      }}
                    >
                      Let’s help us craft another more suitable offer for you?
                    </Typography>
                  )}
                  {Continue ? (
                    <Button
                      variant="contained"
                      onClick={() => (
                        setOpenNeither(false),
                        setContinue(false),
                        setSelectedOptions([]),
                        handleSelected(),
                        setValue(1)
                      )}
                      disabled={selectedOptions.length < 1}
                      sx={{
                        backgroundColor: "#0B5CFF",
                        color: "white",
                        width: "100%",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                        marginTop: "5%",
                      }}
                    >
                      Show additional Offer
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => setContinue(true)}
                      sx={{
                        backgroundColor: "#0B5CFF",
                        color: "white",
                        width: "100%",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                        marginTop: "5%",
                      }}
                    >
                      Continue
                    </Button>
                  )}
                  {Continue ? null : (
                    <Button
                      variant="standard"
                      onClick={() => setOpenNeither(false)}
                      sx={{
                        backgroundColor: "rgba(11, 92, 255, 0.04)",
                        width: "100%",
                        color: "#0B5CFF",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                        marginTop: "5%",
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
              )}
            </Drawer>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                variant="outlined"
                onClick={() => handleDeclineOffers()}
                sx={{
                  color: "#0B5CFF",
                  width: "20%",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#8E99AB",
                  },
                  marginTop: "5%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M17.5 7L7.5 17M7.5 7L17.5 17"
                    stroke="#0B5CFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {negotiation.phase === "first proposal"
                  ? "Neither"
                  : negotiation.phase === "second proposal"
                  ? "None"
                  : null}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <PreferencesPage
          setShowFeatures={setShowFeatures}
          token={token}
          setMessage={setMessage}
        />
      )}
    </Grid>
  );
};

export default OffersPageComponent;
