/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Divider,
  Typography,
  Stack,
  Popover,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MessageBanner from "../../components/messageBanner";
import DataStorage from "../../storage";
import { listCustomers } from "../../api/customers";

import BillingReports from "./BillingReports/BillingReports";
import NegotiationsReports from "./NegotiationsReports/NegotiationsReports";
import ExperimentsReports from "./ExperimentsReports/ExperimentsReports";

function ReportsPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [message, setMessage] = useState(message_ || "");

  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState("billing");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteCustomer = (indexToDelete) => {
    const updatedCustomers = [...selectedCustomer];
    updatedCustomers.splice(indexToDelete, 1);
    setSelectedCustomer(updatedCustomers);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      listCustomers(localStorage.getItem("token"))
        .then((resp) => {
          setCustomers(resp.data);
        })
        .catch(() => setMessage("An error occurred."));
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        backgroundColor: "#171717",
        alignContent: "flex-start",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{padding:"1%",paddingBottom:"0"}}>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: "0px",
          backgroundColor: "#1F1F1F",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          minHeight: "100vh",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              xs={12}
              spacing={2}
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                padding: "2%",
              }}
            >
              <Grid
                item
                lg={6}
                xs={12}
                sx={{ flexDirection: "row", display: "flex" }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    backgroundColor: selected === "billing" ? "#B9D2FF" : null,
                    fontWeight: selected === "billing" ? 600 : 500,
                    borderRadius: "4px",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    border: " 1px solid #B9D2FF",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: selected === "billing" ? "black" : "white",
                    textTransform: "none",
                    padding: "1%",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => setSelected("billing")}
                >
                  Billing Reports
                </Typography>

                <Typography
                  sx={{
                    flex: 1,
                    backgroundColor:
                      selected === "negotiations" ? "#B9D2FF" : null,
                    fontWeight: selected === "negotiations" ? 600 : 500,
                    borderRadius: "0",
                    border: " 1px solid #B9D2FF",
                    borderLeft: "0",
                    borderRight: "0",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: selected === "negotiations" ? "black" : "white",
                    textTransform: "none",
                    padding: "1%",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => setSelected("negotiations")}
                >
                  Negotiations Reports
                </Typography>

                <Typography
                  sx={{
                    flex: 1,
                    backgroundColor:
                      selected === "experiments" ? "#B9D2FF" : null,
                    fontWeight: selected === "experiments" ? 600 : 500,
                    borderRadius: "4px",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    border: " 1px solid #B9D2FF",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: selected === "experiments" ? "black" : "white",
                    textTransform: "none",
                    padding: "1%",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => setSelected("experiments")}
                >
                  Experiments Reports
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={2}
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent:"flex-end"
                }}
              >
                <Typography
                  sx={{
                    display: "contents",
                    alignItems: "center",
                    fontWeight: 500,
                    color: "white",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => console.log("import data")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    style={{ marginRight: "5px" }}
                  >
                    <path
                      d="M18 12.5V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V12.5M14.6667 6.66667L10.5 2.5M10.5 2.5L6.33333 6.66667M10.5 2.5V12.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Import data
                </Typography>

                <Typography
                  sx={{
                    display: "contents",
                    alignItems: "center",
                    fontWeight: 500,

                    color: "white",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => console.log("export data")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    style={{ marginRight: "5px", marginLeft: "5%" }}
                  >
                    <path
                      d="M3 7.5L3 6.5C3 5.09987 3 4.3998 3.27249 3.86502C3.51217 3.39462 3.89462 3.01217 4.36503 2.77248C4.8998 2.5 5.59987 2.5 7 2.5L14 2.5C15.4001 2.5 16.1002 2.5 16.635 2.77248C17.1054 3.01217 17.4878 3.39462 17.7275 3.86502C18 4.3998 18 5.09987 18 6.5L18 7.5M6.33333 13.3333L10.5 17.5M10.5 17.5L14.6667 13.3333M10.5 17.5L10.5 7.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Export data
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider
            sx={{
              width: "100%",
              marginBottom: "2%",
              backgroundColor: "#808080",
            }}
          />
          {selected === "billing" ? (
            <BillingReports
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              customers={customers}
              onDeleteCustomer={onDeleteCustomer}
            />
          ) : selected === "negotiations" ? (
            <NegotiationsReports />
          ) : (
            <ExperimentsReports />
          )}
          <Grid item xs={12}>
            <MessageBanner message={message} setMessage={setMessage} />
          </Grid>
        </Grid>
      </Grid></Grid>
    </Grid>
  );
}

export default ReportsPage;
