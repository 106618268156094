/** @format */

import axios from "axios";

export function createCustomer(token, data) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/customers/create/`,
    data,
    config
  );
}

export function listCustomers(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/api/customers/`, config);
}

export function getCustomer(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/customers/${id}/`,
    config
  );
}

export function saveCustomer(token, id, data) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.put(
    `${process.env.REACT_APP_API_URL}/api/customers/${id}/`,
    data,
    config
  );
}

export function deleteCustomer(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.delete(
    `${process.env.REACT_APP_API_URL}/api/customers/${id}/`,
    config
  );
}

export function getCustomerPlans(token, customer_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/customer/customer-plans/${customer_id}/`,
    config
  );
}

export function setCurrentCustomerPlanSubModel(token, customer_id, subModelId) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const data = {
    subModelId,
  };

  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/negotiation/customer/customer-plans/${customer_id}/`,
    data,
    config
  );
}

export function setCustomerValueFeatures(token, customer_id, valFeats) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const data = {
    valFeats,
  };

  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/negotiation/customer/customer-value-features/${customer_id}/`,
    data,
    config
  );
}
