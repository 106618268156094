// components/Signup.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataStorage from "../storage";

function LogoutPage() {

    const navigate = useNavigate();

    useEffect(()=>{
        DataStorage.remove('company');
        DataStorage.remove('token');
        navigate('/login');
    }, []);
};

export default LogoutPage;