/** @format */

// CurrentSubsComponent.js

import React, { useState } from "react";
import {
  Grid,
  ListItem,
  TextField,
  Typography,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import Divider from "@mui/material/Divider";

function CurrentSubsComponent({ handleChange, formData, subscriptionPlans }) {
  const [customSubs, setCustomSubs] = useState("No");
  const Features = [
    { label: "Data Records - 100,000*" },
    { label: "Advanced Security - Yes*" },
    { label: "Users - Unlimited*" },
  ];
  const [selectedFeatures, setSelectedFeatures] = useState("");
  const handleChange1 = (e) => {
    const { value } = e.target;
    setCustomSubs(value);
  };
  return (
    <Grid
      container
      sx={{
        marginBottom: "16px",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "2%",
            alignItems: "center",
            height: "7vh",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fonstSize: "20px",
              fontWeight: 600,
              margin: "1em",
            }}
          >
            Current Subscription*
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: "#B9D2FF",
              borderColor: "#B9D2FF",
              textTransform: "none",
              marginRight: "8px",
              height: "60%",
              width: "15%",
            }}
          >
            Upload CSV
          </Button>
        </Grid>
        <Divider width="100%" />
        <Grid item xs={12}>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <TextField
                    sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Choose Standard Plan"
                  placeholder="Choose Plan"
                  name="choosePlan"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                    sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Customized Subscription"
                  placeholder="No"
                  onChange={handleChange1}
                  name="choosePlan"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                    sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Subscription Date"
                  placeholder="Enter Subscription Date"
                  name="subscriptionDate"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  margin="normal"
                  select
                  label="Subscription Term"
                  placeholder="Period"
                  fullWidth
                    sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                >
                  <MenuItem value="Term 1">Term 1</MenuItem>
                  <MenuItem value="Term 2">Term 2</MenuItem>
                  <MenuItem value="Term 3">Term 3</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                    sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Price paid in currency"
                  placeholder="Enter Currency"
                  name="currency"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  margin="normal"
                  select
                  label="Subscription Billing"
                  placeholder="Period"
                  fullWidth
                    sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                >
                  <MenuItem value="Billing 1">Billing 1</MenuItem>
                  <MenuItem value="Billing 2">Billing 2</MenuItem>
                  <MenuItem value="Billing 3">Billing 3</MenuItem>
                </TextField>
              </Grid>
              {customSubs === "Yes" || customSubs === "yes" ? (
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    multiple
                    id="search-bar"
                    fullWidth
                    options={Features.map((feat) => feat.label)}
                    onChange={(event, newValue) => {
                      setSelectedFeatures(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Features plan"
                        fullWidth
                          sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <span
                          key={index}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: "#d0d5dc", 
                            padding: "4px 8px", 
                            margin: "4px", 
                            borderRadius: "100px", 
                          }}
                        >
                          {option}
                        </span>
                      ))
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    select
                    label="Subscription Plan"
                    placeholder="Period"
                    fullWidth
                      sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  >
                    <MenuItem value="Offer 1">Basic Offer</MenuItem>
                    <MenuItem value="Offer 2">Offer 2</MenuItem>
                    <MenuItem value="Offer 3">Offer 3</MenuItem>
                  </TextField>
                </Grid>
              )}


            </Grid>
          </ListItem>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CurrentSubsComponent;
