/** @format */

// components/Signup.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { listSubscriptionPlans } from "../api/subscription.plans";
import MessageBanner from "./messageBanner";
import {
  Button,
  Box,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import DataStorage from "../storage";

function SubscriptionPlansPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [subPlans, setSubPlans] = useState([]);
  const [filteredSubPlans, setFilteredSubPlans] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      listSubscriptionPlans(DataStorage.get("token"))
        .then((resp) => {
          setSubPlans(resp.data.filter((subPlan) => subPlan.FullPrice > 0));
          setFilteredSubPlans(
            resp.data.filter((subPlan) => subPlan.FullPrice > 0)
          );
        })
        .catch(() => setMessage("An error occured."));
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      <Box display="flex" justifyContent="center">
        
        <Grid container spacing={1}>
          <Grid items xs={12} sm={6} >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/new-subscription-plan")}
            >
              Add New Subscription Plan
            </Button>
          </Grid>
          <Grid items xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/subscription-plans/settings")}
            >
              Subscription Plan Settings
            </Button>
          </Grid>
        </Grid>
      </Box>
      {filteredSubPlans.length > 0 ? (
        <Paper elevation={3} sx={{ marginTop: "16px", borderRadius: "15px" }}>
          <List sx={{ width: "750px" }}>
            {filteredSubPlans.map((subPlan, index) => (
              <React.Fragment key={subPlan.id}>
                {index !== 0 && <Divider />}
                <ListItem disablePadding sx={{ width: "100%", my: 1 }}>
                  <ListItemButton
                    component={Link}
                    to={`/show-subscription-plan/${subPlan.id}`}
                    sx={{ width: "100%" }}
                  >
                    <ListItemText
                      primary={subPlan.name}
                      secondary={`Click for more details about ${subPlan.name}`}
                    />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : (
        <Box mt={2}>
          <Typography variant="subtitle1">
            No subscription plans available.
          </Typography>
        </Box>
      )}
      <MessageBanner message={message} setMessage={setMessage} />
    </Box>
  );
}

export default SubscriptionPlansPage;
