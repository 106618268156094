/** @format */

// GeneralInfoComponent.js

import React, { useEffect, useState } from "react";
import {
  Grid,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { getData } from "country-list";
import Divider from "@mui/material/Divider";
import { saveCustomer } from "../../api/customers";
import DataStorage from "../../storage";
import getSymbolFromCurrency from "@rea-app/currency-symbol-map";

function GeneralInfoComponent({
  customer,
  formattedDate,
  handleCustomerEdited,
}) {
  const countryOptions = getData().map((d) => ({
    value: d.code,
    label: d.name,
  }));
  const [isEditable, setIsEditable] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState({
    businessName: "",
    administratorName: "",
    administratorEmail: "",
    estimatedRevenueInUSD: "",
    customerSince: "",
    SpeciaPricingAwardee: "",
    country: "",
    externalFunding: "",
    billingPeriod: "",
    billingAmount: "",
  });
  const handleChange = (e) => {
    setEditedCustomer({ ...editedCustomer, [e.target.name]: e.target.value });
  };
  const handleSave = () => {
    saveCustomer(DataStorage.get("token"), customer.id, editedCustomer)
      .then(() => handleCustomerEdited())
      .catch(() => alert("error"));
  };
  useEffect(() => {
    setEditedCustomer((prevEditedCustomer) => ({
      ...prevEditedCustomer,
      ...customer,
    }));
  }, [customer, formattedDate]);

  return (
    <Grid
      container
      sx={{
        marginBottom: "16px",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ flexDirection: "row", display: "flex", margin: "1em" }}
      >
        <Typography
          sx={{
            color: "white",
            fonstSize: "20px",
            fontWeight: 600,
          }}
        >
          General Info
        </Typography>
        <Button
          variant={!isEditable ? "outlined" : "contained"}
          sx={{
            color: !isEditable ? "#B9D2FF" : "black",
            marginLeft: "auto",
            textTransform: "none",
            backgroundColor: isEditable ? "#B9D2FF" : null,
            borderColor: "#B9D2FF",
            minWidth: "8%",
          }}
          onClick={() =>
            !isEditable
              ? setIsEditable(!isEditable)
              : (setIsEditable(!isEditable), handleSave())
          }
        >
          {!isEditable ? (
            <Typography sx={{ flexDirection: "row", display: "flex", gap: 1 }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M15.5007 8.33619L12.1673 5.00286M2.58398 17.9195L5.40429 17.6062C5.74887 17.5679 5.92115 17.5487 6.08219 17.4966C6.22506 17.4503 6.36102 17.385 6.48639 17.3023C6.6277 17.2091 6.75027 17.0866 6.99542 16.8414L18.0007 5.83619C18.9211 4.91572 18.9211 3.42333 18.0007 2.50286C17.0802 1.58238 15.5878 1.58238 14.6673 2.50285L3.66209 13.5081C3.41694 13.7532 3.29436 13.8758 3.20118 14.0171C3.11851 14.1425 3.05316 14.2784 3.00691 14.4213C2.95478 14.5824 2.93564 14.7546 2.89735 15.0992L2.58398 17.9195Z"
                  stroke="#B9D2FF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Edit
            </Typography>
          ) : (
            <Typography>Save</Typography>
          )}
        </Button>
      </Grid>
      <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
      <Grid item xs={12}>
        <ListItem>
          <Grid container>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {" "}
                    {!isEditable ? (
                      <>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Business Name:
                        </Typography>

                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.businessName}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Business Name:"
                        name="businessName"
                        autoComplete="off"
                        value={editedCustomer.businessName}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter experiment Traffic"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      />
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        {" "}
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Administrator Name:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.administratorName}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Administrator Name:"
                        name="administratorName"
                        autoComplete="off"
                        value={editedCustomer.administratorName}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter experiment Traffic"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      />
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        {" "}
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Estimated Revenue
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {getSymbolFromCurrency(customer.billingCurrency)}
                          {customer.estimatedRevenueInUSD}{" "}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Estimated Revenue:"
                        name="estimatedRevenueInUSD"
                        autoComplete="off"
                        value={editedCustomer.estimatedRevenueInUSD}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter experiment Traffic"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      />
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        {" "}
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Customer Since
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        name="customerSince"
                        autoComplete="off"
                        value={editedCustomer.customerSince}
                        onChange={(e) => handleChange(e)}
                        type="date"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      />
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Special Pricing Awardee
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.SpeciaPricingAwardee ? "Yes" : "No"}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Special Pricing Awardee:"
                        name="SpeciaPricingAwardee"
                        autoComplete="off"
                        value={editedCustomer.SpeciaPricingAwardee}
                        onChange={(e) => handleChange(e)}
                        select
                        placeholder="Enter experiment Traffic"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiSelect-icon": {
                            fill: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </TextField>
                    )}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
      </Grid>
      <Grid item xs={12}>
        <ListItem>
          <Grid container>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Country of incorporation:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.country}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Country of incorporation :"
                        name="country"
                        autoComplete="off"
                        value={editedCustomer.country}
                        onChange={(e) => handleChange(e)}
                        select
                        placeholder="Enter experiment Traffic"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiSelect-icon": {
                            fill: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      >
                        {countryOptions.map((country) => (
                          <MenuItem value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        {" "}
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Administrator Email:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                            wordBreak: "break-all",
                          }}
                        >
                          {customer.administratorEmail}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Administrator Email:"
                        name="administratorEmail"
                        autoComplete="off"
                        value={editedCustomer.administratorEmail}
                        onChange={(e) => handleChange(e)}
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      />
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          External Funding
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.externalFunding ? "Yes" : "No"}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="External Funding:"
                        name="externalFunding"
                        autoComplete="off"
                        value={editedCustomer.externalFunding}
                        onChange={(e) => handleChange(e)}
                        select
                        placeholder="Enter experiment Traffic"
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiSelect-icon": {
                            fill: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </TextField>
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Typography>
                    {!isEditable ? (
                      <>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Amount per billing period:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.billingAmount}
                        </Typography>
                      </>
                    ) : (
                      <TextField
                        label="Amount per billing period::"
                        name="billingAmount"
                        autoComplete="off"
                        value={editedCustomer.billingAmount}
                        onChange={(e) => handleChange(e)}
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                      />
                    )}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} lg={2.4}>
              <ListItemText
                primary={
                  <Select
                    disabled={!isEditable}
                    name="billingPeriod"
                    value={editedCustomer.billingPeriod}
                    onChange={(e) => handleChange(e)}
                    sx={{
                      backgroundColor: "#171717",
                      borderRadius: "5px",
                      color: "white",
                      fontWeight: 600,
                      marginLeft: "-5%",
                      "& .MuiSelect-icon": {
                        color: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "white",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#8E99AB",
                          "& li:hover": {
                            backgroundColor: "#656970",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="year">Year</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="day">Day</MenuItem>
                  </Select>
                }
                sx={{
                  "& .MuiListItemText-primary": {
                    color: "white",
                    fontWeight: 300,
                    fontSize: "0.875rem",
                  },
                  "& .MuiListItemText-secondary": {
                    margin: "0.5em",
                    color: "white",
                    fontWeight: 600,
                    fontSize: "1rem",
                  },
                }}
              />
            </Grid>
          </Grid>
        </ListItem>
      </Grid>
    </Grid>
  );
}

export default GeneralInfoComponent;
