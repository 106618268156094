/** @format */

// CustomersGroupsComponent.js

import React from "react";
import {
  Grid,
  Typography,
  Box,
} from "@mui/material";
import Divider from "@mui/material/Divider";
const strategy = [
  {
    label: "All Customers:",
    value: 1342,
  },
  {
    label: "Customers Currently on a Free Plan:",
    value: 184,
  },

  {
    label: "High Income Countries Customers:",
    value: 1526,
  },
  {
    label: "Customers Currently on a Trial:",
    value: 295,
  },

  {
    label: "Low/Med Income Countries Customers:",
    value: 224,
  },  {
    label: "Annual Subscription Plan than 3 month tenure:",
    value: 834,
  },
  {
    label: "Red Customer Health Score:",
    value: 130,
  },
  {
    label: "On a Monthly Subscription Plan:",
    value: 1243,
  },
];

function CustomersGroupsComponent() {
  return (
    <Grid item xs={12} sx={{ margin: "1%" }}>
      <Grid
        container
        sx={{
          marginBottom: "16px",
          backgroundColor: "#2E2E2E",
          borderRadius: "8px",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginTop:"1%"
          }}
        >
          <Typography
            sx={{
              color: "white",
              fonstSize: "20px",
              fontWeight: 600,
              margin: "1em",
            }}
          >
            Pre-Defined Customers Groups
          </Typography>
        </Grid>
        <Divider width="100%" sx={{ backgroundColor: "#808080" }} />

        {strategy.map((item) => (
          <Grid item xs={12} lg={5} sx={{ padding:"1%",paddingLeft: "2%", }}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  borderRadius: 100,
                  backgroundColor: "#171717",
                  display: "inline-flex",
                  width: "45px",
                  height: "45px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99935 12.9167H6.24935C5.08638 12.9167 4.50489 12.9167 4.03173 13.0602C2.96639 13.3834 2.13272 14.217 1.80955 15.2824C1.66602 15.7555 1.66602 16.337 1.66602 17.5M13.3327 15L14.9993 16.6667L18.3327 13.3333M12.0827 6.25C12.0827 8.32107 10.4037 10 8.33268 10C6.26161 10 4.58268 8.32107 4.58268 6.25C4.58268 4.17893 6.26161 2.5 8.33268 2.5C10.4037 2.5 12.0827 4.17893 12.0827 6.25Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
              <Typography
                sx={{
                  marginLeft: "2%",
                  fontSize: "14px",
                  color: "#808080",
                  fontWeight: 400,
                }}
              >
                {item.label}
                <Typography
                  sx={{ fontSize: "14px", color: "white", fontWeight: 600 }}
                >
                  {item.value}
                </Typography>{" "}
              </Typography>{" "}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default CustomersGroupsComponent;
