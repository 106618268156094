/** @format */

import {
  Grid,
  Typography,
  Divider,
  ListItem,
  List,
  ListItemText,
  Button,
  Link,
  Autocomplete,
  TextField,
  MenuItem,
  Hidden,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import ResultModal from "./ResultModal";

const ExperimentComponent = ({
  filteredExperiments,
  setFilteredExperiments,
  handleStatus,
  setSelectedStatus,
  setSelectedObjective,
  setSelectedType,
  experiments,
  selectedType,
  selectedObjective,
  selectedStatus,
}) => {
  const [result, setResult] = useState();
  const [openModal, setOpenModal] = useState();
  const handleResult = (item) => {
    setResult(item);
    console.log(item, "item");
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Grid item xs={12} sx={{ margin: "1%" }}>
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            xs={12}
    
            alignItems={"center"}
       
          >
            <Grid item xs={12} lg={4} sx={{padding:"1%",marginTop:{xs:"5%",md:"2%",lg:"0"}}}>
            <Autocomplete
              freeSolo
              id="search-bar"
              options={filteredExperiments}
              onChange={(event, newValue) => {
                if (event.key == "Enter") {
                  const filteredData = experiments.filter((item) => {
                    return (
                      item.experimentName
                        .toLowerCase()
                        .includes(newValue.toLowerCase()) ||
                      item.id.includes(newValue)
                    );
                  });
                  setFilteredExperiments(filteredData);
                }
                if (typeof newValue === "object") {
                  const selectedArray = newValue ? [newValue] : experiments;
                  setFilteredExperiments(selectedArray);
                }
              }}
              getOptionLabel={(option) => option?.experimentName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "#2E2E2E",
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Hidden mdDown>
                        <InputAdornment position="start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g opacity="0.3">
                              <path
                                d="M17.5 17.5L12.5001 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                        </InputAdornment>
                      </Hidden>
                    ),
                    style: { color: "rgba(128, 128, 128, 1)" },
                  }}
                />
              )}
            /></Grid>

            <Grid item xs={6} lg={2} sx={{padding:"1%",marginTop:{xs:"5%",md:"2%",lg:"0"}}}>
              <TextField
                select
                label="Experiment status"
                value={selectedStatus}
                onChange={(e) => {
                  handleStatus(e.target.value);
                  setSelectedStatus(e.target.value);
                }}
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  "& .MuiInputBase-root": {
                    color: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiSelect-icon": {
                    fill: "rgba(128, 128, 128, 1)",
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="ongoing">On Going</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} lg={2} sx={{padding:"1%",marginTop:{xs:"5%",md:"2%",lg:"0"}}}>
              <TextField
                select
                value={selectedObjective}
                onChange={(e) => setSelectedObjective(e.target.value)}
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  backgroundColor: "#1F1F1F",
                  "& .MuiInputBase-root": {
                    color: "white",
                  },
                }}
              >
                <MenuItem value="objective" disabled>
                  Experiment objective
                </MenuItem>
                <MenuItem value="objective2">Objective 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} lg={2} sx={{padding:"1%",marginTop:{xs:"5%",md:"2%",lg:"0"}}}>
              <TextField
                select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  backgroundColor: "#1F1F1F",

                  "& .MuiInputBase-root": {
                    color: "white",
                  },
                }}
              >
                <MenuItem value="type" disabled>
                  Experiment Type
                </MenuItem>
                <MenuItem value="type2">Type 2</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} lg={2} sx={{padding:"1%",marginTop:{xs:"5%",md:"2%",lg:"0"}}}>
              <Typography
                sx={{
                  backgroundColor: "#1F1F1F",
                  fontWeight: 500,
                  borderRadius: "4px",
                  alignItems: "center",
                  display: "flex",
                  marginLeft: "auto",
                  justifyContent: "center",
                  color: "white",
                  textTransform: "none",
                  width: "125px",
                  height: "44px",
                  padding: "5px",
                  boxShadow: "none",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  style={{ marginRight: "5%" }}
                >
                  <path
                    d="M2.5 4.6C2.5 4.03995 2.5 3.75992 2.60899 3.54601C2.70487 3.35785 2.85785 3.20487 3.04601 3.10899C3.25992 3 3.53995 3 4.1 3H20.9C21.4601 3 21.7401 3 21.954 3.10899C22.1422 3.20487 22.2951 3.35785 22.391 3.54601C22.5 3.75992 22.5 4.03995 22.5 4.6V5.26939C22.5 5.53819 22.5 5.67259 22.4672 5.79756C22.438 5.90831 22.3901 6.01323 22.3255 6.10776C22.2526 6.21443 22.151 6.30245 21.9479 6.4785L15.5521 12.0215C15.349 12.1975 15.2474 12.2856 15.1745 12.3922C15.1099 12.4868 15.062 12.5917 15.0328 12.7024C15 12.8274 15 12.9618 15 13.2306V18.4584C15 18.6539 15 18.7517 14.9685 18.8363C14.9406 18.911 14.8953 18.9779 14.8363 19.0315C14.7695 19.0922 14.6787 19.1285 14.4971 19.2012L11.0971 20.5612C10.7296 20.7082 10.5458 20.7817 10.3983 20.751C10.2693 20.7242 10.1561 20.6476 10.0833 20.5377C10 20.4122 10 20.2142 10 19.8184V13.2306C10 12.9618 10 12.8274 9.96715 12.7024C9.93805 12.5917 9.89014 12.4868 9.82551 12.3922C9.75258 12.2856 9.65102 12.1975 9.44789 12.0215L3.05211 6.4785C2.84898 6.30245 2.74742 6.21443 2.67449 6.10776C2.60986 6.01323 2.56195 5.90831 2.53285 5.79756C2.5 5.67259 2.5 5.53819 2.5 5.26939V4.6Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Filters
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", margin: "1%" }}>
        {filteredExperiments.length > 0 ? (
        <List>
        {filteredExperiments.map((experiment, index) => (
          <React.Fragment key={experiment.id}>
            {index !== 0 && <Divider />}
            <Grid
              container
              sx={{
                marginBottom: "16px",
                backgroundColor: "#2E2E2E",
                borderRadius: "8px",
              }}
            >
              <Grid item xs={12}>
                <ListItem>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex ",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      style={{ marginRight: "20px" }}
                    >
                      <circle cx="22" cy="22" r="22" fill="#808080" />
                      <text
                        x="50%"
                        y="55%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize="18"
                        fontWeight={600}
                        fill="white"
                      >
                        {index + 1}
                      </text>
                    </svg>

                    <ListItemText
                      primary={
                        <Typography>
                          <Typography
                            variant="h1"
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Experiment ID:
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {experiment.id}
                          </Typography>
                        </Typography>
                      }
                    />

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ marginRight: "1em" }}
                    />
                    <ListItemText
                      primary={
                        <Typography>
                          <Typography
                            variant="h1"
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Experiment Name:
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {experiment.experimentName}
                          </Typography>
                        </Typography>
                      }
                    />

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ marginRight: "1em" }}
                    />
                    <ListItemText
                      primary={
                        <Typography>
                          <Typography
                            variant="h1"
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Target Group:
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {experiment.targetGroup}
                          </Typography>
                        </Typography>
                      }
                    />

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ marginRight: "1em" }}
                    />
                    <ListItemText
                      primary={
                        <Typography>
                          <Typography
                            variant="h1"
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Total Number of Customers:{" "}
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {experiment.total}
                          </Typography>
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <Button
                      variant="outlined"
                      component={Link}
                      to={`/show-customer/${experiment.id}`}
                      sx={{
                        borderRadius: "5px",
                        textAlign: "center",
                        height: "100%",
                        color: "#B9D2FF",
                        borderColor: "#B9D2FF",
                        "&:hover": {
                          backgroundColor: "#6e7480",
                        },
                        textTransform: "none",
                      }}
                    >
                      Details
                    </Button>
                    <Button
                      variant="standard"
                      component={Link}
                      to={`/show-customer/${experiment.id}`}
                      sx={{
                        textAlign: "center",
                        height: "100%",
                        "&:hover": {
                          backgroundColor: "#6e7480",
                        },
                        textTransform: "none",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Grid>
                </ListItem>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </List>
        ) : null}
      </Grid>
      <ResultModal
        open={openModal}
        handleClose={handleClose}
        experiment={result}
      />
    </>
  );
};

export default ExperimentComponent;
