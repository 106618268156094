import React, {useEffect, useState} from "react";
import { useParams, useLocation } from "react-router-dom";
import MessageBanner from '../../components/messageBanner';
import { getNegotiationByToken } from "../../api/negotiation";
import SlidersPageComponent from "./SlidersPageComponent";

import OffersPageComponent from "./OffersPageComponent";
const NegotiationPhases = {
    INITIAL : 'initial',
    CHECK_CUSTOMER_SEGMENT : 'check customer segment',
    RANK_VALUE_FEATURES : 'rank value features',
    CHECK_NEGOTIATIONS : 'check negotiations',
    FIRST_PROPOSAL : 'first proposal',
    CUSTOMER_CHOICE : 'customer choice',
    SECOND_PROPOSAL : 'second proposal',
    CUSTOMER_TEXT : 'customer text',
    ASK_ACCOUNT_MANAGER : 'ask account manager',
    COMPLETED : 'completed',
    WAITING_ACCOUNT_MANAGER : 'waiting account manager',
};

function OfferPage() {
    const {token} = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const message_ = searchParams.get('message');

    const [message, setMessage] = useState(message_ || '');
    const [negotiation, setNegotiation] = useState(null);

    useEffect(()=>{
        getNegotiationByToken(token)
            .then(resp=>setNegotiation(resp.data))
            .catch(()=>setMessage('An error occured.'));
    }, []);

    if (!negotiation) {
        return (<><MessageBanner message={message}/></>);
    }

    if (negotiation.phase == NegotiationPhases.RANK_VALUE_FEATURES) {
        return (
        <SlidersPageComponent
            token={token}
            negotiation={negotiation}
            setNegotiation={setNegotiation}
            setMessage={setMessage}
        />);
    }

    if (negotiation.phase == NegotiationPhases.FIRST_PROPOSAL || negotiation.phase == NegotiationPhases.SECOND_PROPOSAL) {
        return (
        <OffersPageComponent
            token={token}
            negotiation={negotiation}
            setNegotiation={setNegotiation}
            setMessage={setMessage}
        />);
    }

    if (negotiation.phase == NegotiationPhases.CUSTOMER_CHOICE) {
        return (
        <OffersPageComponent
            token={token}
            negotiation={negotiation}
            setNegotiation={setNegotiation}
            setMessage={setMessage}
        />);
    }

    if (negotiation.phase == NegotiationPhases.ASK_ACCOUNT_MANAGER) {
        return (
        <OffersPageComponent
            token={token}
            negotiation={negotiation}
            setNegotiation={setNegotiation}
            setMessage={setMessage}
        />);
    }
    
    if (negotiation.phase == NegotiationPhases.WAITING_ACCOUNT_MANAGER) {
        return (
            <h1>
                WAITING ACCOUNT MANAGER
            </h1>
        );
    }

    if (negotiation.phase == NegotiationPhases.COMPLETED) {
        return (
            <h1>
                CONGRATULATIONS, NEGOTIATION IS COMPLETED.
            </h1>
        );
    }

    return (
        <div>
            <h2>Hello {negotiation.customer.businessName}</h2>
        {token}
        <MessageBanner message={message}/>
        </div>
    );
}

export default OfferPage;