/** @format */

// components/Signup.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCustomer, saveCustomer, deleteCustomer } from "../../api/customers";
import { listSubscriptionModels } from "../../api/subscription.plans";
import MessageBanner from "../../components/messageBanner";
import {
  getCustomerPlans,
  setCurrentCustomerPlanSubModel,
} from "../../api/customers";

import {
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  TextField,
  Grid,
  Divider,
  Stack,
  Popover,
} from "@mui/material";
import DataStorage from "../../storage";
import CurrentSubsComponent from "./CurrentSubsComponent";
import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GeneralInfoComponent from "./GeneralInfoComponent";
import ExperimentsComponent from "./ExperimentsComponent";
import NegotiationsComponent from "./NegotiationsComponent";
function ShowCustomerPage() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [customer, setCustomer] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [customerPlans, setCustomerPlans] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [subscriptionModels, setSubscriptionModels] = useState(null);
  const [customerValFeatModelOpened, setCustomerValFeatModelOpened] =
    useState(false);
  const createdDate = new Date(customer.customerSince);
  const day = String(createdDate.getDate()).padStart(2, "0");
  const month = String(createdDate.getMonth() + 1).padStart(2, "0");
  const year = createdDate.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      getCustomer(DataStorage.get("token"), id)
        .then((resp) => setCustomer(resp.data))
        .catch(() => setMessage("An error occured."));
      getCustomerPlans(DataStorage.get("token"), id)
        .then((resp) => setCustomerPlans(resp.data))
        .catch(() => setMessage("An error occured."));
      listSubscriptionModels(DataStorage.get("token"))
        .then((resp) => setSubscriptionModels(resp.data))
        .catch(() => setMessage("An error occured."));
    }
  }, []);

  const handleCustomerEdited = () => {
    getCustomer(DataStorage.get("token"), id)
      .then((resp) => setCustomer(resp.data))
      .catch(() => setMessage("An error occured."));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id1 = open ? "simple-popover" : undefined;

  const handleInputChange = (field, value) => {
    if (field === "externalFunding") {
      if (value === "true") {
        setCustomer({ ...customer, [field]: true });
      } else {
        setCustomer({ ...customer, [field]: false });
      }
    } else {
      setCustomer({ ...customer, [field]: value });
    }
  };

  const handleSave = () => {
    saveCustomer(DataStorage.get("token"), id, customer)
      .then((resp) => setCustomer(resp.data))
      .catch(() => setMessage("An error occured."));
  };

  const handleDelete = () => {
    deleteCustomer(DataStorage.get("token"), id)
      .then((resp) => navigate("/customers"))
      .catch(() => setMessage("An error occured."));
  };

  const handleSubModelChange = (e) => {
    setCurrentCustomerPlanSubModel(DataStorage.get("token"), id, e.target.value)
      .then((resp) => setCustomerPlans(resp.data))
      .catch(() => setMessage("An error occured."));
  };

  if (
    subscriptionModels &&
    customerPlans &&
    subscriptionModels.findIndex(
      (model) => model.id === customerPlans?.currentCustomerPlan?.subModel
    ) === -1
  ) {
    return (
      <div>
        <h2>Select Current Subscription Model First</h2>
        <div>
          <label>Subscription Model:</label>
          <select
            name="subModel"
            value={customerPlans?.currentCustomerPlan?.subModel}
            onChange={handleSubModelChange}
          >
            <option value="">Select a subscription model</option>
            {subscriptionModels.map((subModel, index) => (
              <option key={index} value={subModel.id}>
                {subModel.plan.name + " - " + subModel.interval}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        backgroundColor: "#171717",
        alignContent: "flex-start",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id1}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1%" }}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: "0px",
            backgroundColor: "#1F1F1F",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
            minHeight: "100vh",
          }}
        >
          <Grid container>
            <Grid
              item
              sx={{ margin: "2%" }}
              xs={1}
              flexDirection={"row"}
              display={"flex"}
              alignItems={"center"}
              onClick={() => navigate("/customers")}
            >
              <Typography
                sx={{
                  backgroundColor: "#808080",
                  width: "32px",
                  height: "32px",
                  borderRadius: "100px",
                }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  color="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 16H10M10 16L16 22M10 16L16 10"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>

              <Typography
                marginLeft={"10%"}
                sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
              >
                Details
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{padding:"1%"}}>
              <GeneralInfoComponent
                customer={customer}
                formattedDate={formattedDate}
                handleCustomerEdited={handleCustomerEdited}
              />
              {customerPlans ? (
                <CurrentSubsComponent
                  customerPlans={customerPlans}
                  navigate={navigate}
                  customer={customer}
                />
              ) : null}
              <ExperimentsComponent />
              <NegotiationsComponent />
            </Grid>
            <Grid item xs={12}>
              <MessageBanner message={message} setMessage={setMessage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ShowCustomerPage;
