/** @format */

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemButton,
  Link,
  TextField,
  Box,
} from "@mui/material";
function ModalComponent({ open, handleClose }) {
  const customers = [
    {
      id: 1,
      currentPlan: "Plan 1",
      businessName: "Business Name 1",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 2,
      currentPlan: "Plan 2",
      businessName: "Business Name 2",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 3,
      currentPlan: "Plan 3",
      businessName: "Business Name 3",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 4,
      currentPlan: "Plan 4",
      businessName: "Business Name 4",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 5,
      currentPlan: "Plan 5",
      businessName: "Business Name 5",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 6,
      currentPlan: "Plan 6",
      businessName: "Business Name 6",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 7,
      currentPlan: "Plan 7",
      businessName: "Business Name 7",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 8,
      currentPlan: "Plan 8",
      businessName: "Business Name 8",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 9,
      currentPlan: "Plan 9",
      businessName: "Business Name 9",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
    {
      id: 10,
      currentPlan: "Plan 10",
      businessName: "Business Name 10",
      subcriptionDueDate: 0.0,
      recommended: "SomeText",
    },
  ];
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    const searchValue = searchText.toLowerCase();
    setFilteredCustomers(
      customers.filter((item) => {
        return (
          item.businessName.toLowerCase().includes(searchValue) ||
          searchValue === ""
        );
      })
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
      <DialogTitle sx={{ marginLeft: "auto" }}>
        {" "}
        <Button onClick={handleClose} color="primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </DialogTitle>

      <DialogTitle
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "rgba(16, 24, 40, 1)",
          fontSize: "24px",
          fontWeight: 600,
          gap: "5px",
        }}
      >
        Pending Renewal/Subscription Strategies
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ marginBottom: "5%" }}>
          <TextField
            placeholder="Search Customer"
            variant="outlined"
            value={searchText}
            autoComplete="off"
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                setFilteredCustomers(customers);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            style={{
              borderRadius: "5px",
              width: "100%",
              borderWidth: "1px",
              borderColor: "#BFC0C2",
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
            <List>
              {filteredCustomers.map((customer, index) => (
                <React.Fragment key={customer.id}>
                  {index !== 0 && <Divider />}
                  <Grid
                    container
                    sx={{
                      marginBottom: "16px",
                      backgroundColor: "#2E2E2E",
                      borderRadius: "8px",
                    }}
                  >
                    <Grid item xs={12}>
                      <ListItem>
                        <Grid
                          item
                          xs={10}
                          sx={{
                            display: "flex ",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            style={{ marginRight: "20px" }}
                          >
                            <circle cx="22" cy="22" r="22" fill="#AFBACC" />
                          </svg>
                          <ListItemText
                            primary={
                              <Typography>
                                <Typography
                                  variant="h1"
                                  style={{
                                    color: "rgba(128, 128, 128, 1)",
                                    fontWeight: 600,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  Business Name:
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#FFFFFF",
                                    fontWeight: 300,
                                    fontSize: "1rem",
                                  }}
                                >
                                  {customer.businessName}
                                </Typography>
                              </Typography>
                            }
                          />

                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ marginRight: "1em" }}
                          />
                          <ListItemText
                            primary={
                              <Typography>
                                <Typography
                                  variant="h1"
                                  style={{
                                    color: "rgba(128, 128, 128, 1)",
                                    fontWeight: 600,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  Current Plan:
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#FFFFFF",
                                    fontWeight: 300,
                                    fontSize: "1rem",
                                  }}
                                >
                                  {customer.currentPlan}
                                </Typography>
                              </Typography>
                            }
                          />

                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ marginRight: "1em" }}
                          />
                          <ListItemText
                            primary={
                              <Typography>
                                <Typography
                                  variant="h1"
                                  style={{
                                    color: "rgba(128, 128, 128, 1)",
                                    fontWeight: 600,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  Subscription Renewal Due in
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#FFFFFF",
                                    fontWeight: 300,
                                    fontSize: "1rem",
                                  }}
                                >
                                  {customer.subcriptionDueDate}
                                </Typography>
                              </Typography>
                            }
                          />

                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ marginRight: "1em" }}
                          />
                          <ListItemText
                            primary={
                              <Typography>
                                <Typography
                                  variant="h1"
                                  style={{
                                    color: "rgba(128, 128, 128, 1)",
                                    fontWeight: 600,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  Recommended Renewal/Subscription Strategy
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#FFFFFF",
                                    fontWeight: 300,
                                    fontSize: "1rem",
                                  }}
                                >
                                  {customer.recommended}
                                </Typography>
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <ListItem sx={{ justifyContent: "space-between" }}>
                            <ListItemButton
                              component={Link}
                              to={`/show-customer/${customer.id}`}
                              sx={{
                                backgroundColor: "rgba(128, 128, 128, 1)",
                                borderRadius: "5px",
                                textAlign: "center",
                                height: "100%",
                                "&:hover": {
                                  backgroundColor: "#6e7480",
                                },
                                width: "100px",
                              }}
                            >
                                                <ListItemText
                                primary="Detail"
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    color: "#B9D2FF",
                                    fontWeight: 300,
                                    fontSize: "1rem",
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </Grid>
                      </ListItem>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </List>

        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ModalComponent;
