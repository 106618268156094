import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';

const MessageBanner = ({ message, setMessage, duration }) => {

  useEffect(() => {
    if (duration !== undefined) {
      const timeout = setTimeout(() => {
        setMessage( '' );
      }, duration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [duration]);

  const handleBannerClick = () => {
    setMessage('');
  };

  return (
    <Paper
      elevation={3}
      sx={{
        display: message ? 'block' : 'none',
        position: 'fixed',
        top: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '10px',
        backgroundColor: 'blue',
        color: '#ffffff',
        cursor: 'pointer',
      }}
      onClick={handleBannerClick}
    >
      <Typography variant="body1">{message}</Typography>
    </Paper>
  );
};

export default MessageBanner;
