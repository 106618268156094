/** @format */

import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Grid, TextField, MenuItem, Typography } from "@mui/material";
function EditModal({ open, handleClose, handleSave, countryOptions, data }) {
  const [Data, setData] = useState();
  useEffect(() => {
    setData(data);
  }, [data]);
  const previousId = data?.groupdId;
  const handleChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      sx={{ height: "90%", marginTop: "2%" }}
      PaperProps={{
        sx: {
          backgroundColor: "#1F1F1F",
        },
      }}
    >
      <DialogTitle sx={{ marginLeft: "auto" }}>
        {" "}
        <Button onClick={handleClose} color="primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </DialogTitle>
      <DialogTitle
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "White",
          fontSize: "24px",
          fontWeight: 600,
          gap: "5px",
        }}
      >
        Edits
      </DialogTitle>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <TextField
          label="Group ID"
          name="groupdId"
          autoComplete="off"
          value={Data?.groupdId}
          onChange={(e) => handleChange(e)}
          placeholder="Enter a group name"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <TextField
          select
          name="country"
          label="Customer located in specific Countries"
          value={Data?.country}
          onChange={(e) => handleChange(e)}
          sx={{
            width: "100%",
            borderRadius: "4px",
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              fill: "rgba(128, 128, 128, 1)", // Ok (dropdown) ikonunun rengi
            },
          }}
        >
          <MenuItem value="placeholder" disabled>
            Choose Country
          </MenuItem>
          {countryOptions.map((country, index) => (
            <MenuItem key={index} value={country.value}>
              {country.label}{" "}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}>
          Free or Trial or Paid Customer ?
        </Typography>
        <Button
          name="plan"
          value="free"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            textTransform: "none",
            minWidth: "25%",
            marginTop: "2%",
            color: Data?.plan == "free" ? "#B9D2FF" : "white",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor:
              Data?.plan == "free" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Free
        </Button>
        <Button
          name="plan"
          value="trial"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "25%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.plan == "trial" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.plan == "trial" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Trial
        </Button>
        <Button
          name="plan"
          value="paid"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "25%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.plan == "paid" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.plan == "paid" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Paid
        </Button>
        <Button
          name="plan"
          value="all"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderLeft: "0",
            minWidth: "25%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.plan == "all" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.plan == "all" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          All
        </Button>
      </Grid>{" "}
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}>
          Subscription Term:
        </Typography>
        <Button
          name="subsTerm"
          value="monthly"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            textTransform: "none",
            minWidth: "33.3%",
            marginTop: "2%",
            color: Data?.subsTerm == "monthly" ? "#B9D2FF" : "white",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor:
              Data?.subsTerm == "monthly" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Monthly
        </Button>
        <Button
          name="subsTerm"
          value="annually"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.subsTerm == "annually" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.subsTerm == "annually" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Annually
        </Button>

        <Button
          name="subsTerm"
          value="all"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.subsTerm == "all" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.subsTerm == "all" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          All
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}>
          Subscription Billing:
        </Typography>
        <Button
          name="subsBilling"
          value="monthly"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            textTransform: "none",
            minWidth: "33.3%",
            marginTop: "2%",
            color: Data?.subsBilling == "monthly" ? "#B9D2FF" : "white",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor:
              Data?.subsBilling == "monthly"
                ? "rgba(185, 210, 255, 0.04)"
                : null,
          }}
        >
          {" "}
          Monthly
        </Button>
        <Button
          name="subsBilling"
          value="annually"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.subsBilling == "annually" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.subsBilling == "annually"
                ? "rgba(185, 210, 255, 0.04)"
                : null,
          }}
        >
          {" "}
          Annually
        </Button>

        <Button
          name="subsBilling"
          value="all"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.subsBilling == "all" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.subsBilling == "all" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          All
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}>
          Customer Health Score:
        </Typography>
        <Button
          name="healthScore"
          value="green"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            textTransform: "none",
            minWidth: "33.3%",
            marginTop: "2%",
            color: Data?.healthScore == "green" ? "#B9D2FF" : "white",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor:
              Data?.healthScore == "green" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Green
        </Button>
        <Button
          name="healthScore"
          value="red"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.healthScore == "red" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.healthScore == "red" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          Red
        </Button>

        <Button
          name="healthScore"
          value="all"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.healthScore == "all" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.healthScore == "all" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          All
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}>
          Activated or Not Activated ?
        </Typography>
        <Button
          name="isActivated"
          value="activated"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            textTransform: "none",
            minWidth: "33.3%",
            marginTop: "2%",
            color: Data?.isActivated == "activated" ? "#B9D2FF" : "white",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor:
              Data?.isActivated == "activated"
                ? "rgba(185, 210, 255, 0.04)"
                : null,
          }}
        >
          {" "}
          Activated
        </Button>
        <Button
          name="isActivated"
          value="notActivated"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.isActivated == "notActivated" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.isActivated == "notActivated"
                ? "rgba(185, 210, 255, 0.04)"
                : null,
          }}
        >
          {" "}
          Not Activated
        </Button>

        <Button
          name="isActivated"
          value="all"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.isActivated == "all" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.isActivated == "all" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          All
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ padding: "3%", paddingTop: "2%" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}>
          Renewal Due in:
        </Typography>
        <Button
          name="renewalDue"
          value="90days"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            textTransform: "none",
            minWidth: "33.3%",
            marginTop: "2%",
            color: Data?.renewalDue == "90days" ? "#B9D2FF" : "white",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor:
              Data?.renewalDue == "90days" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          90 Days
        </Button>
        <Button
          name="renewalDue"
          value="60days"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.renewalDue == "60days" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.renewalDue == "60days" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          60 Days
        </Button>

        <Button
          name="renewalDue"
          value="all"
          onClick={(e) => handleChange(e)}
          sx={{
            border: "1px solid #808080",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderLeft: "0",
            minWidth: "33.3%",
            marginTop: "2%",
            textTransform: "none",
            color: Data?.renewalDue == "all" ? "#B9D2FF" : "white",
            backgroundColor:
              Data?.renewalDue == "all" ? "rgba(185, 210, 255, 0.04)" : null,
          }}
        >
          {" "}
          All
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        {" "}
        <Button
          variant="contained"
          onClick={() => handleSave(Data, previousId)}
          sx={{
            backgroundColor: "#B9D2FF",
            color: "black",
            textTransform: "none",
            whiteSpace: "nowrap",
            marginRight: "2%",
            minWidth: "20%",
          }}
        >
          Save
        </Button>
      </Grid>
    </Dialog>
  );
}

export default EditModal;
