/** @format */

import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  Button,
} from "@mui/material";
import React from "react";

const ResultModal = ({ open, handleClose, experiment }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          backgroundColor: "#171717",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle sx={{ justifyContent: "flex-end", display: "flex" }}>
        <Button onClick={() => handleClose()} color="primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </DialogTitle>

      <Grid container sx={{ padding: "2%" }}>
        {" "}
        <Grid
          container
          sx={{
            backgroundColor: "#2E2E2E",
            borderRadius: "12px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 600,
              color: "white",
              padding: "2%",
            }}
          >
            Baseline Pricing
          </Typography>
          <TableContainer
            sx={{
              overflowX: "scroll",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                height: "7px",
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#808080",
                borderRadius: 25,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderLeft: 0,
                    }}
                  >
                    Experiment ID
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Experiment Name
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Free plan Customers
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Trial Customers
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Paid customers
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Customers Plan Upgrades
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Customers purchasing Add-On features
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Conversion Free to Trial
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Conversion Trial to Paid
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Conversion of Customers plan upgrades{" "}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Net Add on Features Revenue
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Recurrent Revenue
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Average Revenue/paid Customer
                  </TableCell>{" "}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderLeft: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experiment?.id}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experiment?.experimentName}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          container
          sx={{
            backgroundColor: "#2E2E2E",
            borderRadius: "12px",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 600,
              color: "white",
              padding: "2%",
            }}
          >
            Experiment Outcomes
          </Typography>
          <TableContainer
            sx={{
              overflowX: "scroll",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                height: "7px",
                margin: "15px",
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#808080",
                borderRadius: 25,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderLeft: 0,
                    }}
                  ></TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderLeft: 0,
                    }}
                  >
                    Experiment ID
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Experiment Name
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Free plan Customers
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Trial Customers
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Paid customers
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Customers Plan Upgrades
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Customers purchasing Add-On features
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Conversion Free to Trial
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Conversion Trial to Paid
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Conversion of Customers plan upgrades{" "}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Net Add on Features Revenue
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Recurrent Revenue
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#808080",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Average Revenue/paid Customer
                  </TableCell>{" "}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      borderLeft: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Armset 2
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experiment?.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experiment?.experimentName}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderTop: 0,
                      borderBottom: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      borderLeft: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Armset 3
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experiment?.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {experiment?.experimentName}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "white",
                      border: "1px solid rgba(128, 128, 128, 0.2)",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      borderRight: 0,
                    }}
                  >
                    Some Value
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ResultModal;
