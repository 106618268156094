/** @format */

import React, { useState } from "react";
import { Grid, Typography, TextField, MenuItem } from "@mui/material";

/**
 *
 *
 * @param {*} { value, text }
 * @return {*}
 */
const CardComponent = ({ value, text }) => {
  const [selected, setSelected] = useState("annually");

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        padding: "5%",
        minHeight: "25vh",
        width: "90%",
      }}
    >
      <Grid item xs={12} sx={{ flexDirection: "row", display: "flex" }}>
        <Grid item xs={12} lg={4}>
          <Typography
            sx={{
              borderRadius: "8px",
              height: "48px",
              width: "48px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#1F1F1F",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7H13.1667C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginLeft: "auto" }}>
          <TextField
            select
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
            size="small"
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "#1F1F1F",
              "& .MuiSelect-root": {
                color: "white",
                fontSize: "14px",
                fontWeight: 500,
              },
              "& .MuiSelect-icon": {
                color: "#808080",
              },
              "& .MuiInputBase-root": {
                color: "white",
              },
            }}
          >
            <MenuItem value="annually">Annually</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "6%" }}>
        <Typography
          sx={{ fontSize: "34px", fontWeight: 600, whiteSpace: "nowrap",color:"white" }}
        >
          {value}
        </Typography>
        <Typography sx={{ fontSize: "14", fontWeight: 500, width: "100%",color:"#808080" }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardComponent;
