/** @format */

import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import Button from "@mui/material/Button";
import ViewValuesModal from "./ViewValueModal";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix="%"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ArmSets = ({ plan }) => {
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("null");
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (e) => {
    setValue(e.target.value);
  };
  const handleIncrement = () => {
    setStatus("inc");
    setValue((prevValue) => {
      const intValue = parseInt(prevValue, 10); // Parse to an integer
      return intValue + 1;
    });
  };

  const handleDecrement = () => {
    setStatus("dec");
    setValue((prevValue) => {
      const intValue = parseInt(prevValue, 10); // Parse to an integer
      return intValue - 1;
    });
  };

  const StyledTextField = () => {
    return (
      <TextField
        value={value}
        label="Enter Percentage"
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(128, 128, 128, 1)",
          },
          "& .MuiInputBase-root": {
            color:
              status == "inc"
                ? "#47D647"
                : status == "dec"
                ? "#D32F2F"
                : "rgba(128, 128, 128, 1)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(128, 128, 128, 1)",
          },
        }}
        onFocus={(e) => e.target.select()}
        onChange={handleValueChange}
        onKeyDown={()=>setStatus("null")}
        InputProps={{
          inputComponent: NumericFormatCustom,
          max: 100,
          min: 0,
          startAdornment: (
            <InputAdornment position="start">
              {status == "dec" ? (
                <Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <path
                      d="M5 1V11M5 11L9 6M5 11L1 6"
                      stroke="#D32F2F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Typography>
              ) : status == "inc" ? (
                <Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <path
                      d="M5 11L5 0.999999M5 0.999999L1 6M5 0.999999L9 6"
                      stroke="#47D647"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Typography>
              ) : null}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                width: "30%",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Typography
                onClick={handleDecrement}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#1F1F1F80",
                  borderRadius: "50%",
                  padding: "10%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginRight: "5%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 10 12"
                  fill="none"
                >
                  <path
                    d="M5 1V11M5 11L9 6M5 11L1 6"
                    stroke="#808080"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
              <Typography
                onClick={handleIncrement}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#1F1F1F80",
                  borderRadius: "50%",
                  padding: "10%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 10 12"
                  fill="none"
                >
                  <path
                    d="M5 11L5 0.999999M5 0.999999L1 6M5 0.999999L9 6"
                    stroke="#808080"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <>
      <Typography
        sx={{
          color: "white",
          fontSize: "24px",
          fontWeight: 600,
          marginTop: "5%",
        }}
      >
        Build Experiment Armsets
      </Typography>
      <Typography
        sx={{
          color: "#808080",
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        At least 3 armsets
      </Typography>

      <Typography
        sx={{
          color: "white",
          fontSize: "18px",
          fontWeight: 500,
          marginTop: "1%",
          marginBottom: "1%",
        }}
      >
        Armset 1 (Baseline):
      </Typography>

      <Grid
        container
        sx={{
          marginBottom: "16px",
          backgroundColor: "#2E2E2E",
          borderRadius: "8px",
          alignItems: "center",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderLeft: 0,
                  }}
                >
                  ID:1
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  Monthly Subscription Price{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  Annual Subscription billed annually Price{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",

                    borderRight: 0,
                  }}
                >
                  Annual subscription billed monthly Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderLeft: 0,
                  }}
                >
                  {plan} Baseline
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  30$
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  N/A
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  25$
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    borderLeft: 0,
                  }}
                >
                  Min Price Discount Baseline
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                  }}
                >
                  30%
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                  }}
                >
                  30%
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  30%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Typography
        sx={{
          color: "white",
          fontSize: "18px",
          fontWeight: 500,
          marginTop: "3%",
          marginBottom: "1%",
        }}
      >
        Armset 2:
      </Typography>

      <Grid
        container
        sx={{
          marginBottom: "16px",
          backgroundColor: "#2E2E2E",
          borderRadius: "8px",
          alignItems: "center",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderLeft: 0,
                  }}
                >
                  ID:2
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  Monthly Subscription Price{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  Annual Subscription billed annually Price{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",

                    borderRight: 0,
                  }}
                >
                  Annual subscription billed monthly Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderLeft: 0,
                  }}
                >
                  {plan}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  <StyledTextField />
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  <StyledTextField />
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  <StyledTextField />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    borderLeft: 0,
                  }}
                >
                  Min Price Discount
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                  }}
                >
                  <StyledTextField />
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                  }}
                >
                  <StyledTextField />
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  <StyledTextField />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
        <Button
          variant="standard"
          sx={{
            color: "white",
            textTransform: "none",
            gap: 1,
            marginRight: "1%",
          }}
        >
          <Typography
            onClick={() => setOpen(true)}
            sx={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}
          >
            {" "}
            View Values
          </Typography>{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            style={{ marginLeft: "2%" }}
          >
            <path
              d="M2.01677 10.5917C1.90328 10.412 1.84654 10.3222 1.81477 10.1836C1.79091 10.0795 1.79091 9.91531 1.81477 9.81122C1.84654 9.67263 1.90328 9.58278 2.01677 9.40308C2.95461 7.9181 5.74617 4.16406 10.0003 4.16406C14.2545 4.16406 17.0461 7.9181 17.9839 9.40308C18.0974 9.58278 18.1541 9.67263 18.1859 9.81122C18.2098 9.91531 18.2098 10.0795 18.1859 10.1836C18.1541 10.3222 18.0974 10.412 17.9839 10.5917C17.0461 12.0767 14.2545 15.8307 10.0003 15.8307C5.74617 15.8307 2.95461 12.0767 2.01677 10.5917Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.0003 12.4974C11.381 12.4974 12.5003 11.3781 12.5003 9.9974C12.5003 8.61668 11.381 7.4974 10.0003 7.4974C8.61962 7.4974 7.50034 8.61668 7.50034 9.9974C7.50034 11.3781 8.61962 12.4974 10.0003 12.4974Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#B9D2FF", textTransform: "none", gap: 1 }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              color: "black",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {" "}
            Edit
          </Typography>{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.0007 8.33619L11.6673 5.00286M2.08398 17.9195L4.90429 17.6062C5.24887 17.5679 5.42115 17.5487 5.58219 17.4966C5.72506 17.4503 5.86102 17.385 5.98639 17.3023C6.1277 17.2091 6.25027 17.0866 6.49542 16.8414L17.5007 5.83619C18.4211 4.91572 18.4211 3.42333 17.5007 2.50286C16.5802 1.58238 15.0878 1.58238 14.1673 2.50285L3.16209 13.5081C2.91694 13.7532 2.79436 13.8758 2.70118 14.0171C2.61851 14.1425 2.55316 14.2784 2.50691 14.4213C2.45478 14.5824 2.43564 14.7546 2.39735 15.0992L2.08398 17.9195Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "space-between",
          display: "flex",
          marginTop: "2%",
        }}
      >
        <Grid
          item
          sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #B9D2FF",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 5V19M5 12H19"
                stroke="#B9D2FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
          <Typography
            sx={{
              color: "#B9D2FF",
              marginLeft: "15%",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            Add
          </Typography>
        </Grid>

        <Button
          size="small"
          variant="contained"
          sx={{
            width: "30%",
            backgroundColor: "#B9D2FF",
            textTransform: "none",
            color: "black",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          Launch Experiment
        </Button>
      </Grid>
      <ViewValuesModal open={open} handleClose={handleClose} plan={plan}/>
    </>
  );
};
export default ArmSets;
