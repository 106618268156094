/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import {
  subscriptionPlanSettings,
  getSubscriptionPlanSettings,
} from "../../api/subscription.plans";
import { Button, Grid, TextField } from "@mui/material";
import DataStorage from "../../storage";
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      suffix="%"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PercentageTextField() {
  const [values, setValues] = React.useState([]);
  const [changed, setChanged] = React.useState(true);

  React.useEffect(() => {
    getSubscriptionPlanSettings(DataStorage.get("token")).then((resp) => {
      setValues(resp.data);
    });
  }, []);

  const handleChange = (e) => {
  
    setValues({
      ...values,
      [e.target.name]: Number(e.target.value),
    });
    setChanged(false);
  };
  const handleSave = () => {
    console.log(values);
    subscriptionPlanSettings(DataStorage.get("token"), values)
      .then(() => console.log("updated"))
      .catch((error) => console.log(error));

    setChanged(true);
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        margin: "auto",
        marginTop: "2%",
        marginBottom: "5%",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      {Object.keys(values).length > 0 ? (
        <>
        
          <Grid
            item
            xs={11}
            lg={5.7}
            sx={{ margin: "2% 0 2% 0", marginTop: { xs: "10%",lg:"2%",md:"5%" } }}
          >
            <TextField
              label="Enter discount 2 years subscription versus Annual subscription"
              fullWidth
              value={values.D2}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="D2"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>

          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter discount 3 years subscription versus Annual subscription"
              fullWidth
              value={values.D3}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="D3"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Premium over annual billing for annual subscription billed monthly"
              fullWidth
              value={values.P1}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="P1"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Premium over 2 years billing for 2 years subscription billed monthly"
              fullWidth
              value={values.P2}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="P2"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Premium over 3 years billing for 3 years subscription billed annually"
              fullWidth
              value={values.P5}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="P5"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Premium over 2 years billing for 2 years subscription billed annually"
              fullWidth
              value={values.P3}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="P3"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Premium over 3 years billing for 3 years subscription billed monthly"
              fullWidth
              value={values.P4}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="P4"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid
            item
            xs={11}
            lg={5.7}
            sx={{ margin: "2% 0 2% 0", marginTop: { xs: "10%",lg:"2%",md:"5%" } }}
          >
            <TextField
              label="Enter Reservation percentage for new paid Bootstrapper-/Bootstrapper "
              fullWidth
              value={values.Dmin}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="Dmin"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Reservation percentage for new paid Bootstrapper+
              "
              fullWidth
              value={values.R1}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="R1"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Reservation percentage for new paid x1
              "
              fullWidth
              value={values.R2}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="R2"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
            <TextField
              label="Enter Reservation percentage for renewals of x1 x2 x3
              "
              fullWidth
              value={values.R3}
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              onChange={(e) => handleChange(e)}
              name="R3"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>

          <Grid xs={12} sx={{ display: "flex" }}>
            {" "}
            <Button
              variant="outlined"
              disabled={changed}
              onClick={() => handleSave()}
              sx={{
                borderColor: "#B9D2FF",
                textTransform: "none",
                color: "#B9D2FF",
                margin: "1%",
                marginRight: "2%",
                marginLeft: "auto",
                "&.Mui-disabled": { color: "#808080", borderColor: "#808080" },
              }}
            >
              Save
            </Button>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
