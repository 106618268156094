/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { listCustomers } from "../../api/customers";
import {
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  IconButton,
  TextField,
  Grid,
  Divider,
  MenuItem,
  Select,
  Stack,
  Popover,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MessageBanner from "../../components/messageBanner";
import getSymbolFromCurrency from "@rea-app/currency-symbol-map";

function CustomersPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [customers, setCustomers] = useState([]);
  const [select, setSelect] = useState(60);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [focused, setFocused] = useState(false);
  const selectList = [10, 20, 30, 40, 50, 60, "more"];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSearch = () => {
    const searchValue = searchText.toLowerCase();
    setFilteredCustomers(
      customers.filter((item) => {
        return (
          item.businessName.toLowerCase().includes(searchValue) ||
          item.administratorName.toLowerCase().includes(searchValue) ||
          searchValue === ""
        );
      })
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      listCustomers(localStorage.getItem("token"))
        .then((resp) => {
          setCustomers(
            resp.data.sort(
              (a, b) => a.subcriptionDueDate - b.subcriptionDueDate
            )
          );
          setFilteredCustomers(
            resp.data.sort(
              (a, b) => a.subcriptionDueDate - b.subcriptionDueDate
            )
          );
        })
        .catch(() => setMessage("An error occurred."));
    }
  }, []);

  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    setSelect(selectedValue);

    if (selectedValue === "more") {
      setFilteredCustomers(customers);
    } else {
      const filteredAndSortedCustomers = customers.filter(
        (item) => item.subcriptionDueDate <= selectedValue
      );
      setFilteredCustomers(filteredAndSortedCustomers);
    }
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#171717",
        alignContent: "flex-start",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          margin: "1%",
          marginBottom: "0px",
          backgroundColor: "#1F1F1F",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          minHeight: "100vh",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container xs={12} spacing={2} sx={{ padding: "2%" }}>
              <Grid item xs={12} lg={3} sx={{ paddingLeft: "3%" }}>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
                >
                  Existing Customer
                </Typography>
              </Grid>
              <Grid item lg={4} xs={12}>
                <TextField
                  placeholder="Search Customer"
                  variant="outlined"
                  value={searchText}
                  autoComplete="off"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value === "") {
                      setFilteredCustomers(customers);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    borderWidth: "1px",
                    borderColor: "#BFC0C2",
                  }}
                  InputProps={{
                    sx: {
                      color: "#C3C8CF",
                      fontWeight: "600",
                      fontSize: "14px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#BFC0C2",
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearch}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g opacity="0.3">
                              <path
                                d="M17.5 17.5L12.5001 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      display: "block",
                      color: focused || searchText ? "black" : "#C3C8CF",

                      bold: 900,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                lg={3}
                xs={8}
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    display: "flex",
                    fontWeight: 300,
                    paddingRight: "2%",
                  }}
                >
                  Subscription Renewal Due in:
                </Typography>
                <Select
                  value={select}
                  onChange={handleSelect}
                  sx={{
                    backgroundColor: "#2E2E2E",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: 300,
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#8E99AB",
                        "& li:hover": {
                          backgroundColor: "#656970",
                        },
                        "&& .Mui-selected": {
                          background: "rgba(128, 128, 128, 1)",
                        },
                      },
                    },
                  }}
                >
                  {selectList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{ color: "white", fontWeight: 600 }}
                    >
                      {item + " days"}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={4} lg={2}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#B9D2FF",
                    textTransform: "none",
                    width: "100%",
                    height: "100%",
                    "&:hover": {
                      backgroundColor: "#8E99AB",
                    },
                    color: "black",
                  }}
                  onClick={() => navigate("/new-customer")}
                >
                  Add New Customer
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "center" }}>
            {filteredCustomers.length > 0 ? (
              <List sx={{ padding: "1%" }}>
                {filteredCustomers.map((customer, index) => (
                  <React.Fragment key={customer.id}>
                    {index !== 0 && <Divider />}
                    <Grid
                      container
                      sx={{
                        marginBottom: "16px",
                        backgroundColor: "#2E2E2E",
                        borderRadius: "8px",
                        padding: "1%",
                      }}
                    >
                      <Grid></Grid>
                      <Grid container xs={10}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="44"
                          height="44"
                          viewBox="0 0 44 44"
                          fill="none"
                          style={{ marginRight: "20px" }}
                        >
                          <circle cx="22" cy="22" r="22" fill="#AFBACC" />
                        </svg>

                        <Grid item xs={2}>
                          <Typography
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Business Name:
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {customer.businessName}
                          </Typography>
                        </Grid>

                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            marginRight: "1em",
                            backgroundColor: "#808080",
                          }}
                        />

                        <Grid item xs={2}>
                          <Typography
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Administrator Name:
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {customer.administratorName}
                          </Typography>
                        </Grid>

                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            marginRight: "1em",
                            backgroundColor: "#808080",
                          }}
                        />

                        <Grid item xs={2}>
                          <Typography
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Estimated Revenue
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {getSymbolFromCurrency(customer.billingCurrency) } {customer.estimatedRevenueInUSD}
                          </Typography>
                        </Grid>

                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            marginRight: "1em",
                            backgroundColor: "#808080",
                          }}
                        />

                        <Grid item xs={2}>
                          <Typography
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Special Pricing Awardee
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {customer.SpeciaPricingAwardee ? "Yes" : "No"}
                          </Typography>
                        </Grid>

                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            marginRight: "1em",
                            backgroundColor: "#808080",
                          }}
                        />

                        <Grid item xs={2}>
                          <Typography
                            style={{
                              color: "rgba(128, 128, 128, 1)",
                              fontWeight: 600,
                              fontSize: "0.875rem",
                            }}
                          >
                            Subscription Ends in
                          </Typography>
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              fontWeight: 300,
                              fontSize: "1rem",
                            }}
                          >
                            {customer.subcriptionDueDate} days
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <Button
                          variant="outlined"
                          component={Link}
                          to={`/show-customer/${customer.id}`}
                          sx={{
                            borderRadius: "5px",
                            textAlign: "center",
                            height: "100%",
                            color: "#B9D2FF",
                            borderColor: "#B9D2FF",
                            "&:hover": {
                              backgroundColor: "#6e7480",
                            },
                            textTransform: "none",
                          }}
                        >
                          Details
                        </Button>
                        <Button
                          variant="standard"
                          component={Link}
                          to={`/show-customer/${customer.id}`}
                          sx={{
                            textAlign: "center",
                            height: "100%",
                            "&:hover": {
                              backgroundColor: "#6e7480",
                            },
                            textTransform: "none",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <Box mt={2}>
                <Typography variant="subtitle1">
                  No Customers available.
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <MessageBanner message={message} setMessage={setMessage} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CustomersPage;
