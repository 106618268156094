/** @format */

import React, { useEffect, useState } from "react";
import { getOrderedValueFeaturesByToken } from "../../api/negotiation";
import { Typography, Button, Slider, Grid, Divider } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
const PreferencesPage = (props) => {
  const { token, setMessage, setShowFeatures } = props;
  const [selectedValueFeatures, setSelectedValueFeatures] = useState([]);
  useEffect(() => {
    getOrderedValueFeaturesByToken(token)
      .then(
        (resp) => (
          console.log(resp.data),
          setSelectedValueFeatures(
            resp.data.map((d) => {
              return { ...d, rank: 1.0 / resp.data.length };
            })
          )
        )
      )
      .catch(() => setMessage("An error occurred."), console.log("hata"));
  }, []);

  const showOffer = () => {
    setShowFeatures(false);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#EEF2F6",
          height: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: "5%",
        }}
      >
        <Grid item xs={11} md={8}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "white",
              borderRadius: "24px",
              padding: "2%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="116"
                height="20"
                viewBox="0 0 116 20"
                fill="none"
              >
                <path
                  d="M18.2188 17.1545C16.2057 19.0515 13.7559 20 10.8692 20C7.98254 20 5.53267 19.0515 3.5196 17.1545C1.50653 15.2575 0.5 12.9178 0.5 10.1355C0.5 7.35321 1.50653 5.01355 3.5196 3.11653C5.53267 1.21951 7.98254 0.271002 10.8692 0.271002C13.7559 0.271002 16.2057 1.21951 18.2188 3.11653C20.2319 5.01355 21.2384 7.35321 21.2384 10.1355C21.2384 12.9178 20.2319 15.2575 18.2188 17.1545ZM6.71013 14.2005C7.83061 15.2484 9.21697 15.7724 10.8692 15.7724C12.5214 15.7724 13.8983 15.2484 14.9998 14.2005C16.1203 13.1527 16.6805 11.7977 16.6805 10.1355C16.6805 8.47335 16.1203 7.11834 14.9998 6.07046C13.8983 5.02258 12.5214 4.47864 10.8692 4.47864C9.21697 4.47864 7.83061 5.02258 6.71013 6.07046C5.60864 7.11834 5.05789 8.47335 5.05789 10.1355C5.05789 11.7977 5.60864 13.1527 6.71013 14.2005Z"
                  fill="#101828"
                />
                <path
                  d="M31.4761 4.17344C29.7299 4.02891 28.8468 4.66125 28.8468 6.07046H31.4761V9.9729H28.8468V19.6206H24.5738V9.9729H22.6652V6.07046H24.5738C24.5738 4.11924 25.1531 2.63776 26.3115 1.62602C27.47 0.596206 29.1982 0.144535 31.4761 0.271002V4.17344Z"
                  fill="#101828"
                />
                <path
                  d="M41.0381 4.17344C39.2719 4.02891 38.3888 4.66125 38.3888 6.07046H41.0381V9.9729H38.3888V19.6206H34.1158V9.9729H32.2072V6.07046H34.1158C34.1158 4.11924 34.695 2.63776 35.8535 1.62602C37.012 0.596206 38.7402 0.144535 41.0381 0.271002V4.17344Z"
                  fill="#101828"
                />
                <path
                  d="M46.007 14.4715C46.5008 15.7362 47.6403 16.3686 47.4255 16.3686C50.5839 16.3686 51.4755 16.0253 52.1602 15.3388L55.5786 17.2087C54.1733 19.0696 52.1032 20 47.3685 20C46.9756 20 45.0575 19.3225 43.6141 17.9675C42.1898 16.6125 41.4776 14.9051 41.4776 12.8455C41.4776 10.804 42.1803 9.10569 43.5857 7.75068C45.01 6.3776 46.8332 5.69106 47.0551 5.69106C51.1252 5.69106 52.8439 6.3776 54.2113 7.75068C55.5976 9.10569 56.2908 10.804 56.2908 12.8455C56.2908 13.4237 56.2338 13.9657 56.1199 14.4715H46.007ZM45.9216 11.4363H52.0463C51.6284 10.009 50.6219 9.29539 47.0266 9.29539C47.3744 9.29539 46.3394 10.009 45.9216 11.4363Z"
                  fill="#101828"
                />
                <path
                  d="M62.9874 8.48239C63.2533 7.61518 63.785 6.95574 64.5827 6.50407C65.3993 6.03433 66.2919 5.79946 67.2604 5.79946V10.4065C66.2159 10.2439 65.2379 10.4155 64.3263 10.9214C63.4337 11.4092 62.9874 12.2674 62.9874 13.4759V19.6206H58.7144V6.07046H62.9874V8.48239Z"
                  fill="#101828"
                />
                <path
                  d="M73.4739 19.6206L67.976 0.650406H72.7617L76.2086 13.9295L80.0544 0.650406H83.7577L87.6034 13.9295L91.0503 0.650406H95.8361L90.3381 19.6206H85.1535L81.906 8.42818L78.6585 19.6206H73.4739Z"
                  fill="#101828"
                />
                <path
                  d="M101.064 4.17344C100.57 4.64318 99.9719 4.87805 99.2692 4.87805C98.5665 4.87805 97.9588 4.64318 97.446 4.17344C96.9523 3.68564 96.7054 3.1075 96.7054 2.43903C96.7054 1.77055 96.9523 1.20145 97.446 0.731708C97.9588 0.243903 98.5665 0 99.2692 0C99.9719 0 100.57 0.243903 101.064 0.731708C101.577 1.20145 101.833 1.77055 101.833 2.43903C101.833 3.1075 101.577 3.68564 101.064 4.17344ZM97.1327 19.6206V6.07046H101.406V19.6206H97.1327Z"
                  fill="#101828"
                />
                <path
                  d="M109.831 15.8266H115.5V19.6206H104.105V16.9106L109.489 9.8645H104.39V6.07046H115.215V8.78047L109.831 15.8266Z"
                  fill="#101828"
                />
              </svg>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "white",
              borderRadius: "24px",
              marginTop: "2%",
            }}
          >
            <Typography
              sx={{ padding: "3%", fontSize: "16px", fontWeight: 600 }}
            >
              Value Feature Ranking & desired quantities
            </Typography>
            <Divider width="100%" />

            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "5%",
                paddingLeft: "5%",
                paddingTop: "3%",
              }}
            >
              {selectedValueFeatures.map((valFeat, index) => (
                <Grid
                  container
                  style={{
                    userSelect: "none",
                    padding: 16,
                    margin: `0 0 8px 0`,
                    background: "white",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {" "}
                  <Grid
                    item
                    xs={0.5}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    <DragHandleIcon sx={{color:"#808080"}} />
                  </Grid>
                  <Grid item xs={11.5}>
                    <Typography>
                      {index + 1}. {valFeat.description}
                    </Typography>
                    {(valFeat.typ !== "yesno" && (
                      <Slider
                        disabled
                        style={{ color: "#A8A8A8", marginTop: "2%" }}
                        value={
                          valFeat.amount != -1
                            ? valFeat.amount
                            : valFeat.maxValue
                        }
                        valueLabelDisplay="on"
                        onChange={(e, newValue) => {
                          const updatedValueFeatures = [
                            ...selectedValueFeatures,
                          ];
                          updatedValueFeatures[index].amount = newValue;
                          setSelectedValueFeatures(updatedValueFeatures);
                        }}
                        marks={[
                          {
                            value: 0,
                            label: 0,
                          },

                          {
                            value: valFeat.maxValue,
                            label:
                              valFeat.typ == "unlimited"
                                ? "unlimited"
                                : valFeat.maxValue,
                          },
                        ]}
                        step={valFeat.resolution}
                        min={0}
                        max={valFeat.maxValue}
                      />
                    )) || (
                      <Grid
                        item
                        sx={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1%",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "50%",
                            borderRadius: "4px",
                            padding: "1%",
                            fontWeight: 600,
                            fontSize: "14px",
                            backgroundColor: valFeat.amount
                              ? "#A8A8A8"
                              : "white",
                            textAlign: "center",
                            color: valFeat.amount ? "white" : "#A8A8A8",
                            cursor: "pointer",
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            border: "1px solid #A8A8A8",
                          }}
                        >
                          Included
                        </Typography>
                        <Typography
                          sx={{
                            width: "50%",
                            borderRadius: "4px",
                            padding: "1%",
                            fontWeight: 600,
                            fontSize: "14px",
                            backgroundColor: !valFeat.amount
                              ? "#A8A8A8"
                              : "white",
                            textAlign: "center",
                            color: !valFeat.amount ? "white" : "#A8A8A8",
                            cursor: "pointer",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            border: "1px solid #A8A8A8",
                          }}
                        >
                          Not Included
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1%",
            }}
          >
            <Button
              onClick={showOffer}
              variant="contained"
              sx={{
                backgroundColor: "#0B5CFF",
                marginLeft: "auto",
                textTransform: "none",
                width: "15%",
                marginBottom: "10%",
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreferencesPage;
