/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  Checkbox,
  Button,
  Container,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import MessageBanner from "../../components/messageBanner";
import queryString from "query-string";
import {
  subscriptionPlanSettings,
  getSubscriptionPlanSettings,
} from "../../api/subscription.plans";
import DataStorage from "../../storage";

function SubscriptionPlanSettings() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [formData, setFormData] = useState({
    company: "",
    noMonthly: false,
    D1: 0.0,
    D2: 0.0,
    D3: 0.0,
    P1: 0.0,
    P2: 0.0,
    P3: 0.0,
    P4: 0.0,
    P5: 0.0,
    Dmin: 0.0,
    defaultCurrency: "USD",
    freePlanAvailable: true,
    locationized: true,
  });
  var cc = require("currency-codes");

  const currencyOptions = cc.codes().map((d) => ({
    currency: d,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      getSubscriptionPlanSettings(DataStorage.get("token"))
        .then((resp) => {
          const responseData = resp.data;

          setFormData((prevFormData) => ({
            ...prevFormData,
            company: responseData.company,
            noMonthly: responseData.noMonthly,
            D1: responseData.D1,
            D2: responseData.D2,
            D3: responseData.D3,
            P1: responseData.P1,
            P2: responseData.P2,
            P3: responseData.P3,
            P4: responseData.P4,
            P5: responseData.P5,
            R1: responseData.R1,
            R2: responseData.R2,
            R3: responseData.R3,
            Dmin: responseData.Dmin,
            defaultCurrency: responseData.defaultCurrency,
            freePlanAvailable: responseData.freePlanAvailable,
            locationized: responseData.locationized,
          }));
        })
        .catch(() => setMessage("No settings Available."));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    subscriptionPlanSettings(DataStorage.get("token"), formData)
      .then((resp) => {
        const message = "Subscription plan settings added successfully.";
        navigate("/subscription-plans?" + queryString.stringify({ message }));
      })
      .catch(() => setMessage("An error occurred."));
  };

  return (
    <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
      <h1>Set Subscription Plans Settings </h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label>Free Plan Available</label>
              <Checkbox
                name="freePlanAvailable"
                checked={formData.freePlanAvailable}
                onChange={handleChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label>No Monthly Subscription</label>
              <Checkbox
                name="noMonthly"
                checked={formData.noMonthly}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label>Locationized</label>
              <Checkbox
                name="locationized"
                checked={formData.locationized}
                onChange={handleChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Discount Annual subscription versus Monthly subscription"
              type="number"
              name="D1"
              value={formData.D1}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Discount 2 years subscription versus Annual subscription:"
              type="number"
              name="D2"
              value={formData.D2}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Discount 3 years subscription versus Annual subscription:"
              type="number"
              name="D3"
              value={formData.D3}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Premium over annual billing for annual subscription billed monthly:"
              type="number"
              name="P1"
              value={formData.P1}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Premium over 2 years billing for 2 years subscription billed monthly:"
              type="number"
              name="P2"
              value={formData.P2}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Premium over 2 years billing for 2 years subscription billed annually:"
              type="number"
              name="P3"
              value={formData.P3}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Premium over 3 years billing for 3 years subscription billed monthly:"
              type="number"
              name="P4"
              value={formData.P4}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Premium over 3 years billing for 3 years subscription billed annually:"
              type="number"
              name="P5"
              value={formData.P5}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Reservation percentage for new paid Bootstrapper+"
              type="number"
              name="R1"
              value={formData.R1}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Reservation percentage for new paid x1"
              type="number"
              name="R2"
              value={formData.R2}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Reservation percentage for renewals of x1 x2 x3"
              type="number"
              name="R3"
              value={formData.R3}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Min Price discount to full price:"
              type="number"
              name="Dmin"
              value={formData.Dmin}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Select a currency</InputLabel>
              <Select
                label="Select a currency"
                name="defaultCurrency"
                value={formData.defaultCurrency}
                onChange={handleChange}
              >
                {currencyOptions.map((currency, index) => (
                  <MenuItem key={index} value={currency.currency}>
                    {currency.currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ gridColumn: "1 / -1" }}
        >
          Save
        </Button>
      </form>
      <MessageBanner message={message} setMessage={setMessage} />
    </Container>
  );
}

export default SubscriptionPlanSettings;
