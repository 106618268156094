/** @format */

// components/Signup.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { signup } from "../../api/authentication";
import { googleLogin } from "../../api/authentication";
import queryString from "query-string";
import MessageBanner from "../../components/messageBanner";
import google_logo from "../../assets/image/Google_G_logo.png";
import "./signUpStyles.css";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import DataStorage from "../../storage";

import {
  Button,
  Typography,
  Checkbox,
  TextField,
  Chip,
  Grid,
  Divider,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
function passwordCheck(password, password1) {
  if (password !== password1) return "Passwords are mismatch";
  if (password.length < 8) return "Password should be at least 8 chars";
  return null;
}

function SignupPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [message, setMessage] = useState(message_ || "");
  const [isChecked, setIsChecked] = useState(false);
  const [isGoogleLoginDisabled, setGoogleLoginDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleGoogleLogin = (data) => {
    if (!data.email_verified) {
      setMessage("Email verification required");
    } else {
      DataStorage.set("company", data);
      DataStorage.set("token", data.token);
      navigate("/dashboard");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "146501087666-mkhfkrvurnlaa98uremrk3p2kvorb6rb.apps.googleusercontent.com",
        scope: "email",
      })
      .then(()=>setGoogleLoginDisabled(false))
      .catch(()=>console.log("error"));
    }
    gapi.load("client:auth2", start);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (DataStorage.get("token")) {
      navigate("/dashboard");
    }
  }, []);

  const handleSignup = (e) => {
    e.preventDefault();

    signup(companyName, email, password)
      .then((resp) => {
        console.log(resp);
        const message = "Email verification required";
        navigate("/login?" + queryString.stringify({ message, email }));
      })
      .catch((error) => {
        setMessage("An error occured");
      });
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#171717",
      }}
    >
      <Grid
        item
        md={3}
        lg={3}
        xl={3}
        sx={{
          height: "80vh",
          backgroundColor: "#2E2E2E",
          borderRadius: "12px",
          padding: "2em",
        }}
      >
        <Grid
          item
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
          }}
        >
          <Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="115"
              height="20"
              viewBox="0 0 115 20"
              fill="none"
            >
              <path
                d="M17.7188 17.1545C15.7057 19.0515 13.2559 20 10.3692 20C7.48254 20 5.03267 19.0515 3.0196 17.1545C1.00653 15.2575 0 12.9178 0 10.1355C0 7.35321 1.00653 5.01355 3.0196 3.11653C5.03267 1.21951 7.48254 0.271002 10.3692 0.271002C13.2559 0.271002 15.7057 1.21951 17.7188 3.11653C19.7319 5.01355 20.7384 7.35321 20.7384 10.1355C20.7384 12.9178 19.7319 15.2575 17.7188 17.1545ZM6.21013 14.2005C7.33061 15.2484 8.71697 15.7724 10.3692 15.7724C12.0214 15.7724 13.3983 15.2484 14.4998 14.2005C15.6203 13.1527 16.1805 11.7977 16.1805 10.1355C16.1805 8.47335 15.6203 7.11834 14.4998 6.07046C13.3983 5.02258 12.0214 4.49864 10.3692 4.49864C8.71697 4.49864 7.33061 5.02258 6.21013 6.07046C5.10864 7.11834 4.55789 8.47335 4.55789 10.1355C4.55789 11.7977 5.10864 13.1527 6.21013 14.2005Z"
                fill="white"
              />
              <path
                d="M30.9961 4.17344C29.2299 4.02891 28.3468 4.66125 28.3468 6.07046H30.9961V9.9729H28.3468V19.6206H24.0738V9.9729H22.1652V6.07046H24.0738C24.0738 4.11924 24.6531 2.63776 25.8115 1.62602C26.97 0.596206 28.6982 0.144535 30.9961 0.271002V4.17344Z"
                fill="white"
              />
              <path
                d="M40.5381 4.17344C38.7719 4.02891 37.8888 4.66125 37.8888 6.07046H40.5381V9.9729H37.8888V19.6206H33.6158V9.9729H31.7072V6.07046H33.6158C33.6158 4.11924 34.195 2.63776 35.3535 1.62602C36.512 0.596206 38.2402 0.144535 40.5381 0.271002V4.17344Z"
                fill="white"
              />
              <path
                d="M45.507 14.4715C46.0008 15.7362 47.1403 16.3686 48.9255 16.3686C50.0839 16.3686 50.9955 16.0253 51.6602 15.3388L55.0786 17.2087C53.6733 19.0696 51.6032 20 48.8685 20C46.4756 20 44.5575 19.3225 43.1141 17.9675C41.6898 16.6125 40.9776 14.9051 40.9776 12.8455C40.9776 10.804 41.6803 9.10569 43.0857 7.75068C44.51 6.3776 46.3332 5.69106 48.5551 5.69106C50.6252 5.69106 52.3439 6.3776 53.7113 7.75068C55.0976 9.10569 55.7908 10.804 55.7908 12.8455C55.7908 13.4237 55.7338 13.9657 55.6199 14.4715H45.507ZM45.4216 11.4363H51.5463C51.1284 10.009 50.1219 9.29539 48.5266 9.29539C46.8744 9.29539 45.8394 10.009 45.4216 11.4363Z"
                fill="white"
              />
              <path
                d="M62.4874 8.48239C62.7533 7.61518 63.285 6.95574 64.0827 6.50407C64.8993 6.03433 65.7919 5.79946 66.7604 5.79946V10.4065C65.7159 10.2439 64.7379 10.4155 63.8263 10.9214C62.9337 11.4092 62.4874 12.2674 62.4874 13.4959V19.6206H58.2144V6.07046H62.4874V8.48239Z"
                fill="white"
              />
              <path
                d="M72.9739 19.6206L67.476 0.650406H72.2617L75.7086 13.9295L79.5544 0.650406H83.2577L87.1034 13.9295L90.5503 0.650406H95.3361L89.8381 19.6206H84.6535L81.406 8.42818L78.1585 19.6206H72.9739Z"
                fill="white"
              />
              <path
                d="M100.564 4.17344C100.07 4.64318 99.4719 4.87805 98.7692 4.87805C98.0665 4.87805 97.4588 4.64318 96.946 4.17344C96.4523 3.68564 96.2054 3.1075 96.2054 2.43903C96.2054 1.77055 96.4523 1.20145 96.946 0.731708C97.4588 0.243903 98.0665 0 98.7692 0C99.4719 0 100.07 0.243903 100.564 0.731708C101.077 1.20145 101.333 1.77055 101.333 2.43903C101.333 3.1075 101.077 3.68564 100.564 4.17344ZM96.6327 19.6206V6.07046H100.906V19.6206H96.6327Z"
                fill="white"
              />
              <path
                d="M109.331 15.8266H115V19.6206H103.605V16.9106L108.989 9.8645H103.89V6.07046H114.715V8.78049L109.331 15.8266Z"
                fill="white"
              />
            </svg>
          </Typography>
          <Typography
            sx={{ color: "white", fontSize: "24px", fontWeight: "600" }}
          >
            Sign Up
          </Typography>
          <Typography
            sx={{ color: "#898989", fontSize: "16px", fontWeight: "400" }}
          >
            Enter your credentials to continue
          </Typography>
          { !isGoogleLoginDisabled &&
            <GoogleLogin
              clientId="146501087666-mkhfkrvurnlaa98uremrk3p2kvorb6rb.apps.googleusercontent.com"
              onSuccess={(resp) => {
                googleLogin(resp.accessToken)
                  .then((resp) => handleGoogleLogin(resp.data))
                  .catch(() => setMessage("An error occured."));
              }}
              onFailure={(resp) => {
                setMessage("An error occured.");
                console.log(resp);
              }}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "black",
                    width: "100%",
                    backgroundColor: "#F8FAFC",
                    borderColor: "#D0D5DD",
                  }}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<img src={google_logo} alt="Google g logo" />}
                >
                  Sign Up With Google
                </Button>
              )}
            />
          }
            <Divider
            sx={{
              width: "100%",
              backgroundColor: "#808080",
              height: "1px",
              alignItems: "center",
              display: "flex",
              zIndex: 0,
              marginTop:"5%",
              marginBottom:"5%"
            }}
          >
            <Chip
              label="OR"
              sx={{
                border: "1px solid #808080",
                borderRadius: "12px",
                width: "98px",
                color: "white",
                zIndex: 1,
                backgroundColor: "#2E2E2E",
              }}
            />
          </Divider>
          <Typography
            sx={{ color: "white", fontSize: "14px", fontWeight: "500" }}
          >
            Sign up with Email address
          </Typography>
          <TextField
            label="Email adress"
            type="email"
            id="email"
            name="email"
            variant="outlined"
            style={{
              borderRadius: "5px",
              width: "100%",
              borderWidth: "1px",
              borderColor: "#BFC0C2",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputBase-root": {
                color: "white",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiSelect-icon": {
                fill: "rgba(128, 128, 128, 1)",
              },
            }}
            InputProps={{
              sx: {
                color: "#000000DE",
                fontWeight: "400",
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
              },
            }}
            InputLabelProps={{
              sx: { color: "black", "&.Mui-focused": { color: "#00000099" } },
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            style={{
              borderRadius: "5px",
              width: "100%",
              borderWidth: "1px",
              borderColor: "#BFC0C2",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputBase-root": {
                color: "white",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiSelect-icon": {
                fill: "rgba(128, 128, 128, 1)",
              },
            }}
            InputProps={{
              sx: {
                color: "#000000DE",
                fontWeight: "400",
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
              },
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOff sx={{ color: "#808080" }} />
                  ) : (
                    <Visibility sx={{ color: "#808080" }} />
                  )}
                </IconButton>
              ),
            }}
            InputLabelProps={{
              sx: { color: "black", "&.Mui-focused": { color: "#00000099" } },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="b2b"
                name="b2b"
                sx={{color:"#808080"}}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            }
            label="Are you a B2B company?"
            sx={{ fontSize: "14px", fontWeight: "400", color: "white" }}
          />
          <TextField
            label="What's the number of your active customers/month?"
            variant="outlined"
            type="number"
            name="actCustNum"
            id="actCustNum"
            style={{
              borderRadius: "5px",
              width: "100%",
              borderWidth: "1px",
              borderColor: "#BFC0C2",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputBase-root": {
                color: "white",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiSelect-icon": {
                fill: "rgba(128, 128, 128, 1)",
              },
            }}
            InputProps={{
              sx: {
                color: "#000000DE",
                fontWeight: "400",
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "black",
                  },
              },
            }}
            InputLabelProps={{
              sx: { color: "black", "&.Mui-focused": { color: "#00000099" } },
            }}
          />
          <Button
            onClick={handleSignup}
            sx={{
              textTransform: "none",
              backgroundColor: "#B9D2FF",
              fontSize: "14px",
              fontWeight: 500,
              width: "100%",
              borderRadius: "4px",
              color:"black"
            }}
            size="large"
            variant="contained"
          >
            Sign Up
          </Button>
          <Divider sx={{width:"100%",backgroundColor:"#808080"}} />
          <Button
            onClick={() => navigate("/login")}
            sx={{
              color: "#B9D2FF",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "none",
      
            }}
          >
            Already have an account?
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignupPage;
