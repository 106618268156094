/** @format */

import React, { useState } from "react";
import { Grid, Typography, TextField, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {string} props.text
 * @param {string} props.date - date
 * @param {string} props.selectIcon "tick", "file","close"
 */

const InputCardComponent = ({ value, text, date }) => {
  const [original, setOriginal] = useState("choose");
  const [New, setNew] = useState();
  const [term, setTerm] = useState();
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        padding: "2%",
        minHeight: "25vh",
        width: "100%",
      }}
    >
      <Grid item xs={12} sx={{ flexDirection: "row", display: "flex" }}>
        <Grid item xs={12} lg={1}>
          <Typography
            sx={{
              borderRadius: "8px",
              height: "48px",
              width: "48px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#1F1F1F",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M21 19V17C21 15.1362 19.7252 13.5701 18 13.126M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5C17 6.67869 15.9659 8.11585 14.5 8.70924M16 19C16 17.1362 16 16.2044 15.6955 15.4693C15.2895 14.4892 14.5108 13.7105 13.5307 13.3045C12.7956 13 11.8638 13 10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Typography
            sx={{ fontSize: "34px", fontWeight: 600, color: "white" }}
          >
            {value}{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{ alignItems: "center", display: "flex", color: "#808080" }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
            {" "}
            {text}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2} sx={{ marginLeft: "auto" }}>
          <Typography
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "#1F1F1F",
              color: "white",
              fontSize: "14px",
              fontWeight: 500,
              verticalAlign: "center",
              textAlign: "center",
              padding: "4px",
            }}
          >
            {date}{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} sx={{ marginTop: "6%" }}>
        <TextField
          select
          required
          value={original}
          label="Original Subscription Plan"
          sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              color: "#808080",
            },
            width: "95%",
          }}
          onChange={(e) => setOriginal(e.target.value)}
        >
          <MenuItem disabled value="choose">
            Choose plan
          </MenuItem>
          <MenuItem value="plan1">Plan 1</MenuItem>
          <MenuItem value="plan2">Plan 2</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} lg={4} sx={{ marginTop: "6%" }}>
        <TextField
          select
          required
          value={New}
          label="New Subscription Plan"
                    sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              color: "#808080",
            },
            width: "95%",
          }}
          onChange={(e) => setNew(e.target.value)}
        >
          <MenuItem disabled value="choose">
            Choose plan
          </MenuItem>
          <MenuItem value="plan1">Plan 1</MenuItem>
          <MenuItem value="plan2">Plan 2</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} lg={4} sx={{ marginTop: "6%" }}>
        <TextField
          select
          required
          value={term}
          label=" Subscription Term"
                    sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              color: "#808080",
            },
            width: "95%",
          }}
          onChange={(e) => setTerm(e.target.value)}
        >
          <MenuItem disabled value="choose">
            Choose term
          </MenuItem>
          <MenuItem value="plan1">Term 1</MenuItem>
          <MenuItem value="plan2">Term 2</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default InputCardComponent;
