/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Button, Container, Grid } from "@mui/material";
import MessageBanner from "./messageBanner";
import queryString from "query-string";
import { createSubscriptionPlan } from "../api/subscription.plans";
import DataStorage from "../storage";

function NewSubscriptionPlanPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");

  const [formData, setFormData] = useState({
    name: "",
    FullPrice: 0.0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.FullPrice <= 0) {
      setMessage("Full Price must be greater than 0.");
      return;
    } else {
      createSubscriptionPlan(DataStorage.get("token"), formData)
        .then((resp) => {
          const message = "Subscription plan added successfully.";
          navigate("/subscription-plans?" + queryString.stringify({ message }));
        })
        .catch(() => setMessage("An error occurred."));
    }
  };

  return (
    <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
      <h1>Create Subscription Plan</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plan Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Full Price:"
              type="number"
              name="FullPrice"
              value={formData.FullPrice}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ gridColumn: "1 / -1" }}
        >
          Create
        </Button>
      </form>
      <MessageBanner message={message} />
    </Container>
  );
}

export default NewSubscriptionPlanPage;
