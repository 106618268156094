/** @format */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  TextField,
  Grid,
  Stack,
  Popover,
  Button,
  Box,
  Divider,
  IconButton,
  Autocomplete,
  InputAdornment,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MessageBanner from "../../components/messageBanner";
import { listCustomers } from "../../api/customers";
import LoginsChart from "./LoginsChartComponent";
import FetureChart from "./FetureChartComponent";
import UsersChart from "./UsersChartComponent";
import SentimentChart from "./SentimentChartComponent";
import SupportComponent from "./SupportChartComponent";
import FinancialComponent from "./FinancialsComponent";
const CustomerHealth = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");
  const [customers, setCustomers] = useState([]);
  const [data, setdata] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selected, setSelected] = useState("day");
  const [selectedFeture, setSelectedFeture] = useState("day");
  const [selectedUsers, setSelectedUsers] = useState("act");

  const { id } = useParams();
  const [message, setMessage] = useState(message_ || "");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (id) {
      setdata(customers.filter((customer) => customer.id == id));
    }
    if (selectedCustomer) {
      const customer = customers.filter(
        (customer) => customer.businessName == selectedCustomer
      );
      const customerId = customer[0].id;
      navigate(`/customer-health/${customerId}`);
    }
  }, [selectedCustomer, id, customers]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popId = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      listCustomers(localStorage.getItem("token"))
        .then((resp) => {
          setCustomers(resp.data);
        })
        .catch(() => setMessage("An error occurred."));
    }
  }, []);
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#171717",
        alignContent: "flex-start",

      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          margin: "1%",
          marginBottom: "0px",
          backgroundColor: "#1F1F1F",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          minHeight: "100vh",
        }}
      >
        <Grid container spacing={1} xs={12}>
          <Grid
            item
            xs={10}
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
            sx={{ margin: "2%" }}
          >
            <Typography
           
              sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
            >
              Search Customer
            </Typography>
            <Autocomplete
              freeSolo
              id="search-bar"
              sx={{ width: "50%", marginLeft: "5%" }}
              options={customers.map((customer) => customer.businessName)}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search customer"
                  variant="outlined"
                  fullWidth
                  sx={{  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiInputBase-root": {
                    color: "#808080",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiSelect-icon": {
                    fill: "rgba(128, 128, 128, 1)",
                  },}}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{color:"#808080"}} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Divider
            sx={{ width: "100%", marginBottom: "2%",backgroundColor:"#808080",marginLeft:"0.5%" }}
          />
          {/*----------------------------------------------------Health Score------------------------------------*/}
          <Grid item xs={12} sx={{ margin: "1%" }}>
            <Grid
              container
              xs={12}
              sx={{
                marginBottom: "16px",
                backgroundColor:
                  data[0]?.customerHealth == 0.0 ? "#F2184E" : "#A3CFAA",
                borderRadius: "12px",
                position: "relative",
                height: "25vh",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  padding: 5,
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.85 11.6227H14.75L13.1 15.0189L10.9 8.22642L9.25 11.6227H8.15M11.9925 4.41791C9.79318 1.77177 6.12572 1.05997 3.37016 3.48304C0.614608 5.9061 0.226665 9.95734 2.39062 12.8231C4.0251 14.9877 8.66842 19.3332 10.8428 21.3301C11.2425 21.6972 11.4424 21.8807 11.6764 21.953C11.8796 22.0157 12.1054 22.0157 12.3085 21.953C12.5426 21.8807 12.7424 21.6972 13.1421 21.3301C15.3165 19.3332 19.9598 14.9877 21.5943 12.8231C23.7583 9.95734 23.4177 5.88061 20.6148 3.48304C17.8118 1.08546 14.1918 1.77177 11.9925 4.41791Z"
                      stroke="rgba(128, 128, 128, 1)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Typography>
                <Typography
                  sx={{ fontSize: "34px", fontWeight: 600, color: "white" }}
                >
                  Health
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    position: "absolute",
                    right: 50,
                    bottom: 15,
                    fonstSize: "14px",
                    fontWeight: 500,
                    backgroundColor: "white",
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#8E99AB",
                    },
                  }}
                  onClick={() => console.log("girdi")}
                >
                  Change Customer Health Score
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/*----------------------------------------------------First part graphics ------------------------------------*/}

          <Grid item xs={12} sx={{ justifyContent: "center", margin: "1%" }}>
            <Grid xs={12} container sx={{ justifyContent: "space-evenly" }}>
              <Grid item lg={3.5} xs={11} height={"100%"}>
                <Typography
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "30px",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Logins
                  <Box
                    sx={{ flexDirection: "row", display: "flex", gap: "5px" }}
                  >
                    <Button
                      onClick={() => setSelected("day")}
                      sx={{
                        color: selected == "day" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Day
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "#808080" }}
                    />
                    <Button
                      onClick={() => setSelected("week")}
                      sx={{
                        color: selected == "week" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Week
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "#808080" }}
                    />
                    <Button
                      onClick={() => setSelected("month")}
                      sx={{
                        color: selected == "month" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Month
                    </Button>
                  </Box>
                </Typography>

                <LoginsChart select={selected} />
              </Grid>
              <Grid item lg={3.5} xs={11} height={"100%"}>
                <Typography
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "30px",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Feture Adoption
                  <Box sx={{ flexDirection: "row", display: "flex" }}>
                    <Button
                      onClick={() => setSelectedFeture("day")}
                      sx={{
                        color: selectedFeture == "day" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Day
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "#808080" }}
                    />
                    <Button
                      onClick={() => setSelectedFeture("week")}
                      sx={{
                        color: selectedFeture == "week" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Week
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "#808080" }}
                    />
                    <Button
                      onClick={() => setSelectedFeture("month")}
                      sx={{
                        color:
                          selectedFeture == "month" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Month
                    </Button>
                  </Box>
                </Typography>

                <FetureChart select={selectedFeture} />
              </Grid>
              <Grid item lg={3.5} xs={11} height={"100%"}>
                <Typography
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "30px",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Users
                  <Box
                    sx={{ flexDirection: "row", display: "flex", gap: "5px" }}
                  >
                    <Button
                      onClick={() => setSelectedUsers("act")}
                      sx={{
                        color: selectedUsers == "act" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      Activity
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "#808080" }}
                    />
                    <Button
                      onClick={() => setSelectedUsers("user")}
                      sx={{
                        color: selectedUsers == "user" ? "#B9D2FF" : "#808080",
                        fontWeight: 600,
                      }}
                    >
                      USER Typye
                    </Button>
                  </Box>
                </Typography>

                <UsersChart />
              </Grid>
            </Grid>
          </Grid>
          {/*----------------------------------------------------second part graphics ------------------------------------*/}

          <Grid item xs={12} sx={{ justifyContent: "center", margin: "1%" }}>
            <Grid xs={12} container sx={{ justifyContent: "space-evenly" }}>
              <Grid item lg={3.5} xs={11} height={"100%"}>
                <Typography
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "30px",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Sentiment
                  <Button
                    sx={{
                      color: "#B9D2FF",
                      fontWeight: 600,
                      textTransform: "none",
                    }}
                  >
                    View Details
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 14.1668L11.6667 10.0002L7.5 5.8335"
                        stroke="#B9D2FF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </Typography>

                <SentimentChart select={selected} />
              </Grid>
              <Grid item lg={3.5} xs={11} height={"100%"}>
                <Typography
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "30px",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Technical support
                  <Button
                    sx={{
                      color: "#B9D2FF",
                      fontWeight: 600,
                      textTransform: "none",
                    }}
                  >
                    View Details
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 14.1668L11.6667 10.0002L7.5 5.8335"
                        stroke="#B9D2FF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </Typography>

                <SupportComponent />
              </Grid>
              <Grid item lg={3.5} xs={11} height={"100%"}>
                <Typography
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    fontSize: "30px",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Financials
                  <Button
                    sx={{
                      color: "#B9D2FF",
                      fontWeight: 600,
                      textTransform: "none",
                    }}
                  >
                    View Details
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.5 14.1668L11.6667 10.0002L7.5 5.8335"
                        stroke="#B9D2FF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </Typography>

                <FinancialComponent />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MessageBanner message={message} setMessage={setMessage} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerHealth;
