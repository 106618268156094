/** @format */

import React from "react";
import CustomersGroupsComponent from "./CustomersGroups";
import TargetGroupComponent from "./TargetGroupComponent";
const DefineTargetGroups = () => {
  return (
    <>
      <CustomersGroupsComponent />
      <TargetGroupComponent/>
    </>
  );
};

export default DefineTargetGroups;
