/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { listExperiments } from "../../api/experiment";
import {
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  IconButton,
  TextField,
  Grid,
  Divider,
  MenuItem,
  Autocomplete,
  Stack,
  Popover,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MessageBanner from "../../components/messageBanner";
import ExperimentComponent from "./Experiments/ExperimentsComponent";
import DefineTargetGroups from "./DefineTargetGroups/DefineTargetComponent";
import ExperimentSetUp from "./ExperimentsSetUp/ExperimentsSetUp";

function ExperimentsPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [experiments, setExperiments] = useState([]);
  const [select, setSelect] = useState(60);
  const [filteredExperiments, setFilteredExperiments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedObjective, setSelectedObjective] = useState("objective");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedType, setSelectedType] = useState("type");
  const [selectedPage, setSelectedPage] = useState("experiment");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [createExperiment, setCreateExperiment] = useState(false);

  const handleStatus = (value) => {
    const filtered =
      value == "all"
        ? experiments
        : experiments.filter((experiment) => experiment.status == value);

    setFilteredExperiments(filtered);
  };

  const dummyData = [
    {
      id: "3246",
      experimentName: "Experiment 1",
      targetGroup: "High Income, Negotiation 1",
      totalNumberOfCustomer: 250,
      status: "completed",
    },
    {
      id: "8743",
      experimentName: "Experiment 2",
      targetGroup: "Low Income, Negotiation 2",
      totalNumberOfCustomer: 180,
      status: "completed",
    },
    {
      id: "2351",
      experimentName: "Experiment 3",
      targetGroup: "Medium Income, Negotiation 3",
      totalNumberOfCustomer: 320,
      status: "ongoing",
    },
    {
      id: "1298",
      experimentName: "Experiment 4",
      targetGroup: "High Income, Negotiation 4",
      totalNumberOfCustomer: 290,
      status: "completed",
    },
    {
      id: "7650",
      experimentName: "Experiment 5",
      targetGroup: "Low Income, Negotiation 5",
      totalNumberOfCustomer: 150,
      status: "ongoing",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSearch = (e) => {
    const searchValue = e.toLowerCase();
    setFilteredExperiments(
      experiments.filter((item) => {
        return (
          item.experimentName.toLowerCase().includes(searchValue) ||
          item.id.includes(searchValue) ||
          searchValue === ""
        );
      })
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      /* listExperiments(localStorage.getItem("token"))
      .then(resp=>setFilteredExperiments(resp.data).sort((a, b)=>new Date(a.created_at) - new Date(b.created_at)))
      .catch(()=>setMessage('An error occurred.'));*/
      setFilteredExperiments(dummyData);
      setExperiments(dummyData);
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        backgroundColor: "#171717",
        alignContent: "flex-start",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1%", paddingBottom: "0" }}>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: "0px",
            backgroundColor: "#1F1F1F",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
            minHeight: "100vh",
          }}
        >
          <Grid container spacing={1}>
            {!createExperiment ? (
              <Grid item xs={12}>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    padding: "2%",
                  }}
                >
              <Grid
                item
                lg={6}
                xs={12}
                sx={{ flexDirection: "row", display: "flex" }}
              >
         <Typography
                  sx={{
                    flex: 1,
                    backgroundColor:
                      selectedPage === "experiment" ? "#B9D2FF" : null,
                    fontWeight: selectedPage === "experiment" ? 600 : 500,
                    borderRadius: "4px",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    border: " 1px solid #B9D2FF",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: selectedPage === "experiment" ? "black" : "white",
                    textTransform: "none",
                    padding: "1%",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => setSelectedPage("experiment")}
                >
                  Experiments
                </Typography>

                <Typography
                  sx={{
                    flex: 1,
                    backgroundColor:
                      selectedPage === "target" ? "#B9D2FF" : null,
                    fontWeight: selectedPage === "target" ? 600 : 500,
                    borderRadius: "4px",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    border: " 1px solid #B9D2FF",
                    borderLeft: "0",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: selectedPage === "target" ? "black" : "white",
                    textTransform: "none",
                    padding: "1%",
                    boxShadow: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => setSelectedPage("target")}
                >
                  Define Target Groups
                </Typography>

            
              </Grid>
                  {selectedPage == "experiment" && !createExperiment ? (
                    <Button
                      variant="contained"
                      onClick={() => setCreateExperiment(true)}
                      sx={{
                        backgroundColor: "#B9D2FF",
                        color: "black",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        marginLeft: "auto",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{ marginRight: "1%" }}
                      >
                        <g clip-path="url(#clip0_710_70851)">
                          <path
                            d="M9.99935 6.66797V13.3346M6.66602 10.0013H13.3327M18.3327 10.0013C18.3327 14.6037 14.6017 18.3346 9.99935 18.3346C5.39698 18.3346 1.66602 14.6037 1.66602 10.0013C1.66602 5.39893 5.39698 1.66797 9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013Z"
                            stroke="#171717"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_710_70851">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Create New
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ padding: "2%", display: "flex" }}>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: 600, color: "white" }}
                >
                  Set up Pricing Experiments
                </Typography>
              </Grid>
            )}
            <Divider
              sx={{
                width: "99.5%",
                backgroundColor: "#808080",
                marginLeft: "auto",
              }}
            />

            {!createExperiment ? (
              selectedPage == "experiment" ? (
                <ExperimentComponent
                  filteredExperiments={filteredExperiments}
                  setFilteredExperiments={setFilteredExperiments}
                  handleStatus={handleStatus}
                  setSelectedObjective={setSelectedObjective}
                  setSelectedStatus={setSelectedStatus}
                  setSelectedType={setSelectedType}
                  experiments={experiments}
                  selectedType={selectedType}
                  selectedStatus={selectedStatus}
                  selectedObjective={selectedObjective}
                />
              ) : selectedPage == "target" ? (
                <DefineTargetGroups />
              ) : null
            ) : (
              <ExperimentSetUp setCreateExperiment={setCreateExperiment} />
            )}
            <Grid item xs={12}>
              <MessageBanner message={message} setMessage={setMessage} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExperimentsPage;
