/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  MenuItem,
  Divider,
  Typography,
  Stack,
  Popover,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Chip,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "../../components/InstrunctionsModalComponent/InstrunctionsModalComponent";
import MessageBanner from "../../components/messageBanner";
import DataStorage from "../../storage";
import ValueFeatures from "./ValueFeatures";
import { listValueFeatures } from "../../api/value.feature";
import {
  listSubscriptionPlans,
  saveSubscriptionPlan,
  deleteSubscriptionPlan,
  createSubscriptionPlan,
  subscriptionPlanSettings,
  getSubscriptionPlanSettings,
} from "../../api/subscription.plans";
import { getSubPlanValFeat, saveSubPlanValFeat } from "../../api/value.feature";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function SubscriptionPackageBuilderPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [isEditable, setIsEditable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [valFeats, setValFeats] = useState([]);
  const [settings, setSettings] = useState(null);
  const [subPlans, setSubPlans] = useState([]);
  const [subPlanValFeat, setSubPlanValFeat] = useState({});
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      // Fetch data from APIs
      Promise.all([
        listValueFeatures(DataStorage.get("token")),
        listSubscriptionPlans(DataStorage.get("token")),
        getSubPlanValFeat(DataStorage.get("token")),
        getSubscriptionPlanSettings(DataStorage.get("token")),
      ])
        .then((responses) => {
          const valFeatsResp = responses[0].data;
          const subPlansResp = responses[1].data;
          const subPlanValFeatResp = responses[2].data;
          const settings = responses[3].data;

          setValFeats(valFeatsResp);
          setSubPlans(subPlansResp);
          setSubPlanValFeat(subPlanValFeatResp);
          setSettings(settings);

          // Data has been loaded, set isLoading to false
          setIsLoading(false);
        })
        .catch(() => setMessage("An error occurred."));
    }
  }, []);

  useEffect(() => {
    handleData();
  }, [subPlanValFeat, valFeats, subPlans]);

  const handleData = () => {
    const updatedData = valFeats?.map((item) => {
      const subPlanData = subPlans?.map((plan) => {
        const subPlanId = plan.id.toString();
        if (subPlanValFeat[subPlanId] && subPlanValFeat[subPlanId][item.id]) {
          return {
            name: plan.name,
            id: plan.id,
            amount: subPlanValFeat[subPlanId][item.id].amount,
          };
        }
        return {
          name: plan.name,
          id: plan.id,
          amount: 0,
        };
      });

      return {
        unit: item.unit,
        description: item.description,
        id: item.id,
        subPlan: subPlanData,
      };
    });

    setData(updatedData);
    console.log(updatedData);
  };

  const handleChange = (index, subPlanIndex, newValue) => {
    const updatedData = [...data];
    const parsedValue = newValue === "" ? 0 : Math.round(parseFloat(newValue));

    updatedData[index].subPlan[subPlanIndex].amount = parsedValue;
    setData(updatedData);
  };

  const handleSave = () => {
    const updatedSubPlanValFeat = { ...subPlanValFeat };

    data.forEach((item) => {
      item.subPlan.forEach((subPlanData) => {
        const subPlanId = subPlanData.id.toString();
        const valFeatId = item.id;
        if (!updatedSubPlanValFeat[subPlanId]) {
          updatedSubPlanValFeat[subPlanId] = {};
        }

        updatedSubPlanValFeat[subPlanId][valFeatId] = {
          amount: subPlanData.amount,
        };
      });
    });

    saveSubPlanValFeat(DataStorage.get("token"), updatedSubPlanValFeat)
      .then((resp) => {
        setSubPlanValFeat(resp.data);
      })
      .catch(() => setMessage("An error occurred."));
    console.log(updatedSubPlanValFeat);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteSelected = () => {
    Promise.all(
      selectedItems.map((plan) =>
        deleteSubscriptionPlan(DataStorage.get("token"), plan)
      )
    )
      .then(() => {
        return listSubscriptionPlans(DataStorage.get("token"))
          .then((resp) => {
            setSubPlans(resp.data);
          })
          .catch(() => setMessage("An error occurred."));
      })
      .catch(() => setMessage("An error occurred."));
  };

  const handleAddPlan = () => {
    const data = { FullPrice: 0, name: "New SubPlan" };
    createSubscriptionPlan(DataStorage.get("token"), data).then(() =>
      listSubscriptionPlans(DataStorage.get("token"))
        .then((resp) => {
          setSubPlans(resp.data);
        })
        .catch(() => setMessage("An error occurred."))
    );
  };

  const handleItemToggle = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const handleSubPlanChange = (id, e) => {
    const updatedPlans = [...subPlans];

    updatedPlans.forEach((item) => {
      console.log(item[e.target.name]);
      if (item.id === id) {
        item[e.target.name] = e.target.value;
      }
    });
    setSubPlans(updatedPlans);
  };
  const handleSaveSubPlan = () => {
    console.log("Before", subPlans);
    subPlans.forEach((plan) =>
      saveSubscriptionPlan(DataStorage.get("token"), plan.id, plan)
        .then((resp) => {
          console.log("resp.data", resp.data);
        })
        .catch(() => setMessage("An error occurred."))
    );
    setIsEditable(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = () => {
    setIsSubmit(!isSubmit);
    console.log(!isSubmit);
    subscriptionPlanSettings(DataStorage.get("token"), settings);
  };

  const handleChangeSettings = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#171717",
        alignContent: "flex-start",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ padding: "1%", paddingBottom: "0" }}>
        <Grid
          item
          xs={12}
          sx={{
            paddingBottom: "0px",
            backgroundColor: "#1F1F1F",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
            minHeight: "100vh",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                xs={12}
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent:"space-between",
                  padding: "2%",
                }}
              >
                <Grid item lg={3} xs={12}>
                  <Typography
                    sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
                  >
                    Subscription Package Builder
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={2}
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#B9D2FF",
                      textDecoration: "underline ",
                      "&.MuiTypography-root": {
                        userSelect: "none",
                      },
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    Instructions & help
                  </Typography>
                </Grid>
                {isSubmit ? (
                  <>
                    <Grid item sx={"auto"}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#B9D2FF",
                          textTransform: "none",
                          color: "black",
                          fontWeight: 500,

                          "&:hover": {
                            backgroundColor: "#8E99AB",
                          },
                        }}
                        onClick={() => handleAddPlan()}
                      >
                        Add New Plan
                      </Button>
                    </Grid>
                    <Grid item sx={"auto"}>
                      <Button
                        onClick={() => {
                          isEditable
                            ? handleSaveSubPlan()
                            : setIsEditable(true);
                        }}
                        variant="standard"
                        sx={{
                          textTransform: "none",
                          color: "#B9D2FF",

                          "&:hover": {
                            backgroundColor: "#8E99AB",
                          },
                        }}
                      >
                        {isEditable ? "Save" : "Edit"}
                      </Button>
                    </Grid>
                    <Grid item  sx={"auto"}>
                      <Button
                        onClick={() => handleDeleteSelected()}
                        variant="standard"
                        disabled={selectedItems.length === 0}
                        sx={{
                          textTransform: "none",
                          color: "#B9D2FF",
                          "&:disabled": {
                            color: "#808080",
                          },
                          "&:hover": {
                            backgroundColor: "#8E99AB",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item  sx={"auto"}>
                      <Button
                        onClick={() => handleSave(data)}
                        variant="standard"
                        sx={{
                          textTransform: "none",
                          color: "#B9D2FF",
                          "&:hover": {
                            backgroundColor: "#8E99AB",
                          },
                        }}
                      >
                        Save Amounts
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>

            <Divider
              sx={{
                width: "100%",
                backgroundColor: "#808080",
              }}
            />
            {isSubmit ? (
              <Grid item xs={12} sx={{ padding: "1%" }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    color: "white",
                    marginBottom: "2%",
                  }}
                >
                  <Chip
                    onClick={() => handleSubmit()}
                    sx={{
                      width: "3em", // Örneğin, 2em genişlik
                      height: "3em",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#808080",
                      marginRight: "1%",
                      paddingLeft: "0.8%",
                      "& .MuiChip-icon": {
                        color: "white",
                      },
                    }}
                    icon={<ArrowBackIcon />}
                  />
                  Back to Questionnaire
                </Typography>
                <ValueFeatures
                  data={data}
                  handleChange={handleChange}
                  handleItemToggle={handleItemToggle}
                  selectedItems={selectedItems}
                  subPlans={subPlans}
                  handleSubPlanChange={handleSubPlanChange}
                  isEditable={isEditable}
                  isLoading={isLoading}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={11}
                lg={5}
                sx={{ margin: "auto", marginTop: "5%" }}
              >
                <Grid
                  container
                  sx={{
                    backgroundColor: "#2E2E2E",
                    borderRadius: "12px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    padding: "25px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      gap: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "5%",
                        marginTop: "5%",
                        color: "white",
                      }}
                    >
                      Questionnaire
                    </Typography>
                    <TextField
                      margin="normal"
                      select
                      value={settings ? settings?.freePlanAvailable : ""}
                      name="freePlanAvailable"
                      onChange={(e) => handleChangeSettings(e)}
                      label="Do you offer a free plan?"
                      fullWidth
                      sx={{
                        backgroundColor: "#2E2E2E",
                        "& .MuiInputLabel-root": {
                          color: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiInputBase-root": {
                          color: "#808080",
                        },
                        "& .MuiSelect-icon": {
                          fill: "rgba(128, 128, 128, 1)",
                        },
                      }}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </TextField>
                    <TextField
                      margin="normal"
                      select
                      label="Do you offer a Trial Period for these plans?"
                      fullWidth
                      sx={{
                        backgroundColor: "#2E2E2E",
                        "& .MuiInputLabel-root": {
                          color: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiInputBase-root": {
                          color: "#808080",
                        },
                        "& .MuiSelect-icon": {
                          fill: "rgba(128, 128, 128, 1)",
                        },
                      }}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </TextField>

                    <TextField
                      margin="normal"
                      select
                      label="Do you offer a Monthly Subscription for these plans ?"
                      fullWidth
                      value={settings ? settings?.noMonthly : ""}
                      name="noMonthly"
                      onChange={(e) => handleChangeSettings(e)}
                      sx={{
                        backgroundColor: "#2E2E2E",
                        "& .MuiInputLabel-root": {
                          color: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiInputBase-root": {
                          color: "#808080",
                        },
                        "& .MuiSelect-icon": {
                          fill: "rgba(128, 128, 128, 1)",
                        },
                      }}
                    >
                      <MenuItem value="false">Yes</MenuItem>
                      <MenuItem value="true">No</MenuItem>
                    </TextField>
                    <Button
                      onClick={() => handleSubmit()}
                      variant="contained"
                      sx={{
                        backgroundColor: "#B9D2FF",
                        textTransform: "none",
                        color: "black",
                        width: "100%",

                        "&:hover": {
                          backgroundColor: "#8E99AB",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <MessageBanner message={message} setMessage={setMessage} />
            </Grid>
            <Modal open={openModal} handleClose={handleCloseModal} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SubscriptionPackageBuilderPage;
