class DataStorage {
  static checkCookiesEnabled() {
    try {
      localStorage.setItem('testCookie', 'testValue', { expires: 1 });
      const cookieValue = localStorage.getItem('testCookie');
      localStorage.removeItem('testCookie');
      return cookieValue === 'testValue';
    } catch (error) {
      return false;
    }
  }

  static checkThirdPartyCookies() {
    try {
      // Set a third-party cookie
      document.cookie = 'test_cookie=true; samesite=None; secure=true';
  
      // Attempt to read the third-party cookie
      const cookieValue = document.cookie.indexOf('test_cookie=true') !== -1;
  
      // Clean up by removing the test cookie
      document.cookie = 'test_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; samesite=None; secure=true';
  
      if (cookieValue) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  static get(key) {
    if (DataStorage.checkCookiesEnabled()) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }

  static set(key, value, options = {}) {
    if (DataStorage.checkCookiesEnabled()) {
        localStorage.setItem(key, value, options);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  static remove(key) {
    if (DataStorage.checkCookiesEnabled()) {
        localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
}

export default DataStorage;
