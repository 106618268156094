/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement, // Bar grafiği için BarElement eklenmiştir
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2"; // Bar grafiği için Bar import edilmiştir
import { faker } from "@faker-js/faker";
import { Grid, Typography } from "@mui/material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement, // Bar grafiği için BarElement eklenmiştir
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
    },
    y: {
      grid: {
        color: "#2E2E2E52",
        lineWidth: 3,
      },
    },
  },
};

const generateLabels = (select) => {
  switch (select) {
    case "month":
      return [
        "Live Feed",
        "NPS Results",
        "Acc. Details",
        "Users Online",
        "Named Features",
      ];
    case "day":
      return [
        "Live Feed",
        "NPS Results",
        "Acc. Details",
        "Users Online",
        "Named Features",
      ];
    case "week":
      return [
        "Live Feed",
        "NPS Results",
        "Acc. Details",
        "Users Online",
        "Named Features",
      ];
    default:
      return [];
  }
};

const generateRandomColors = (count) => {
  // Belirtilen sayıda rastgele renk oluşturan bir işlev
  const randomColors = [];
  for (let i = 0; i < count; i++) {
    const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    randomColors.push(color);
  }
  return randomColors;
};

export function FetureChart({ select }) {
  const [labels, setLabels] = useState(generateLabels(select));

  useEffect(() => {
    setLabels(generateLabels(select));
  }, [select]);

  const datasetCount = labels.length; // Veri seti sayısı

  // Rastgele arka plan renklerini oluştur
  const backgroundColors = generateRandomColors(datasetCount);

  const data = {
    aspectRatio: 1,
    responsive: true,
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: backgroundColors, // Rastgele renkler
        borderWidth: 0,
      },
    ],
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        height: "470px",
        alignItems: "center",
      }}
    >
      <Grid item xs={12}>
        <Bar data={data} options={options} />
      </Grid>
    </Grid>
  );
}


export default FetureChart;
