import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MessageBanner from '../../../components/messageBanner';
import { TextField, Checkbox, Button, FormControlLabel, FormGroup, Container, Typography, Box, Select, MenuItem } from '@mui/material';
import { createExperiment } from '../../../api/experiment';
import { listSubscriptionPlans } from '../../../api/subscription.plans';
import DataStorage from "../../../storage";

function NewExperimentPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get('message');

  const [message, setMessage] = useState(message_ || '');

  const { id } = useParams();

  const [formData, setFormData] = {
    name: '',
    typ: '',
    active: '',
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get('token')) {
        navigate('/login');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    createExperiment(DataStorage.get('token'), formData)
      .then(resp=>navigate('/experiments'))
      .catch(()=>setMessage('An error occured.'));
  };

  return (
    <Container component="main" maxWidth="xs">
    </Container>
  );
}

export default NewExperimentPage;
