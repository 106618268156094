/** @format */

import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import PricePlansTable from "./PricePlansTableComponent";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix="%"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const PricingComponent = () => {
  var cc = require("currency-codes");
  const [currency, setCurrency] = useState("USD");
  const [percent, setPercent] = useState();
  const [selectedRadio, setSelectedRadio] = useState({
    minPrice: "yes",
    twoYears: "yes",
    threeYears: "yes",
    twoThreeYears: "yes",
  });
  const currencyOptions = cc.codes().map((d) => ({
    currency: d,
  }));
  const handleChange = (event) => {
    setSelectedRadio({
      ...selectedRadio,
      [event.target.name]: event.target.value,
    });
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  const handlePercentChange = (event) => {
    setPercent(event.target.value);
  };

  return (
    <Grid container xs={12}>
      <Grid
        container
        xs={12}
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid
          item
          xs={12}
          lg={4.8}
          sx={{
            backgroundColor: "#2E2E2E",
            borderRadius: "12px",
          }}
        >
          <Grid container sx={{ padding: "5%", height: "100%" }}>
            <Grid item xs={12} sx={{ margin: "auto" }}>
              <TextField
                sx={{
                  width: "90%",
                  margin: "auto",
                  display: "flex",
                  "& .MuiInputBase-root": {
                    color: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiInputLabel-root": {
                    color: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(128, 128, 128, 1)",
                  },
                  "& .MuiSelect-icon": {
                    fill: "rgba(128, 128, 128, 1)",
                  },
                }}
                value={currency}
                label="Select Pricing currency"
                select
                onChange={handleCurrencyChange}
              >
                {currencyOptions.map((item) => (
                  <MenuItem key={item.currency} value={item.currency}>
                    {item.currency}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Divider
              width="100%"
              sx={{ marginTop: "5%", marginBottom: "5%" }}
            />
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "90%",
                  color: "white",
                }}
              >
                Are you willing to offer discounted prices (Min Price) to new
                customers meeting eligibility criteria?{" "}
              </Typography>
              <RadioGroup
                name="minPrice"
                row
                sx={{
                  width: "90%",
                  marginTop: "5%",
                  gap: "5%",
                  color: "white",
                }}
                value={selectedRadio.minPrice}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "20%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "100%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      No
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>

            <Divider
              width="100%"
              sx={{ marginTop: "5%", marginBottom: "5%" }}
            />
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "90%",
                  color: "white",
                }}
              >
                Do you want to offer 2 years subscription term?{" "}
              </Typography>
              <RadioGroup
                name="twoYears"
                row
                sx={{
                  width: "90%",
                  marginTop: "5%",
                  gap: "5%",
                  color: "white",
                }}
                value={selectedRadio.twoYears}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "20%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "100%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      No
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
            <Divider
              width="100%"
              sx={{ marginTop: "5%", marginBottom: "5%" }}
            />
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "90%",
                  color: "white",
                }}
              >
                Do you want to offer 3 years subscription term?{" "}
              </Typography>
              <RadioGroup
                row
                name="threeYears"
                sx={{
                  width: "90%",
                  marginTop: "5%",
                  gap: "5%",
                  color: "white",
                }}
                value={selectedRadio.threeYears}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "20%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "100%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      No
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
            <Divider
              width="100%"
              sx={{ marginTop: "5%", marginBottom: "5%" }}
            />
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  width: "90%",
                  color: "white",
                }}
              >
                Do you want to offer Monthly Billing for 2 & 3 years
                subscription terms?{" "}
              </Typography>
              <RadioGroup
                name="twoThreeYears"
                row
                sx={{
                  width: "90%",
                  marginTop: "5%",
                  gap: "5%",
                  color: "white",
                }}
                value={selectedRadio.twoThreeYears}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "20%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        marginLeft: "100%",
                        "&.Mui-checked": {
                          color: "#B9D2FF",
                        },
                        color: "#808080",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="17px" fontWeight={400}>
                      No
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7} sx={{ marginTop: { xs: "10%", lg: "0%" } }}>
          <PricePlansTable currency={currency} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PricingComponent;
