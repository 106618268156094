// components/Signup.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../api/authentication';
import queryString from 'query-string';
import MessageBanner from './messageBanner';

function passwordCheck(password, password1) {
    if (password !== password1) return 'Passwords are mismatch';
    if (password.length < 8) return 'Password should be at least 8 chars';
    return null;
}

function ResetPasswordPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const message_ = searchParams.get('message');

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [message, setMessage] = useState(message_ || '');

    const navigate = useNavigate();

    const handleResetPassword = (e) => {
        e.preventDefault();
        const pc = passwordCheck(password, password2);
        setMessage('');
        if (pc) {
            setMessage(pc);
            return;
        }
        resetPassword(token, password)
            .then(resp=>{
                const message = 'Password reset successfully';
                const email = resp.data.email;
                navigate('/login?' + queryString.stringify({message, email}));
            })
            .catch(error=>{
                setMessage('An error occured');
            });
    };

    return (
        <div>
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPassword}>
            <div>
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                />
            </div>
            <div>
                <label htmlFor="password2">Re-enter Password:</label>
                <input
                    type="password"
                    id="password2"
                    name="password2"
                    value={password2}
                    onChange={e => setPassword2(e.target.value)}
                    required
                />
            </div>
            <div>
                <button type="submit">Reset Password</button>
                <button onClick={()=>navigate('/login')}>Login</button>
            </div>
        </form>
        <MessageBanner message={message}/>
        </div>
    );
};

export default ResetPasswordPage;