/** @format */

// TargetGroupComponent.js

import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { getData } from "country-list";
import EditModal from "./EditModal";

const countryOptions = getData().map((d) => ({
  value: d.code,
  label: d.name,
}));

function TargetGroupComponent() {
  const [createTarget, setCreateTarget] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingGroup, setEditingGroup] = useState();
  const [newGroupValue, setNewGroupsValue] = useState({
    groupdId: "",
    country: "",
    plan: "",
    subsTerm: "",
    subsBilling: "",
    healthScore: "",
    isActived: "",
    renewalDue: "",
  });
  const [targetGroup, setTargetGroup] = useState([
    {
      groupdId: "Group Name 1",
      value: 1242,
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    },
    {
      groupdId: "Group Name 2",
      value: 1242,
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    },
    {
      groupdId: "Group Name 3",
      value: 1242,
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    },
    {
      groupdId: "Group Name 4",
      value: 1242,
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    },
    {
      groupdId: "Group Name 5",
      value: 1242,
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    },
    {
      groupdId: "Group Name 6",
      value: 1242,
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    },
  ]);

  const handleAdd = () => {
    const newGroup = {
      groupdId: newGroupValue.groupdId,
      value: 1242,
    };

    setTargetGroup([...targetGroup, newGroup]);

    setNewGroupsValue({
      groupdId: "",
      country: "",
      plan: "",
      subsTerm: "",
      subsBilling: "",
      healthScore: "",
      isActived: "",
      renewalDue: "",
    });
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleChange = (e) => {
    setNewGroupsValue({ ...newGroupValue, [e.target.name]: e.target.value });
  };
  const handleSave = (Data, previousId) => {
    const updatedTargetGroup = targetGroup.map((item) => {
      if (item.groupdId == previousId) {
        return { ...item, ...Data };
      }
      return item;
    });

    setTargetGroup(updatedTargetGroup);
    setModalOpen(false);
  };

  const handleEdit = (group) => {
    setEditingGroup(group);
    setModalOpen(true);
  };
  return (

    <Grid item xs={12} sx={{ margin: "1%" }}>
      {!createTarget ? (
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginTop: "1%",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fonstSize: "20px",
                fontWeight: 600,
                margin: "1em",
              }}
            >
              Custom Target Group
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCreateTarget(true)}
              sx={{
                backgroundColor: "#B9D2FF",
                color: "black",
                textTransform: "none",
                whiteSpace: "nowrap",
                marginLeft: "auto",
                marginRight: "2%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                style={{ marginRight: "1%" }}
              >
                <g clip-path="url(#clip0_710_70851)">
                  <path
                    d="M9.99935 6.66797V13.3346M6.66602 10.0013H13.3327M18.3327 10.0013C18.3327 14.6037 14.6017 18.3346 9.99935 18.3346C5.39698 18.3346 1.66602 14.6037 1.66602 10.0013C1.66602 5.39893 5.39698 1.66797 9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013Z"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_710_70851">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              CreateTarget New
            </Button>
          </Grid>
          <Divider width="100%" sx={{ backgroundColor: "#808080" }} />

          {targetGroup.map((item) => (
            <Grid item xs={12} lg={6} sx={{ padding: "2%" }}>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    borderRadius: 100,
                    backgroundColor: "#171717",
                    display: "inline-flex",
                    width: "62px",
                    height: "62px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16 18L19 15M19 15L22 18M19 15V21M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M12 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "2%",
                    fontSize: "16px",
                    color: "#808080",
                    fontWeight: 600,
                  }}
                >
                  {item.groupdId}
                  <Typography
                    sx={{ fontSize: "12px", color: "white", fontWeight: 400 }}
                  >
                    {item.value}
                  </Typography>{" "}
                </Typography>{" "}
                <Button
                  onClick={() => handleEdit(item)}
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    width: "20%",
                    color: "#B9D2FF",
                    textTransform: "none",
                    marginLeft: "auto",
                    border: "1px solid #B9D2FF",
                  }}
                  variant="outlined"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    style={{ marginRight: "5%" }}
                  >
                    <path
                      d="M15.5007 8.33228L12.1673 4.99895M2.58398 17.9156L5.40429 17.6023C5.74887 17.564 5.92115 17.5448 6.08219 17.4927C6.22506 17.4464 6.36102 17.3811 6.48639 17.2984C6.6277 17.2052 6.75027 17.0827 6.99542 16.8375L18.0007 5.83228C18.9211 4.91181 18.9211 3.41942 18.0007 2.49895C17.0802 1.57848 15.5878 1.57847 14.6673 2.49895L3.66209 13.5042C3.41694 13.7493 3.29436 13.8719 3.20118 14.0132C3.11851 14.1386 3.05316 14.2745 3.00691 14.4174C2.95478 14.5784 2.93564 14.7507 2.89735 15.0953L2.58398 17.9156Z"
                      stroke="#B9D2FF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Edit
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginTop: "1%",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fonstSize: "20px",
                fontWeight: 600,
                margin: "1em",
              }}
            >
              Custom Target Group
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCreateTarget(true)}
              sx={{
                backgroundColor: "#B9D2FF",
                color: "black",
                textTransform: "none",
                whiteSpace: "nowrap",
                marginLeft: "auto",
                marginRight: "2%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                style={{ marginRight: "1%" }}
              >
                <g clip-path="url(#clip0_710_70851)">
                  <path
                    d="M9.99935 6.66797V13.3346M6.66602 10.0013H13.3327M18.3327 10.0013C18.3327 14.6037 14.6017 18.3346 9.99935 18.3346C5.39698 18.3346 1.66602 14.6037 1.66602 10.0013C1.66602 5.39893 5.39698 1.66797 9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013Z"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_710_70851">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              CreateTarget New
            </Button>
          </Grid>
          <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <TextField
              label="Group ID"
              name="groupdId"
              autoComplete="off"
              value={newGroupValue.groupdId}
              onChange={(e) => handleChange(e)}
              placeholder="Enter a group name"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <TextField
              select
              name="country"
              label="Customer located in specific Countries"
              value={newGroupValue.country}
              onChange={(e) => handleChange(e)}
              sx={{
                width: "100%",
                borderRadius: "4px",
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)", // Ok (dropdown) ikonunun rengi
                },
              }}
            >
              <MenuItem value="placeholder" disabled>
                Choose Country
              </MenuItem>
              {countryOptions.map((country, index) => (
                <MenuItem key={index} value={country.value}>
                  {country.label}{" "}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}
            >
              Free or Trial or Paid Customer ?
            </Typography>
            <Button
              name="plan"
              value="free"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                textTransform: "none",
                minWidth: "25%",
                marginTop: "2%",
                color: newGroupValue.plan == "free" ? "#B9D2FF" : "white",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  newGroupValue.plan == "free"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Free
            </Button>
            <Button
              name="plan"
              value="trial"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "25%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.plan == "trial" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.plan == "trial"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Trial
            </Button>
            <Button
              name="plan"
              value="paid"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "25%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.plan == "paid" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.plan == "paid"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Paid
            </Button>
            <Button
              name="plan"
              value="all"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                borderLeft: "0",
                minWidth: "25%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.plan == "all" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.plan == "all"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              All
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}
            >
              Customer Health Score:
            </Typography>
            <Button
              name="healthScore"
              value="green"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                textTransform: "none",
                minWidth: "33.3%",
                marginTop: "2%",
                color:
                  newGroupValue.healthScore == "green" ? "#B9D2FF" : "white",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  newGroupValue.healthScore == "green"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Green
            </Button>
            <Button
              name="healthScore"
              value="red"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.healthScore == "red" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.healthScore == "red"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Red
            </Button>

            <Button
              name="healthScore"
              value="all"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.healthScore == "all" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.healthScore == "all"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              All
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}
            >
              Subscription Term:
            </Typography>
            <Button
              name="subsTerm"
              value="monthly"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                textTransform: "none",
                minWidth: "33.3%",
                marginTop: "2%",
                color:
                  newGroupValue.subsTerm == "monthly" ? "#B9D2FF" : "white",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  newGroupValue.subsTerm == "monthly"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Monthly
            </Button>
            <Button
              name="subsTerm"
              value="annually"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color:
                  newGroupValue.subsTerm == "annually" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.subsTerm == "annually"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Annually
            </Button>

            <Button
              name="subsTerm"
              value="all"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.subsTerm == "all" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.subsTerm == "all"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              All
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}
            >
              Activated or Not Activated ?
            </Typography>
            <Button
              name="isActivated"
              value="activated"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                textTransform: "none",
                minWidth: "33.3%",
                marginTop: "2%",
                color:
                  newGroupValue.isActivated == "activated"
                    ? "#B9D2FF"
                    : "white",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  newGroupValue.isActivated == "activated"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Activated
            </Button>
            <Button
              name="isActivated"
              value="notActivated"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color:
                  newGroupValue.isActivated == "notActivated"
                    ? "#B9D2FF"
                    : "white",
                backgroundColor:
                  newGroupValue.isActivated == "notActivated"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Not Activated
            </Button>

            <Button
              name="isActivated"
              value="all"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.isActivated == "all" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.isActivated == "all"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              All
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}
            >
              Subscription Billing:
            </Typography>
            <Button
              name="subsBilling"
              value="monthly"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                textTransform: "none",
                minWidth: "33.3%",
                marginTop: "2%",
                color:
                  newGroupValue.subsBilling == "monthly" ? "#B9D2FF" : "white",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  newGroupValue.subsBilling == "monthly"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Monthly
            </Button>
            <Button
              name="subsBilling"
              value="annually"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color:
                  newGroupValue.subsBilling == "annually" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.subsBilling == "annually"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              Annually
            </Button>

            <Button
              name="subsBilling"
              value="all"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.subsBilling == "all" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.subsBilling == "all"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              All
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "white" }}
            >
              Renewal Due in:
            </Typography>
            <Button
              name="renewalDue"
              value="90days"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                textTransform: "none",
                minWidth: "33.3%",
                marginTop: "2%",
                color:
                  newGroupValue.renewalDue == "90days" ? "#B9D2FF" : "white",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  newGroupValue.renewalDue == "90days"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              90 Days
            </Button>
            <Button
              name="renewalDue"
              value="60days"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color:
                  newGroupValue.renewalDue == "60days" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.renewalDue == "60days"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              60 Days
            </Button>

            <Button
              name="renewalDue"
              value="all"
              onClick={(e) => handleChange(e)}
              sx={{
                border: "1px solid #808080",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                borderLeft: "0",
                minWidth: "33.3%",
                marginTop: "2%",
                textTransform: "none",
                color: newGroupValue.renewalDue == "all" ? "#B9D2FF" : "white",
                backgroundColor:
                  newGroupValue.renewalDue == "all"
                    ? "rgba(185, 210, 255, 0.04)"
                    : null,
              }}
            >
              {" "}
              All
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            <Button
              variant="standard"
              onClick={() => setCreateTarget(false)}
              sx={{
                color: "white",
                textTransform: "none",
                whiteSpace: "nowrap",
                marginLeft: "auto",
                marginRight: "2%",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => (setCreateTarget(false), handleAdd())}
              sx={{
                backgroundColor: "#B9D2FF",
                color: "black",
                textTransform: "none",
                whiteSpace: "nowrap",
                marginRight: "2%",
                minWidth: "7%",
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
      <EditModal
        open={modalOpen}
        handleClose={handleClose}
        handleSave={handleSave}
        data={editingGroup}
        countryOptions={countryOptions}
      />
    </Grid>
  );
}

export default TargetGroupComponent;
