/** @format */

import React from "react";
import { Grid, Typography, IconButton, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 *
 *
 * @param {*} { selectedCustomer, onDeleteCustomer }
 * @return {*}
 */
const SearchResultsComponent = ({ selectedCustomer, onDeleteCustomer }) => {
  console.log(selectedCustomer);
  return (
    <Grid
      item
      xs={11.7}
      flexDirection={"row"}
      display={"flex"}
      alignItems={"center"}
      sx={{
        borderRadius: "12px",
        backgroundColor: "#2E2E2E",
        padding: "1%",
        marginTop: "2%",
        justifyContent: "space-between",
        gap: "2%",
        height: "10vh",
      }}
    >
      <Typography
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          fontSize: "20px",
          fontWeight: 600,
          color:"white"
        }}
      >
        Search Results:{" "}
        {selectedCustomer?.map((customer, index) => (
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              padding: "1%",
              marginLeft: "2%",
            }}
          >
            <Avatar sx={{ bgcolor: "rgba(128, 128, 128, 1)" }}>
              {getInitials(customer.businessName)}
            </Avatar>
            <Typography
              marginLeft={"4%"}
              whiteSpace={"nowrap"}
              sx={{ fontSize: "18px", fontWeight: 400 }}
            >
              {getFormattedName(customer.businessName)}
            </Typography>
            <IconButton
              onClick={() => onDeleteCustomer(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g opacity="0.26">
                  <path
                    d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z"
                    fill="#808080"
                  />
                </g>
              </svg>{" "}
            </IconButton>
          </Typography>
        ))}
      </Typography>
    </Grid>
  );
};
function getFormattedName(name) {
  const words = name.split(" ");
  const formattedName = words
    .map((word) => {
      const firstChar = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstChar + restOfWord;
    })
    .join(" ");
  return formattedName;
}

function getInitials(name) {
  const words = name.split(" ");
  const initials = words
    .filter((word, index) => index === 0 || index === words.length - 1) // İlk ve son kelimeyi seç
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
  return initials;
}

export default SearchResultsComponent;
