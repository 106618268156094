/** @format */

// CurrentSubsComponent.js

import React from "react";
import {
  Grid,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import Divider from "@mui/material/Divider";

function CurrentSubsComponent({ customerPlans, navigate, customer }) {
  console.log("trial", customerPlans);
  const createdDate = new Date(customerPlans.currentCustomerPlan.created_at);
  const day = String(createdDate.getDate()).padStart(2, "0");
  const month = String(createdDate.getMonth() + 1).padStart(2, "0");
  const year = createdDate.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  return (
    <Grid
      container
      spacing={1}
      sx={{
        marginBottom: "16px",
      }}
    >
      <Grid item xs={7.5}>
        <Grid
          container
          xs={12}
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "12px",
            height: "100%",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "white",
                fonstSize: "20px",
                fontWeight: 600,
                margin: "1em",
              }}
            >
              Current Subscription
            </Typography>
          </Grid>
          <Divider width="100%" />
          <Grid item xs={12}>
            <ListItem>
              <Grid container>
                <Grid item xs={6} lg={4}>
                  <ListItemText
                    primary={
                      <Typography>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Subscription Date:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ListItemText
                    primary={
                      <Typography>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Price paid in currency:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customer.billingCurrency}
                        </Typography>
                      </Typography> //bacakendden çekilmesi lazım
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ListItemText
                    primary={
                      <Typography>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Subscription Billing:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          {customerPlans.currentCustomerPlan.billingPeriod}
                        </Typography>
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <Grid container>
                <Grid item xs={6} lg={4}>
                  <ListItemText
                    primary={
                      <Typography>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Subscription Term:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontWeight: 600,
                            marginTop: "0.5em",
                            fontSize: "1rem",
                          }}
                        >
                          3 years*
                        </Typography>
                      </Typography> //backendeden çekilmesi lazım
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={8}>
                  <ListItemText
                    primary={
                      <Typography>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#808080",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          }}
                        >
                          Premium Plan:
                        </Typography>
                        {customerPlans.valFeats.map((feat, index) => (
                          <Typography
                            key={index}
                            style={{
                              color: "white",
                              fontWeight: 600,
                              fontSize: "1rem",
                              display: "inline-block", // Yazıları yan yana sıralamak için eklenen stil
                              marginRight: "0.5em", // Yazılar arasında boşluk bırakmak için eklenen stil
                            }}
                          >
                            {index === 0
                              ? `+ ${feat.valFeat.description}`
                              : `- ${feat.valFeat.description}`}
                            {index < customerPlans.valFeats.length - 1
                              ? ","
                              : ""}
                          </Typography>
                        ))}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4.5}>
        <Grid
          container
          xs={12}
          sx={{
            marginBottom: "16px",
            borderRadius: "12px",
            height: "100%",
            position: "relative",
            backgroundColor: customer.customerHealth == 0 ? "red" : "#29BB72",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              paddingLeft: 2,
              padding: "3%",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                borderRadius: "8px",
                backgroundColor: "white",
                width: "48px",
                height: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15.85 11.6227H14.75L13.1 15.0189L10.9 8.22642L9.25 11.6227H8.15M11.9925 4.41791C9.79318 1.77177 6.12572 1.05997 3.37016 3.48304C0.614608 5.9061 0.226665 9.95734 2.39062 12.8231C4.0251 14.9877 8.66842 19.3332 10.8428 21.3301C11.2425 21.6972 11.4424 21.8807 11.6764 21.953C11.8796 22.0157 12.1054 22.0157 12.3085 21.953C12.5426 21.8807 12.7424 21.6972 13.1421 21.3301C15.3165 19.3332 19.9598 14.9877 21.5943 12.8231C23.7583 9.95734 23.4177 5.88061 20.6148 3.48304C17.8118 1.08546 14.1918 1.77177 11.9925 4.41791Z"
                  stroke="#808080"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Typography>
            <Typography
              sx={{ fontSize: "34px", fontWeight: 600, color: "white" }}
            >
              Health
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                position: "absolute",
                right: 50,
                bottom: 15,
                fonstSize: "14px",
                fontWeight: 500,
                marginBottom: "16px",
                cursor: "pointer",
                textDecoration: "underline",
                userSelect: "none",
              }}
              onClick={() => navigate(`/customer-health/${customer.id}`)}
            >
              See more
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CurrentSubsComponent;
