/** @format */

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Typography, Grid, Divider } from "@mui/material";
import { faker } from "@faker-js/faker";

ChartJS.register(ArcElement);
const activeUser = faker.datatype.number({ min: 0, max: 100 });
const inActiveUser = faker.datatype.number({ min: 0, max: 100 });
const activeUserPercent = (
  (activeUser / (activeUser + inActiveUser)) *
  100
).toFixed(2);
const inActiveUserPercent = (
  (inActiveUser / (activeUser + inActiveUser)) *
  100
).toFixed(2);
export const options = {
  aspectRatio: 1,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || "";
          if (label) {
            const value = context.parsed;
            return ` ${value.toFixed(2)}%`;
          }
          return "";
        },
      },
    },
  },
};
const data = {
  labels: ["Active Users", "Inactive Users"],

  datasets: [
    {
      data: [activeUserPercent, inActiveUserPercent],
      backgroundColor: ["rgba(195, 185, 255, 0.5)", "#C3B9FF"],
    },
  ],
};

export function UsersChart() {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        height: "470px",
        alignItems: "center",
        padding: "5%",
        justifyContent: "space-between",
      }}
    >
      <Grid item xs={7}>
        <Doughnut data={data} options={options} />
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "24px", color: "white" }}>
          {activeUserPercent}%
          <Typography sx={{ fontSize: "12px", color: "#808080" }}>
            Active Users
          </Typography>
        </Typography>
        <Typography sx={{ fontSize: "24px", color: "white" }}>
          {inActiveUserPercent}%
          <Typography sx={{ fontSize: "12px", color: "#808080" }}>
            Inactive Users
          </Typography>
        </Typography>
      </Grid>
      <Divider width="90%" sx={{ margin: "15px",backgroundColor:"#808080" }} />
      <Grid xs={12} sx={{ paddingLeft: "10%", paddingBottom: "10%" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "white" }}>
          Active Users:{activeUser}
        </Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "white" }}>
          Inactive Users:{inActiveUser}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default UsersChart;
