/** @format */

// TestingNegotiationsComponent.js

import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  MenuItem,
  TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import { startDummyNegotiation } from "../../api/negotiation";
import { listSubscriptionModels } from "../../api/subscription.plans";
import MessageBanner from "../../components/messageBanner";
import DataStorage from "../../storage";

const renewalStrategies = [
  "Subscribe x 1-Bootsrapper(-)",
  "Subscribe x 1-Bootsrapper",
  "Subscribe x 1-Bootsrapper(+)",
  "Subscribe x 1",
  "Subscribe x 2",
  "Subscribe x 3",
];

const billingPeriods = ["Monthly", "Annual", "Two year", "Three year"];

function TestingNegotiationsComponent() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [subscriptionModels, setSubscriptionModels] = useState(null);
  const [renewalStrategy, setRenewalStrategy] = useState(renewalStrategies[0]);
  const [subscriptionModel, setSubscriptionModel] = useState();
  const [billingPeriod, setBillingPeriod] = useState(billingPeriods[0]);

  const navigate = useNavigate();

  useEffect(() => {
    listSubscriptionModels(DataStorage.get("token"))
      .then((resp) => {
        setSubscriptionModels(resp.data);
        setSubscriptionModel(resp.data[0].id);
      })
      .catch(() => setMessage("An error occured."));
  }, []);

  console.log(subscriptionModels);

  const handleLaunch = (renewalStrategy) => {
    startDummyNegotiation(
      DataStorage.get("token"),
      subscriptionModel,
      billingPeriod,
      {},
      renewalStrategy
    )
      .then((resp) => navigate(`/offer/${resp.data.token}`))
      .catch(() => setMessage("An error occured."));
  };

  const handleBillingPeriodChange = (e) => {
    let currentModel = subscriptionModels.find(
      (sm) => sm.id == subscriptionModel
    );
    let billingPeriod = e.target.value;
    console.log(billingPeriod, currentModel.interval);
    if (
      (billingPeriod == "Annual" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Three year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Two year")
    ) {
      setMessage("Billing period must be less than subscription term");
      return;
    }
    if (billingPeriod == "Two year" && currentModel.interval == "Three year") {
      setMessage("Three year plans has no two year billing");
      return;
    }
    setBillingPeriod(e.target.value);
  };

  const handleSubModelChange = (e) => {
    let currentModel = subscriptionModels.find((sm) => sm.id == e.target.value);
    console.log(billingPeriod, currentModel.interval);
    if (
      (billingPeriod == "Annual" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Three year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Two year")
    ) {
      setMessage("Billing period must be less than subscription term");
      return;
    }
    if (billingPeriod == "Two year" && currentModel.interval == "Three year") {
      setMessage("Three year plans has no two year billing");
      return;
    }
    setSubscriptionModel(e.target.value);
  };

  const handleRenewalStrategyChange = (e) => {
    setRenewalStrategy(e.target.value);
    handleLaunch(e.target.value);
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        margin: "auto",
        marginTop: "2%",
        marginBottom: "5%",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              color: "White",
              fontSize: "20px",
              fontWeight: 600,
              margin: "1em",
            }}
          >
            Testing Negotiation Strategies
          </Typography>
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_338_6132)">
                <path
                  d="M7.57533 7.49935C7.77125 6.94241 8.15795 6.47277 8.66695 6.17363C9.17596 5.87448 9.7744 5.76513 10.3563 5.86494C10.9382 5.96475 11.466 6.26729 11.8462 6.71896C12.2264 7.17063 12.4345 7.74228 12.4337 8.33268C12.4337 9.99935 9.93366 10.8327 9.93366 10.8327M10.0003 14.166H10.0087M18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327C5.39795 18.3327 1.66699 14.6017 1.66699 9.99935C1.66699 5.39698 5.39795 1.66602 10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_338_6132">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
        </Grid>
        <Divider width="100%" />

        <Grid item xs={12} lg={6} sx={{ padding: "2%" }}>
          {" "}
          <TextField
            select
            label="Select billing period    "
            name="subcription"
            value={billingPeriod}
            onChange={handleBillingPeriodChange}
            sx={{
              width: "100%",
              borderRadius: "4px",
              "& .MuiInputBase-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
              "& .MuiSelect-icon": {
                fill: "rgba(128, 128, 128, 1)",
              },
            }}
          >
            {billingPeriods?.map((renewalStrategy, index) => (
              <MenuItem key={index} value={renewalStrategy}>
                {renewalStrategy}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ padding: "2%" }}>
          {" "}
          <TextField
            select
            label="Select a subscription model"
            name="subcription"
            value={subscriptionModel}
            onChange={handleSubModelChange}
            sx={{
              width: "100%",
              borderRadius: "4px",
              "& .MuiInputBase-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
              "& .MuiSelect-icon": {
                fill: "rgba(128, 128, 128, 1)",
              },
            }}
          >
            {subscriptionModels?.map((subModel, index) => (
              <MenuItem key={index} value={subModel.id}>
                {subModel.plan.name + " - " + subModel.interval}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {renewalStrategies?.map((item) => (
          <Grid xs={12} lg={6} sx={{ padding: "2%" }}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  borderRadius: 100,
                  backgroundColor: "#171717",
                  display: "inline-flex",
                  width: "62px",
                  height: "62px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16 8V5L19 2L20 4L22 5L19 8H16ZM16 8L12 11.9999M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
              <Typography
                sx={{
                  marginLeft: "2%",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: 600,
                }}
              >
                {item}
              </Typography>{" "}
              <Button
                onClick={handleRenewalStrategyChange}
                value={item}
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  width: "20%",
                  color: "#B9D2FF",
                  borderColor: "#B9D2FF",
                  textTransform: "none",
                  marginLeft: "auto",
                  "&:hover": {
                    backgroundColor: "#8E99AB",
                  },
                }}
                variant="outlined"
              >
                Simulate
              </Button>
            </Box>
            <Divider sx={{ width: "100%", marginTop: "2%" }} />
          </Grid>
        ))}
      </Grid>
      <MessageBanner message={message} />
    </Grid>
  );
}

export default TestingNegotiationsComponent;
