import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; // Set your API base URL here

export function getArmSet(token, armSetId) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.get(`${API_BASE_URL}/api/experiments/arm-set/${armSetId}/`, config);
}

export function createArmSet(token, experimentId) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.post(`${API_BASE_URL}/api/experiments/arm-set/create/${experimentId}/`, {}, config);
}

export function updateArmSet(token, armSetId, data) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.put(`${API_BASE_URL}/api/experiments/arm-set/${armSetId}/`, data, config);
}

export function partialUpdateArmSet(token, armSetId, data) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.patch(`${API_BASE_URL}/api/experiments/arm-set/${armSetId}/`, data, config);
}

export function deleteArmSet(token, armSetId) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.delete(`${API_BASE_URL}/api/experiments/arm-set/${armSetId}/`, config);
}

export function listArmSets(token, experimentId) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.get(`${API_BASE_URL}/api/experiments/arm-set/list/${experimentId}/`, config);
}
