/** @format */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import MessageBanner from "./messageBanner";
import SubscriptionPlanTable from "./subscription.plan.table";
import {
  getSubscriptionPlan,
  saveSubscriptionPlan,
  deleteSubscriptionPlan,
} from "../api/subscription.plans";
import DataStorage from "../storage";

function ShowSubscriptionPlanPage() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [subPlan, setSubPlan] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      getSubscriptionPlan(DataStorage.get("token"), id)
        .then((resp) => {
          setSubPlan(resp.data);
        })
        .catch(() => setMessage("An error occurred."));
    }
  }, []);

  const handleInputChange = (field, value) => {
    setSubPlan({ ...subPlan, [field]: value });
  };

  const handleSave = () => {
    if(subPlan.FullPrice <= 0  ){
        setMessage("Full Price must be greater than 0.");
        return; 
    }
    else{
    saveSubscriptionPlan(DataStorage.get("token"), id, subPlan)
      .then((resp) => setSubPlan(resp.data))
      .catch(() => setMessage("An error occurred."));}
  };

  const handleDelete = () => {
    deleteSubscriptionPlan(DataStorage.get("token"), id)
      .then((resp) => navigate("/subscription-plans"))
      .catch(() => setMessage("An error occurred."));
  };

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <h2>Subscription Plan Details</h2>
        {subPlan && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: "15px" }}>
                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Plan Name"
                        name="name"
                        value={subPlan.name}
                        onChange={(e) =>
                          handleInputChange("name", parseFloat(e.target.value))
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  
                   
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Full Price:"
                        type="number"
                        name="FullPrice"
                        value={subPlan.FullPrice}
                        onChange={(e) =>
                          handleInputChange(
                            "FullPrice",
                            parseFloat(e.target.value)
                          )
                        }
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </Box>

                <br />
              </Paper>
            </Grid>{" "}
            <Grid item xs={12} md={6}>
              <SubscriptionPlanTable data={subPlan.table} />
            </Grid>
          </Grid>
        )}
        <MessageBanner message={message} setMessage={setMessage} />
      </Box>
    </Container>
  );
}

export default ShowSubscriptionPlanPage;
