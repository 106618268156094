/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MessageBanner from "../../components/messageBanner";
import { createCustomer } from "../../api/customers";
import queryString from "query-string";
import {
  Typography,
  TextField,
  Grid,
  Stack,
  Popover,
  Button,
  Box,
  InputAdornment,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import DataStorage from "../../storage";
import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GeneralInfoInputComponent from "./GeneralInfoInputComponent";
import CurrentSubsComponent from "./CurrentSubsComponent";
import { listSubscriptionPlans } from "../../api/subscription.plans";
import Papa from "papaparse";
function NewCustomerPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");
  const [searchText, setSearchText] = useState("");
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [message, setMessage] = useState(message_ || "");

  const [formData, setFormData] = useState({
    businessName: "",
    administratorName: "",
    administratorEmail: "",
    country: "",
    estimatedRevenueInUSD: "",
    customerSince: "",
    billingCurrency: "",
    amountPeriod: "",
    externalFunding: "",
    id: "",
    billingPeriod: "year",
  });

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      listSubscriptionPlans(DataStorage.get("token"))
        .then((resp) => setSubscriptionPlans(resp.data))
        .catch(() => setMessage("An error occured."));
    }

    drop.current = document.getElementById("fileInput");

    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    return () => {
      drop.current.removeEventListener("dragover", handleDragOver);
      drop.current.removeEventListener("drop", handleDrop);
    };
  }, []);

  const drop = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    const formDataArray = Object.keys(formData);
    Papa.parse(droppedFile, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        const data = result.data;
        console.log("Bırakılan Dosya:", data);

        // Önceki verileri korumak için yeni bir nesne oluşturun
        let updatedFormData = { ...formData };

        const keys = Object.keys(data[0]);

        formDataArray.forEach((item, index) => {
          keys.forEach((key) => {
            if (key === item) {
              updatedFormData[item] = data[0][item];
            }
          });
        });

        // Güncellenmiş verileri kullanarak formData'yı güncelleyin
        setFormData(updatedFormData);
      },
    });
  };

  const handleFileUpload = (file) => {
    console.log(file.size);
    const maxSizeInBytes = 1 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      console.log("File size cannot be more than 5MB");
      file.value = "";
    } else {
      console.log("Seçilen dosya:", file);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.businessName === "Test Customer") {
      setMessage("Invalid business name. Please provide a different name.");
    } else {
      createCustomer(DataStorage.get("token"), formData)
        .then((resp) => {
          const message = "Customer added successfully.";
          navigate("/customers?" + queryString.stringify({ message }));
        })
        .catch(
          (error) => (console.log(error), setMessage("An error occurred."))
        );
    }
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#171717",
        alignContent: "flex-start",
        overflowY: "auto",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%", backgroundColor: "#808080" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          margin: "1%",
          marginBottom: "0px",
          backgroundColor: "#1F1F1F",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          minHeight: "100%",
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sx={{ margin: "2%" }}
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                backgroundColor: "#808080",
                width: "32px",
                height: "32px",
                borderRadius: "100px",
              }}
              onClick={() => navigate("/customers")}

            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                color="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 16H10M10 16L16 22M10 16L16 10"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Typography>

            <Typography
              marginLeft={"1%"}
              sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
            >
              Add Customer
            </Typography>
          </Grid>
          <Divider
            sx={{
              width: "99.5%",
              marginBottom: "2%",
              marginTop: "2%",
              marginLeft: "0.5%",
              backgroundColor: "#808080",
            }}
          />
          <Grid item xs={12} sx={{ justifyContent: "center", margin: "1%" }}>
            <label
              htmlFor="fileInput"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                id="fileInput"
                onChange={(e) => handleFileUpload(e.target.files[0])}
              />
              <Grid
                container
                sx={{
                  marginBottom: "16px",
                  backgroundColor: "#2E2E2E",
                  borderRadius: "12px",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: "20vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "20px", fontWeight: 600, color: "white" }}
                    >
                      Upload CSV file:
                    </Typography>
                    <Typography
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        backgroundColor: "#1F1F1F",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#171717",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6.6665 13.3333L9.99984 10M9.99984 10L13.3332 13.3333M9.99984 10V17.5M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        flexDirection: "row",
                        display: "flex",
                        color: "white",
                      }}
                    >
                      Click to upload{" "}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          marginLeft: "5px",
                          color: "white",
                        }}
                      >
                        or drag and drop
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#808080",
                      }}
                    >
                      Max.500MB
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>{" "}
            </label>
          </Grid>
          <Divider
            sx={{
              width: "99.5%",
              marginLeft: "0.5%",
              marginTop: "2%",
              marginBottom: "2%",
              backgroundColor: "#808080", 
              height: "1px",
              alignItems: "center",
              display: "flex",
              zIndex: 0,
            }}
          >
            <Chip
              label="OR"
              sx={{
                border: "1px solid #808080",
                borderRadius: "12px",
                width: "98px",
                color: "white",
                zIndex: 1,
                backgroundColor: "#1F1F1F",
              }}
            />
          </Divider>

          <Grid
            item
            xs={12}
            lg={6}
            sx={{ justifyContent: "center", paddingRight: "1%" }}
          >
            <Grid
              container
              sx={{
                marginBottom: "16px",
                backgroundColor: "#2E2E2E",
                borderRadius: "12px",
                marginLeft: "2%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: "7vh",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "7vh",
                      display: "flex",
                      width: "60%",
                      marginLeft: "2%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "#1F1F1F",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#171717",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M11.6668 1.89105V5.33317C11.6668 5.79988 11.6668 6.03324 11.7577 6.2115C11.8376 6.3683 11.965 6.49578 12.1218 6.57568C12.3001 6.6665 12.5335 6.6665 13.0002 6.6665H16.4423M16.6668 8.32336V14.3332C16.6668 15.7333 16.6668 16.4334 16.3943 16.9681C16.1547 17.4386 15.7722 17.821 15.3018 18.0607C14.767 18.3332 14.067 18.3332 12.6668 18.3332H7.3335C5.93336 18.3332 5.2333 18.3332 4.69852 18.0607C4.22811 17.821 3.84566 17.4386 3.60598 16.9681C3.3335 16.4334 3.3335 15.7333 3.3335 14.3332V5.6665C3.3335 4.26637 3.3335 3.56631 3.60598 3.03153C3.84566 2.56112 4.22811 2.17867 4.69852 1.93899C5.2333 1.6665 5.93336 1.6665 7.3335 1.6665H10.01C10.6215 1.6665 10.9272 1.6665 11.2149 1.73558C11.47 1.79682 11.7139 1.89783 11.9375 2.0349C12.1898 2.18951 12.406 2.4057 12.8384 2.83808L15.4953 5.49493C15.9276 5.92731 16.1438 6.1435 16.2984 6.39579C16.4355 6.61947 16.5365 6.86333 16.5978 7.11842C16.6668 7.40614 16.6668 7.71188 16.6668 8.32336Z"
                            stroke="white"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        marginLeft: "2%",
                        color: "white",
                      }}
                    >
                      Download Customer Details CSV
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      marginRight: "8px",
                      height: "70%",
                      width: "20%",
                      color: "#B9D2FF",
                      borderColor: "#B9D2FF",
                    }}
                  >
                    Download File
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            sx={{ justifyContent: "center", paddingRight: "1%" }}
          >
            <Grid
              container
              sx={{
                marginBottom: "16px",
                backgroundColor: "#2E2E2E",
                borderRadius: "12px",
                marginLeft: "2%",
                width: "98%",
              }}
            >
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: "7vh",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: "7vh",
                      display: "flex",
                      width: "60%",
                      marginLeft: "2%",
                      alignItems: "center",
                    }}
                  >
                         <Typography
                      sx={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "#1F1F1F",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#171717",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M11.6668 1.89105V5.33317C11.6668 5.79988 11.6668 6.03324 11.7577 6.2115C11.8376 6.3683 11.965 6.49578 12.1218 6.57568C12.3001 6.6665 12.5335 6.6665 13.0002 6.6665H16.4423M16.6668 8.32336V14.3332C16.6668 15.7333 16.6668 16.4334 16.3943 16.9681C16.1547 17.4386 15.7722 17.821 15.3018 18.0607C14.767 18.3332 14.067 18.3332 12.6668 18.3332H7.3335C5.93336 18.3332 5.2333 18.3332 4.69852 18.0607C4.22811 17.821 3.84566 17.4386 3.60598 16.9681C3.3335 16.4334 3.3335 15.7333 3.3335 14.3332V5.6665C3.3335 4.26637 3.3335 3.56631 3.60598 3.03153C3.84566 2.56112 4.22811 2.17867 4.69852 1.93899C5.2333 1.6665 5.93336 1.6665 7.3335 1.6665H10.01C10.6215 1.6665 10.9272 1.6665 11.2149 1.73558C11.47 1.79682 11.7139 1.89783 11.9375 2.0349C12.1898 2.18951 12.406 2.4057 12.8384 2.83808L15.4953 5.49493C15.9276 5.92731 16.1438 6.1435 16.2984 6.39579C16.4355 6.61947 16.5365 6.86333 16.5978 7.11842C16.6668 7.40614 16.6668 7.71188 16.6668 8.32336Z"
                            stroke="white"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        marginLeft: "2%",
                        color: "white",
                      }}
                    >
                      Download Current Subscription CSV
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      marginRight: "8px",
                      height: "70%",
                      width: "20%",
                      color: "#B9D2FF",
                      borderColor: "#B9D2FF",
                    }}
                  >
                    Download File
                  </Button>
                </Grid>
              </Grid>
      
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "center", margin: "1%" }}
          >
            <GeneralInfoInputComponent
              handleChange={handleChange}
              formData={formData}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "center", margin: "1%" }}
          >
            <CurrentSubsComponent subscriptionPlans={subscriptionPlans} />
          </Grid>
          <Grid xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                backgroundColor: "#B9D2FF",
                textTransform: "none",
                marginRight: "20px",
                height: "44px",
                width: "135px",
                marginTop: "1vh",
                marginBottom: "5vh",
                color:"black"
              }}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MessageBanner message={message} setMessage={setMessage} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NewCustomerPage;
