/** @format */

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import { Grid, Typography, Table, TableRow, TableCell } from "@mui/material";
function ModalComponent({ open, setModal, experiments }) {
  return (
    <Dialog
      open={open}
      onClose={() => setModal(false)}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogContent
        sx={{
          backgroundColor: "whitesmoke",
          "&::-webkit-scrollbar": {
            width: "13    px",
            margin: "15px",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            borderRadius: 25,
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        <DialogTitle sx={{ justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={() => setModal(false)} color="primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
        </DialogTitle>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "16px",
            paddingTop: "2%",
            paddingBottom: "2%",
            overflowX: "scroll",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              height: "7px",
              margin: "15px",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              borderRadius: 25,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "2%",
              marginLeft: "1%",
            }}
          >
            {" "}
            Baseline
          </Typography>
          <Table>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #E0E0E0;",
                  borderLeft: "0px",
                  whiteSpace: "nowrap",
                }}
              >
                Experiment ID
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Experiment Name
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Free plan Customers{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Trial Customers
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Paid customers
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Customers Plan Upgrades
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Customers purchasing Add-On features
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #E0E0E0;",
                  whiteSpace: "nowrap",
                  borderLeft: "0",
                }}
              >
                {experiments[0].id}{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                {experiments[0].experimentName}{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                {experiments[0].freePlan}{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                {experiments[0].trialCustomer}{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                {experiments[0].paidCustomers}{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                {experiments[0].planUpgrades}{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                {experiments[0].purchase}{" "}
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "center",
            backgroundColor: "white",
            marginTop: "3%",
            borderRadius: "16px",
            paddingTop: "2%",
            paddingBottom: "2%",
            overflowX: "scroll",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              height: "7px",
              margin: "15px",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              borderRadius: 25,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "2%",
              marginLeft: "1%",
            }}
          >
            {" "}
            Experiment Outcomes
          </Typography>
          <Table>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #E0E0E0;",
                  borderLeft: "0px",
                  whiteSpace: "nowrap",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "1px solid #E0E0E0;",
                  borderLeft: "0px",
                  whiteSpace: "nowrap",
                }}
              >
                Experiment ID
              </TableCell>

              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Experiment Name
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Free plan Customers{" "}
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Trial Customers
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Paid customers
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Customers Plan Upgrades
              </TableCell>
              <TableCell
                sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
              >
                Customers purchasing Add-On features
              </TableCell>
            </TableRow>
            {experiments.map((experiment, index) => (
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #E0E0E0;",
                    whiteSpace: "nowrap",
                    borderLeft: "0",
                  }}
                >
                  Armset {index + 2}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.id}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.experimentName}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.freePlan}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.trialCustomer}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.paidCustomers}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.planUpgrades}{" "}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0;", whiteSpace: "nowrap" }}
                >
                  {experiment.purchase}{" "}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ModalComponent;
