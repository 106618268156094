/** @format */

import React, { useEffect } from "react";
import "./landingPageStyles.css";
import { useLocation, useNavigate } from "react-router-dom";
import DataStorage from "../../storage";

function LandingPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (DataStorage.get("token")) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <div className="onboardoing">
      <div className="frame">
        <p className="x-v-AI">
          <span className="text-wrapper">X-V </span>
          <span className="span">AI</span>
        </p>
        <p className="div">
          Crafting the most optimal solutions for the right customer at the
          right price, time and terms
        </p>
        <div className="button-container">
          <button className="login" onClick={() => navigate("/login")}>
            Login
          </button>
          <button className="signup" onClick={() => navigate("/signup")}>
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
