/** @format */

// GeneralInfoInputComponent.js

import React from "react";
import { Grid, ListItem, TextField, Typography, MenuItem } from "@mui/material";
import { getData } from "country-list";
import Divider from "@mui/material/Divider";
import { country } from "currency-codes";

function GeneralInfoInputComponent({ handleChange, formData }) {
  const countryOptions = getData().map((d) => ({
    value: d.code,
    label: d.name,
  }));

  return (
    <Grid
      container
      sx={{
        marginBottom: "16px",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "#808080",
              fonstSize: "20px",
              fontWeight: 600,
              margin: "1em",
            }}
          >
            General Info
          </Typography>
        </Grid>
        <Divider width="100%" />
        <Grid item xs={12}>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Customer ID"
                  name="id"
                  placeholder="Enter ID"
                  value={formData.id}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Account Manager name"
                  name="managerName"
                  placeholder="Enter Name"
                  value={formData.id}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Business Name"
                  name="businessName"
                  placeholder="Enter Business Name"
                  value={formData.businessName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Administrator Name"
                  name="administratorName"
                  placeholder="Enter Administrator Name"
                  value={formData.administratorName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiSelect-icon": {
                      fill: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Country of incorporation"
                  select
                  name="country"
                  placeholder="Enter Country of incorporation"
                  value={formData.country}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  {countryOptions.map((country) => (
                    <MenuItem value={country.value}>{country.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Administrator Email"
                  type="email"
                  name="administratorEmail"
                  placeholder="Enter Administrator Email"
                  value={formData.administratorEmail}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Estimated Revenue"
                  placeholder="Enter Estimated Revenue"
                  type="number"
                  name="estimatedRevenueInUSD"
                  value={formData.estimatedRevenueInUSD}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Customer Since"
                  placeholder="Enter Date"
                  type="date"
                  name="customerSince"
                  value={formData.customerSince}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiSelect-icon": {
                      fill: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="External Funding"
                  name="externalFunding"
                  placeholder="Enter External Funding"
                  value={formData.externalFunding}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  select
                  margin="normal"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Billing Currency"
                  name="billingCurrency"
                  placeholder="Enter Billing Currency"
                  value={formData.billingCurrency}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiSelect-icon": {
                      fill: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Special Pricing Awardee"
                  name="SpeciaPricingAwardee"
                  value={formData.SpeciaPricingAwardee}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  select
                  margin="normal"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                  label="Amount per billing period"
                  name="amountPeriod"
                  placeholder="Enter amount per billing period"
                  value={formData.amountPeriod}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <TextField
                  margin="normal"
                  select
                  name="billingPeriod"
                  label="Billing Period"
                  placeholder="Period"
                  value={formData.billingPeriod}
                  onChange={handleChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "#808080",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiSelect-icon": {
                      fill: "rgba(128, 128, 128, 1)",
                    },
                  }}
                >
                  <MenuItem value="year">Year</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GeneralInfoInputComponent;
