/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { Grid, Typography, Select, MenuItem } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
    },
    y: {
      grid: {
        color: "#2E2E2E52",
        lineWidth: 3,
      },
    },
  },
};

const generateLabels = (select) => {
  switch (select) {
    case "month":
      return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    case "day":
      return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    case "week":
      return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    default:
      return [];
  }
};

export function SentimentChart({ select }) {
  const [labels, setLabels] = useState(generateLabels(select));

  useEffect(() => {
    setLabels(generateLabels(select));
  }, [select]);

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "#B9FFC3",
      },
    ],
  };

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        padding: "5%",
        height: "470px",
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          paddingRight: "%30",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            marginRight: "5%",
            color: "white",
          }}
        >
          Based on NPS for:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Select
          value={"select1"}
          //onChange={(e) => setSelect(e.target.value)}
          sx={{
            backgroundColor: "#1F1F1F",
            borderRadius: "5px",
            color: "white",
            fontWeight: 600,
            "& .MuiSelect-icon": {
              color: "#808080",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "white",
            },
          }}
          /**      MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: "#8E99AB",
                "& li:hover": {
                  backgroundColor: "#656970",
                },
              },
            },
          }} */
        >
          <MenuItem value="select1">All NPS Surveys</MenuItem>
          <MenuItem value="select2">Select2</MenuItem>
          <MenuItem value="select3">Select3</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Bar data={data} options={options} />
      </Grid>
    </Grid>
  );
}

export default SentimentChart;
