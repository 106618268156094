/** @format */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Divider,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
} from "@mui/material";

const ValueFeatures = ({
  data,
  handleChange,
  handleItemToggle,
  selectedItems,
  subPlans,
  handleSubPlanChange,
  isEditable,
  isLoading,
}) => {
  const longestSubPlanLength = Math.max(
    ...data.map((item) => item.subPlan.length)
  );
  let xsValue = 9.5 / longestSubPlanLength;
  if (xsValue > 4) {
    xsValue = 4;
  } else if (xsValue < 2) {
    xsValue = 2;
  }
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      // If loading is not true, show the skeleton for 1 second
      const skeletonTimeout = setTimeout(() => {
        setShowSkeleton(false);
      }, 1000);

      return () => {
        clearTimeout(skeletonTimeout);
      };
    }
  }, [isLoading]);
  return (

        <Grid item sx={{ overflowX: "auto" }}>
          <Grid
            item
            xs={12}
            sx={{
              padding: "20px",
              backgroundColor: "#2E2E2E",
              borderRadius: "12px",
              paddingTop: "0",
              marginBottom: "2%",
              paddingBottom: "0",
              flexDirection: "row",
              display: "flex",
              overflowX: "auto",
            }}
          >
            <Grid item lg={2.5} xs={12}>
            <Grid
                container
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: "10%",
                  marginBottom: "10%",
                  alignItems: "center",
                }}
              >
              <Typography
                sx={{
                  fontSize: "34px",
                  fontWeight: 600,
                  marginTop: "5px",
                  color: "white",
                  textAlign: "center",
                  paddingTop: "10%",

                }}
              >
                Subscription Plan Settings
              </Typography>
              <Divider
                  orientation="vertical"
                  sx={{ height: "100%", display: { xs: "none", lg: "block" } }}
                />
              </Grid>
            </Grid>
            {subPlans?.map((item) => (
              <Grid item xs={3} lg={3}>
                <Grid
                  container
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: "10%",
                    marginBottom: "15%",
                    minHeight: { xs: "10vh" },
                  }}
                >
                  <Grid
                    items
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {isEditable ? (
                      <TextField
                        name="name"
                        sx={{
                          width: "90%",
                          marginTop: "5%",
                          backgroundColor: "#2E2E2E",
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "#808080",
                          },
                        }}
                        onChange={(e) => handleSubPlanChange(item.id, e)}
                        value={item.name}
                      />
                    ) : (
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "20px",
                          fontWeight: 600,
                          paddingTop: "10%",
                        }}
                      >
                        {" "}
                        <Checkbox
                          sx={{ color: " #808080" }}
                          checked={selectedItems.includes(item.id)}
                          onChange={() => handleItemToggle(item.id)}
                        />
                        {item.name}
                      </Typography>
                    )}
                  </Grid>
                  <Divider orientation="vertical" sx={{ height: "100%" }} />
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            {data?.map((value) => (
              <Grid
                key={value.id}
                item
                xs={12}
                sx={{
                  padding: "20px",
                  backgroundColor: "#2E2E2E",
                  borderRadius: "12px",
                  paddingTop: "0",
                  marginBottom: "2%",
                  paddingBottom: "0",
                  flexDirection: "row",
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <Grid item xs={0.5}>
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(89, 89, 89, 1)",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginTop: "50%",
                      }}
                    >
                      ID
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        marginTop: "20%",
                        color: "white",
                      }}
                    >
                      {value.id}
                    </Typography>
                    <Divider
                      orientation="vertical"
                      sx={{ height: "100%", marginLeft: "5%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      paddingRight: "10%",
                      marginBottom: "10%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        paddingTop: "10%",
                      }}
                    >
                      Description:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginTop: "5px",
                        color: "white",
                      }}
                    >
                      {value.description}
                    </Typography>
                    <Divider orientation="vertical" sx={{ height: "100%" }} />
                  </Grid>
                </Grid>
                {value.subPlan.map((item) => (
                  <Grid item xs={12} md={4}>
                    <Grid
                      container
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "10%",
                        marginBottom: "15%",
                      }}
                    >
                      <Grid
                        items
                        xs={12}
                        sx={{
                          gap: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "20px",
                            fontWeight: 600,
                            paddingTop: "10%",
                          }}
                        >
                          {" "}
                          {item.name}
                        </Typography>
                        {item.amount === -1 ? (
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: 600,
                              color: "#808080",
                            }}
                          >
                            Unlimited
                          </Typography>
                        ) : (
                          <TextField
                            label="Trial, Monthly, Annually"
                            placeholder="Enter"
                            value={item.amount}
                            onChange={(e) =>
                              handleChange(
                                data.indexOf(value),
                                value.subPlan.indexOf(item),
                                e.target.value || ""
                              )
                            }
                            sx={{
                              width: "90%",
                              marginTop: "5%",
                              backgroundColor: "#2E2E2E",
                              "& .MuiInputLabel-root": {
                                color: "rgba(128, 128, 128, 1)",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(128, 128, 128, 1)",
                              },
                              "& .MuiInputBase-root": {
                                color: "#808080",
                              },
                            }}
                          />
                        )}
                        <FormControlLabel
                          control={
                            <Switch
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  backgroundColor: "#808080", // Yuvarlak buton rengi
                                },
                              }}
                              checked={item.amount === -1}
                              onChange={() => {
                                if (item.amount === -1) {
                                  // Eğer durum şu an açıksa, kapalı yap
                                  handleChange(
                                    data.indexOf(value),
                                    value.subPlan.indexOf(item),
                                    ""
                                  );
                                } else {
                                  // Eğer durum şu an kapalıysa, açık yap
                                  handleChange(
                                    data.indexOf(value),
                                    value.subPlan.indexOf(item),
                                    -1
                                  );
                                }
                              }}
                            />
                          }
                          label={item.amount === -1 ? "" : "Unlimited ?"}
                          labelPlacement="start"
                          sx={{ color: "white", width: "fit-content" }}
                        />
                      </Grid>
                      <Divider orientation="vertical" sx={{ height: "100%" }} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
     
  );
};

export default ValueFeatures;
