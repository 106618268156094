/** @format */

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { Grid, Typography } from "@mui/material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
    },
    y: {
      grid: {
        color: "#808080",
        lineWidth: 0.3,
        
      },
    },
  },
};

const generateLabels = (select) => {
  switch (select) {
    case "month":
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    case "day":
      return Array.from({ length: 31 }, (_, i) => (i + 1).toString());
    case "week":
      return Array.from({ length: 52 }, (_, i) => ` ${i + 1}`);
    default:
      return [];
  }
};

export function Chart({ select }) {
  const [labels, setLabels] = useState(generateLabels(select));

  useEffect(() => {
    setLabels(generateLabels(select));
  }, [select]);

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 20 })),
        borderColor: "#C3B9FF",
        borderWidth: 1,
        pointBackgroundColor: "white",
        pointRadius: 4,
      },
    ],
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        height:"470px",alignItems:"center"
   
      }}
    >
      <Grid item xs={12}>
        <Line data={data} options={options} />
      </Grid>
    </Grid>
  );
}

export default Chart;
