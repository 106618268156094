import React from 'react';

function SubscriptionPlanTable(props) {
    const {data} = props;
    return (
        <table className="custom-table" style={{borderRadius:'15px'}}>
            <tr>
                <th rowSpan="1"/>
                <th rowSpan="1">Monthly Subscription</th>
                <th colSpan="2">Annual Subscription</th>
                <th colSpan="3">Two Year Subscription</th>
                <th colSpan="4">Two Year Subscription</th>
            </tr>
            <tr>
                <th/>
                <th>Billed Monthly</th>
                <th>Billed Monthly</th>
                <th>Billed Annual</th>
                <th>Billed Monthly</th>
                <th>Billed Annual</th>
                <th>Billed Two Year</th>
                <th>Billed Monthly</th>
                <th>Billed Annual</th>
                <th>Billed Three Year</th>
            </tr>
            <tbody>
                {['Full Price', 'Min Price'].map((rowName, rowIndex) => (
                    <tr key={rowName}>
                        <td>{rowName}</td>
                        <td>{data[rowName]?.['Monthly Subscription']?.['Billed Monthly']}</td>
                        <td>{data[rowName]?.['Annual Subscription']?.['Billed Monthly']}</td>
                        <td>{data[rowName]?.['Annual Subscription']?.['Billed Annual']}</td>
                        <td>{data[rowName]?.['Two Years Subscription']?.['Billed Monthly']}</td>
                        <td>{data[rowName]?.['Two Years Subscription']?.['Billed Annual']}</td>
                        <td>{data[rowName]?.['Two Years Subscription']?.['Billed Two Year']}</td>
                        <td>{data[rowName]?.['Three Years Subscription']?.['Billed Monthly']}</td>
                        <td>{data[rowName]?.['Three Years Subscription']?.['Billed Annual']}</td>
                        <td>{data[rowName]?.['Three Years Subscription']?.['Billed Three Year']}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default SubscriptionPlanTable;