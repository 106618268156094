/** @format */

import { Grid, Typography } from "@mui/material";
import React from "react";
import CardComponent from "./CardComponent";
import CustomerReportComponent from "./CustomerReportComponent";
import SearchCustomerComponent from "./SearchCustomerComponent";
import SearchResultsComponent from "./SearchResultsComponent";

/**
 *
 *
 * @param {*} { customers, selectedCustomer,setSelectedCustomer, onDeleteCustomer }
 * @return {*}
 */
const BillingReports = ({
  customers,
  selectedCustomer,
  setSelectedCustomer,
  onDeleteCustomer,
}) => {
  return (
    <>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}
      >
        <CardComponent value={"$1.6k"} text={"Total Revenue"} />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}
      >
        <CardComponent value={"$1.2k"} text={"Average Revenue Per Customer"} />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}
      >
        <CardComponent
          value={"$3.4k"}
          text={"Total Revenue per billing term"}
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}
      >
        <CardComponent
          value={"$1.9k"}
          text={"Total amount billed in advance"}
        />
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}>
        <SearchCustomerComponent
          customers={customers}
          setSelectedCustomer={setSelectedCustomer}
        />
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}>
        <SearchResultsComponent
          selectedCustomer={selectedCustomer}
          onDeleteCustomer={onDeleteCustomer}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}
      >
        <CustomerReportComponent
          value={"$2.8k"}
          text={"Total amount billed for a specific customer per billing cycle"}
          select={true}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{ justifyContent: "center", display: "flex" ,          marginTop: { xs: "3%", lg: "0" },
}}
      >
        <CustomerReportComponent
          select={false}
          value={"$4.1k"}
          text={"Annual Recurrent Revenue per selecter customer"}
        />
      </Grid>
    </>
  );
};

export default BillingReports;
