import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Typography, Grid, Divider } from "@mui/material";
import { faker } from "@faker-js/faker";

ChartJS.register(ArcElement);
const openTickets = faker.datatype.number({ min: 0, max: 100 })
const closedTickets = faker.datatype.number({ min: 0, max: 100 })
const openTicketsPercent = ((openTickets / (openTickets + closedTickets)) * 100)
const closedTicketsPercent = (closedTickets / (openTickets + closedTickets)) * 100;

export const options = {
  aspectRatio: 1,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || "";
          if (label) {
            const value = context.parsed;
            return ` ${value.toFixed(2)}%`;
          }
          return "";
        },
      },
    },
  },
};
const data = {
  labels: ["Öneri 1", "Öneri 2"],

  datasets: [
    {
      data: [openTicketsPercent, closedTicketsPercent],
      backgroundColor: ["rgba(195, 185, 255, 0.5)", "#C3B9FF"],
    },
  ],
};

export function SupportComponent() {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        height: "470px",
        alignItems: "center",
        padding: "5%",
        justifyContent: "space-between",
      }}
    >
      <Grid item xs={7}>
        <Doughnut data={data} options={options} />
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "24px", color: "white" }}>
          {openTickets}
          <Typography sx={{ fontSize: "12px", color: "#808080" }}>
            Open Tickets
          </Typography>
        </Typography>
        <Typography sx={{ fontSize: "24px", color: "white" }}>
          {closedTickets}
          <Typography sx={{ fontSize: "12px", color: "#808080" }}>
            Closed Tickets
          </Typography>
        </Typography>
      </Grid>
      <Divider width="90%" sx={{ margin: "15px",backgroundColor:"#808080" }} />
      <Grid xs={12} sx={{ paddingLeft: "10%", paddingBottom: "10%" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "white" }}>
          Open Tickets: {openTickets}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "#808080" }}>
          Tickets with a status other than closed
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SupportComponent;
