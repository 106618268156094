/** @format */

import React, { useState } from "react";
import { Grid, Typography, TextField, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {string} props.text
 * @param {string} props.date - date
 * @param {boolean} props.selectable "Do you want to select box? "true","false" "
 */

const CardComponent = ({ value, text, date, selectable }) => {
  const [selected, setSelected] = useState("choosePlan");

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        padding: "3%",
        minHeight: "25vh",
      }}
    >
      <Grid item xs={12} sx={{ flexDirection: "row", display: "flex" }}>
        <Grid item xs={12} lg={4}>
          <Typography
            sx={{
              borderRadius: "8px",
              height: "48px",
              width: "48px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#1F1F1F",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M9 16.6586V19C9 20.1046 9.89543 21 11 21C12.1046 21 13 20.1046 13 19V16.6586M11 1V2M2 11H1M4.5 4.5L3.8999 3.8999M17.5 4.5L18.1002 3.8999M21 11H20M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={3.5} sx={{ marginLeft: "auto" }}>
          <Typography
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "#1F1F1F",
              color: "white",
              fontSize: "14px",
              fontWeight: 500,
              verticalAlign: "center",
              textAlign: "center",
              padding: "4px",
            }}
          >
            {date}{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={selectable ? 4 : 12} sx={{ marginTop: "6%" }}>
        <Typography
          sx={{ fontSize: "34px", fontWeight: 600, whiteSpace: "nowrap",color:"white" }}
        >
          {value}
        </Typography>
        <Typography
          sx={{
            fontSize: "14",
            fontWeight: 500,
            width: "100%",
            marginTop: "10px",color:"#808080",
          }}
        >
          {text}
        </Typography>
      </Grid>
      {selectable ? (
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            alignItems: "flex-end",
            display: "flex",
            paddingBottom: "5%",
            marginTop: {
              xs: "5%",
            },
          }}
        >
          <TextField
            size="small"
            select
            sx={{ width: "70%", backgroundColor: "white" }}
            value={selected}
            label="Original Subscription Plan"
            onChange={(e) => setSelected(e.target.value)}
          >
            <MenuItem value="choosePlan" disabled>
              Choose Plan
            </MenuItem>
            <MenuItem value="plan1">Plan 1 </MenuItem>
            <MenuItem value="plan2">Plan 2</MenuItem>
          </TextField>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CardComponent;
