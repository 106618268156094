/** @format */

import React from "react";
import { Grid } from "@mui/material";

import MultiCardComponent from "./MultiCardComponent";
import InputCardComponent from "./InputCardComponent";
import TableComponent from "./TableComponent";
import ModalComponent from "./ModalComponent";
const NegotiationsReports = () => {
  return (
    <Grid container sx={{ padding: "1%",paddingTop:"0" }}>
      <Grid
        item
        xs={12}
        md={3}
        lg={3}
        sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}
      >
        <MultiCardComponent
          value="215"
          text="Successful negotiations per period"
          date="6 Feb - 12 Feb"
          selectIcon="tick"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
          marginTop: { xs: "3%", md: "0" },
          paddingLeft:{md:"1%",sx:"0"} 
        }}
      >
        <InputCardComponent
          value="123"
          text="Total amounts of subscriptions/conversions per subscription term"
          date="6 Feb - 12 Feb"
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={4}
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: "2%",
          paddingRight: "1%",
        }}
      >
        <MultiCardComponent
          value="128"
          text="Renewal/Subscription  pending confirmation"
          date="6 Feb - 12 Feb"
          selectIcon="file"
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={4}
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: "2%",
          paddingRight: "1%",
        }}
      >
        <MultiCardComponent
          value="137"
          text="Unsuccessful negotiations per period"
          date="6 Feb - 12 Feb"
          selectIcon="close"
        />
      </Grid>
      <Grid
        item
        xs={6}
        lg={4}
        sx={{ justifyContent: "center", display: "flex", marginTop: "2%" }}
      >
        <MultiCardComponent
          value="169"
          text="Unsuccessful negotiations pending action"
          date="6 Feb - 12 Feb"
          selectIcon="close"
        />
      </Grid>
      <Grid
        xs={12}
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingRight: "0.5%",
        }}
      >
        <TableComponent date="6 Feb - 12 Feb" />
      </Grid>
      <ModalComponent />
    </Grid>
  );
};

export default NegotiationsReports;
