/** @format */

// NegotiationsComponent.js

import React, { useState } from "react";
import {
  Grid,
  Checkbox,
  Typography,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Box,
  TableRow,
  TableContainer,
} from "@mui/material";
import Divider from "@mui/material/Divider";
const users = [
  {
    id: 1,
    date: "20/08/2023",
    NegotiationStrategy: "Strategy 1",
    paidPlanPurchased: "Yes",
  },
  {
    id: 2,
    date: "20/08/2023",
    NegotiationStrategy: "Strategy 2",
    paidPlanPurchased: "No",
  },
];

function NegotiationsComponent() {
  return (
    <Grid
      container
      sx={{
        marginBottom: "16px",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "2%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fonstSize: "20px",
              fontWeight: 600,
              margin: "1em",
            }}
          >
            Recommended Negotiation
          </Typography>
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="#808080"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                stroke="#808080"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                stroke="#808080"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Box>
        </Grid>
        <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
        <Grid
          item
          xs={12}
          sx={{
            padding: "1em",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#808080",
                fontSize: "14px",
                fontWeight: 400,
                marginBottom: "5px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                style={{ marginRight: "8px" }}
              >
                <g clip-path="url(#clip0_157_3682)">
                  <path
                    d="M7.57484 7.49984C7.77076 6.94289 8.15746 6.47326 8.66647 6.17411C9.17547 5.87497 9.77392 5.76562 10.3558 5.86543C10.9377 5.96524 11.4655 6.26777 11.8457 6.71944C12.226 7.17111 12.434 7.74277 12.4332 8.33317C12.4332 9.99984 9.93317 10.8332 9.93317 10.8332M9.99984 14.1665H10.0082M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
                    stroke="#808080"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_157_3682">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Recommended Renewal/Subscription Negotiation:
            </Typography>
            <Typography
              sx={{
                marginLeft: 5,
                fontSize: "16px",
                fontWeight: 600,
                alignItems: "center",
                display: "flex",
                gap: "10px",
                color: "white",
              }}
            >
              Negotiation 1*
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_157_3843)">
                  <path
                    d="M9.1665 3.3332H5.6665C4.26637 3.3332 3.56631 3.3332 3.03153 3.60568C2.56112 3.84536 2.17867 4.22782 1.93899 4.69822C1.6665 5.233 1.6665 5.93307 1.6665 7.3332V14.3332C1.6665 15.7333 1.6665 16.4334 1.93899 16.9682C2.17867 17.4386 2.56112 17.821 3.03153 18.0607C3.56631 18.3332 4.26637 18.3332 5.6665 18.3332H12.6665C14.0666 18.3332 14.7667 18.3332 15.3015 18.0607C15.7719 17.821 16.1543 17.4386 16.394 16.9682C16.6665 16.4334 16.6665 15.7333 16.6665 14.3332V10.8332M6.66648 13.3332H8.06193C8.46959 13.3332 8.67341 13.3332 8.86522 13.2871C9.03528 13.2463 9.19786 13.179 9.34698 13.0876C9.51517 12.9845 9.6593 12.8404 9.94755 12.5521L17.9165 4.5832C18.6069 3.89284 18.6069 2.77355 17.9165 2.0832C17.2261 1.39284 16.1069 1.39284 15.4165 2.0832L7.44753 10.0521C7.15928 10.3404 7.01515 10.4845 6.91208 10.6527C6.8207 10.8018 6.75336 10.9644 6.71253 11.1345C6.66648 11.3263 6.66648 11.5301 6.66648 11.9378V13.3332Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_157_3843">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_157_3845)">
                  <path
                    d="M6.24984 9.99984L8.74984 12.4998L13.7498 7.49984M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_157_3845">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "rgba(128, 128, 128, 1)",
                textTransform: "none",
                marginRight: "8px",
                "&:hover": {
                  backgroundColor: "#8E99AB",
                },
              }}
            >
              OfferWiz Subscriber
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "rgba(128, 128, 128, 1)",
                textTransform: "none",
                marginRight: "8px",
                "&:hover": {
                  backgroundColor: "#8E99AB",
                },
              }}
            >
              Copy link
            </Button>
          </Box>
        </Grid>
        <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            padding: "1em",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "white",
              marginBottom: "2%",
            }}
          >
            Negotiation History
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#808080",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "5px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: "8px" }}
            >
              <g clip-path="url(#clip0_157_3682)">
                <path
                  d="M7.57484 7.49984C7.77076 6.94289 8.15746 6.47326 8.66647 6.17411C9.17547 5.87497 9.77392 5.76562 10.3558 5.86543C10.9377 5.96524 11.4655 6.26777 11.8457 6.71944C12.226 7.17111 12.434 7.74277 12.4332 8.33317C12.4332 9.99984 9.93317 10.8332 9.93317 10.8332M9.99984 14.1665H10.0082M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
                  stroke="#808080"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_157_3682">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Was the customer part of negotiation using OfferWiz Subscriber?
          </Typography>
          <Typography
            sx={{
              marginLeft: 4,
              fontSize: "16px",
              fontWeight: 600,
              color: "white",
            }}
          >
            Yes*
          </Typography>
        </Grid>

        <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
        <Grid
          item
          md={12}
          sx={{
            color: "#808080",
            fonstSize: "20px",
            fontWeight: 600,
            margin: "1em",
            marginRight: 0,
            overflowX:"auto"

          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Negotiation Result:
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Negotiation Strategy
                      </Typography>
                    </TableCell>
                    <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Paid Plan purchased
                      </Typography>
                    </TableCell>

                    <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        ID
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "white",
                          }}
                        >
                          {user.date}
                        </Typography>
                      </TableCell>
                      <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "white",
                          }}
                        >
                          {user.NegotiationStrategy}
                        </Typography>
                      </TableCell>
                      <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            backgroundColor: "#1F1F1F80",
                            borderRadius: "100px",
                            padding: "10px",
                            width: "40px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            color:
                              user.paidPlanPurchased === "Yes"
                                ? " #47D647"
                                : "#D32F2F",
                          }}
                        >
                          {user.paidPlanPurchased}
                        </Typography>
                      </TableCell>

                      <TableCell   sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "white",
                          }}
                        >
                          {user.id}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Grid>
  );
}

export default NegotiationsComponent;
