/** @format */

import React from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  Autocomplete,
  Typography,
  Hidden,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

/**
 *
 *
 * @param {*} { customers, setSelectedCustomer }
 * @return {*}
 */
const SearchCustomerComponent = ({ customers, setSelectedCustomer }) => {
  return (
    <Grid
      item
      xs={11.7}
      flexDirection={"row"}
      display={"flex"}
      alignItems={"center"}
      sx={{
        borderRadius: "12px",
        backgroundColor: "#2E2E2E",
        padding: "1%",
        marginTop: "2%",
        justifyContent: "space-between",
        gap: "2%",
      }}
    >
      <Typography
        marginLeft={"3%"}
        sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
      >
        Search Customer
      </Typography>

      <Autocomplete
        freeSolo
        id="search-bar"
        sx={{ width: "70%" }}
        options={customers}
        onChange={(event, newValue) => {
          // newValue null veya undefined ise, boş bir dizi olarak kabul edin
          const selectedArray = newValue ? [newValue] : [];
          setSelectedCustomer(selectedArray);
        }}
        getOptionSelected={(option, value) =>
          option?.businessName === value?.businessName
        }
        getOptionLabel={(option) => option?.businessName || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search customer"
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
             
            }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Hidden mdDown>
                  <InputAdornment position="start">
                    <SearchIcon sx={{color:"#808080"}} />
                  </InputAdornment>
                </Hidden>
              ),
            }}
          />
        )}
      />

      <Typography
        sx={{
          backgroundColor: "#1F1F1F",
          fontWeight: 500,
          borderRadius: "4px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          color: "white",
          textTransform: "none",
          width: "125px",
          height: "44px",
          padding: "5px",
          boxShadow: "none",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          style={{ marginRight: "5%" }}
        >
          <path
            d="M2.5 4.6C2.5 4.03995 2.5 3.75992 2.60899 3.54601C2.70487 3.35785 2.85785 3.20487 3.04601 3.10899C3.25992 3 3.53995 3 4.1 3H20.9C21.4601 3 21.7401 3 21.954 3.10899C22.1422 3.20487 22.2951 3.35785 22.391 3.54601C22.5 3.75992 22.5 4.03995 22.5 4.6V5.26939C22.5 5.53819 22.5 5.67259 22.4672 5.79756C22.438 5.90831 22.3901 6.01323 22.3255 6.10776C22.2526 6.21443 22.151 6.30245 21.9479 6.4785L15.5521 12.0215C15.349 12.1975 15.2474 12.2856 15.1745 12.3922C15.1099 12.4868 15.062 12.5917 15.0328 12.7024C15 12.8274 15 12.9618 15 13.2306V18.4584C15 18.6539 15 18.7517 14.9685 18.8363C14.9406 18.911 14.8953 18.9779 14.8363 19.0315C14.7695 19.0922 14.6787 19.1285 14.4971 19.2012L11.0971 20.5612C10.7296 20.7082 10.5458 20.7817 10.3983 20.751C10.2693 20.7242 10.1561 20.6476 10.0833 20.5377C10 20.4122 10 20.2142 10 19.8184V13.2306C10 12.9618 10 12.8274 9.96715 12.7024C9.93805 12.5917 9.89014 12.4868 9.82551 12.3922C9.75258 12.2856 9.65102 12.1975 9.44789 12.0215L3.05211 6.4785C2.84898 6.30245 2.74742 6.21443 2.67449 6.10776C2.60986 6.01323 2.56195 5.90831 2.53285 5.79756C2.5 5.67259 2.5 5.53819 2.5 5.26939V4.6Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Filters
      </Typography>
    </Grid>
  );
};

export default SearchCustomerComponent;
