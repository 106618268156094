import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Container, Grid, Paper } from '@mui/material';
import { createValueFeature, getValueFeature, saveValueFeature } from '../api/value.feature';
import InfinityInput from './InfinityInput/InfinityInput';
import MessageBanner from './messageBanner';
import queryString from 'query-string';
import DataStorage from "../storage";

const NewValueFeaturePage = () => {
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const message_ = searchParams.get('message');

    const [message, setMessage] = useState(message_ || '');

    const [formData, setFormData] = useState({
        description: '',
        unit: '',
        priceToUserPerUnit: 0.0,
        unlimitedPriceToUserPerUnit: 0.0,
        resolution: 1.0,
        maxValue: 0.0,
        minValue: 0.0,
        valueToUser: '',
        typ: 'limited',
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (!DataStorage.get('token')) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (id) {
            getValueFeature(DataStorage.get('token'), id)
                .then(resp => setFormData(resp.data))
                .catch(() => setMessage('An error occurred.'));
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.description) {
            setMessage('Description must not be blank');
            return;
        }
        if (!formData.priceToUserPerUnit) {
            setMessage('Price for value feature must not be 0');
            return;
        }
        if (formData.typ=='limited' && formData.maxValue % formData.resolution != 0) {
            setMessage(`Max should be multiplier of ${formData.resolution}`);
            return;
        }
        if (formData.minValue % formData.resolution != 0) {
            setMessage(`Min should be multiplier of ${formData.resolution}`);
            return;
        }
        if (!id) {
            createValueFeature(DataStorage.get('token'), formData)
                .then(resp=>{
                    const message = 'Value feature added successfully.';
                    navigate('/value-features?' + queryString.stringify({message}));
                })
                .catch(()=>setMessage('An error occurred.'));
        }
        else {
            saveValueFeature(DataStorage.get('token'), id, formData)
                .then(resp=>{
                    const message = 'Value feature saved successfully.';
                    navigate('/value-features?' + queryString.stringify({message}));
                })
                .catch(()=>setMessage('An error occured.'));
        }
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                <Typography variant="h4" component="h2" gutterBottom>{!id && 'Create Value Feature' || 'Update Value Feature'}</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Value Feature Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Value To User"
                        name="valueToUser"
                        value={formData.valueToUser}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Button name='typ' value='limited' onClick={handleChange} disabled={formData.typ==='limited'}>Limited</Button>
                        <Button name='typ' value='unlimited' onClick={handleChange} disabled={formData.typ==='unlimited'}>Unlimited</Button>
                        <Button name='typ' value='yesno' onClick={handleChange} disabled={formData.typ==='yesno'}>Yes/No</Button>
                    </div>
                    { formData.typ !== 'yesno' &&
                        <>
                            <TextField
                                fullWidth
                                label="Multiplier"
                                name="resolution"
                                value={formData.resolution}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Unit"
                                name="unit"
                                value={formData.unit}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label={`Annual Full Price Per ${formData.unit || 'Unit'}`}
                                name="priceToUserPerUnit"
                                value={formData.priceToUserPerUnit}
                                onChange={handleChange}
                                margin="normal"
                            />
                        </>
                    }
                    { formData.typ === 'unlimited' &&
                        <TextField
                            fullWidth
                            label={`Annual Full Price for Unlimited ${formData.unit || 'Unit'} of ${formData.description}`}
                            name="unlimitedPriceToUserPerUnit"
                            value={formData.unlimitedPriceToUserPerUnit}
                            onChange={handleChange}
                            margin="normal"
                        />
                    }
                    { formData.typ === 'yesno' &&
                        <TextField
                            fullWidth
                            label={`Annual Full Price`}
                            name="priceToUserPerUnit"
                            value={formData.priceToUserPerUnit}
                            onChange={handleChange}
                            margin="normal"
                        />
                    }
                    { formData.typ === 'limited' &&
                        <TextField
                            fullWidth
                            label={`Max ${formData.unit || 'Unit'} of ${formData.description}`}
                            name="maxValue"
                            value={formData.maxValue}
                            onChange={handleChange}
                            margin="normal"
                        />
                    }
                    { formData.typ !== 'yesno' &&
                        <TextField
                            fullWidth
                            label={`Min ${formData.unit || 'Unit'} of ${formData.description}`}
                            name="minValue"
                            value={formData.minValue}
                            onChange={handleChange}
                            margin="normal"
                        />
                    }
                    <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2, marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>{!id && "Create" || "Update"}</Button>
                </form>
            </Paper>
            <MessageBanner message={message} setMessage={setMessage} />
        </Container>
    );
};

export default NewValueFeaturePage;
