/** @format */

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import video from "../../icons/video_demo.png";
import { Typography } from "@mui/material";
function Modal({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}       PaperProps={{
      sx: {
        backgroundColor: "#1F1F1F",
      },
    }}>
      <DialogTitle sx={{ marginLeft: "auto" }}>
        {" "}
        <Button onClick={handleClose} color="primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </DialogTitle>

      <DialogTitle
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "white",
          fontSize: "24px",
          fontWeight: 600,
          gap: "5px",
        }}
      >
        Instructions & help{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M8.07533 7.50033C8.27125 6.94338 8.65795 6.47375 9.16695 6.1746C9.67596 5.87546 10.2744 5.76611 10.8563 5.86592C11.4382 5.96573 11.966 6.26826 12.3462 6.71993C12.7264 7.1716 12.9345 7.74326 12.9337 8.33366C12.9337 10.0003 10.4337 10.8337 10.4337 10.8337M10.5003 14.167H10.5087M18.8337 10.0003C18.8337 14.6027 15.1027 18.3337 10.5003 18.3337C5.89795 18.3337 2.16699 14.6027 2.16699 10.0003C2.16699 5.39795 5.89795 1.66699 10.5003 1.66699C15.1027 1.66699 18.8337 5.39795 18.8337 10.0003Z"
            stroke="#808080"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </DialogTitle>
      <DialogContent sx={{ justifyContent: "center",display:"flex",flexDirection:"column" }}>
        <img
          src={video}
          alt="Açıklama metni"
          style={{ maxWidth: "100%", height: "auto" }} // İstenirse stil ekleyebilir
        />
       <Typography sx={{color:"#808080",fontWeight:400,margin:"2%"}}>
          An instruction, guideline is a legal act created by state
          administration bodies to establish rules governing organizational,
          scientific-technical, technological, financial and other special
          aspects of activities and relations of institutions, establishments,
          enterprises, and officials..
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
