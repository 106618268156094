// components/Signup.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataStorage from "../storage";

function DashboardPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const message_ = searchParams.get('message');

    const [message, setMessage] = useState(message_ || '');

    const navigate = useNavigate();

    useEffect(() => {
        if (!DataStorage.get('token')) {
            navigate('/login');
        }
    });

    return (
        <div>
        </div>
    );
};

export default DashboardPage;