/** @format */

import React, { useState, forwardRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import getSymbolFromCurrency from "@rea-app/currency-symbol-map";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import {
  listSubscriptionPlans,
  saveSubscriptionPlan,
} from "../../api/subscription.plans";
import {
  subscriptionPlanSettings,
  getSubscriptionPlanSettings,
} from "../../api/subscription.plans";
import DataStorage from "../../storage";
const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, Prefix, ...other } = props;
  const defaultPrefix = Prefix || "%"; // Use "%" as the default Prefix if not specified

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      prefix={defaultPrefix} // Use the specified Prefix or "%" as the default
      onValueChange={(values) => {
        if (typeof onChange === "function") {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  Prefix: PropTypes.string, // Define the 'Prefix' prop
};
const PricePlansTable = ({ currency }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [plans, setPlans] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [settings, setSettings] = useState(null);
  const [discountEntered, setDiscountEntered] = useState(true);

  useEffect(() => {
    getSubscriptionPlanSettings(DataStorage.get("token")).then(
      (resp) => (
        setDiscount(resp.data.D1),
        setSettings(resp.data),
        listSubscriptionPlans(DataStorage.get("token")).then((data) =>
          setPlans(data.data)
        )
      )
    );
  }, []);

  const handleChange = (e, index) => {
    const updatedPlans = [...plans]; // Create a copy of the plans array
    updatedPlans[index].FullPrice = e.target.value; // Update the FullPrice property

    setPlans(updatedPlans); // Update the state with the modified plans array
    setDiscount(0);
  };

  const handlePercent = (annual, monthly) => {
    const originalAnnual = monthly * 12; // Calculate the original annual subscription value
    const discount = ((originalAnnual - annual) / originalAnnual) * 100;
    console.log(`Discount: ${discount.toFixed(2)}%`);
    if (discount) {
      setDiscount(discount);
      setDiscountEntered(true);
    }
  };

  const handleSave = () => {
    plans.map((item) =>
      saveSubscriptionPlan(DataStorage.get("token"), item.id, item)
    );
    const updatedSettings = { ...settings };
    updatedSettings.D1 = discount;
    subscriptionPlanSettings(DataStorage.get("token"), updatedSettings);
  };

  return (
    <Grid
      container
      sx={{
        paddingTop: "5%",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        display: "flex",
      }}
    >
      <Grid
        container
        xs={12}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "2%",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            margin: "2%",
            color: "white",
          }}
        >
          Monthly Subscription prices for your plans
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_338_5980)">
              <path
                d="M7.5763 7.50033C7.77222 6.94338 8.15893 6.47375 8.66793 6.1746C9.17693 5.87546 9.77538 5.76611 10.3573 5.86592C10.9392 5.96573 11.467 6.26826 11.8472 6.71993C12.2274 7.1716 12.4355 7.74326 12.4346 8.33366C12.4346 10.0003 9.93464 10.8337 9.93464 10.8337M10.0013 14.167H10.0096M18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003C1.66797 5.39795 5.39893 1.66699 10.0013 1.66699C14.6037 1.66699 18.3346 5.39795 18.3346 10.0003Z"
                stroke="#8E99AB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_338_5980">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Typography>

        <Button
          variant="standard"
          sx={{
            textTransform: "none",
            color: "#B9D2FF",
          }}
          onClick={() =>
            !isEditable
              ? setIsEditable(true)
              : (setIsEditable(false), handleSave())
          }
        >
          {!isEditable ? "Edit" : "Save"}
        </Button>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderLeft: 0,
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                    stroke="#808080"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </TableCell>
              {settings?.noMonthly || settings == null ? null : (
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#808080",
                  }}
                >
                  Monthly Subscription
                </TableCell>
              )}

              <TableCell
                sx={{
                  textAlign: "center",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRight: 0,
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#808080",
                }}
              >
                Annual Subscription
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans?.map((item, index) => (
              <TableRow
                key={item.plan}
                sx={{
                  textAlign: "center",
                  "&:last-child td, &:last-child th": { borderBottom: 0 },
                }}
              >
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderLeft: 0,
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  {item.name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <NumericFormatCustom
                    value={item.FullPrice}
                    name="FullPrice"
                    onChange={(e) => handleChange(e, index)}
                    disabled={!isEditable}
                    Prefix={getSymbolFromCurrency(currency)}
                    style={{
                      background: "transparent",
                      textAlign: "center",
                      borderRadius: "10px",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: 400,
                      border: isEditable
                        ? "1px solid #808080"
                        : "0px solid #808080",
                    }}
                  />
                </TableCell>
                {settings?.noMonthly || settings == null ? null : (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRight: 0,
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {index == 0 ? (
                      <NumericFormatCustom
                        disabled={!isEditable}
                        value={(
                          item.FullPrice *
                          12 *
                          (1 - discount / 100)
                        ).toFixed(2)}
                        style={{
                          borderRadius: "10px",
                          background: "transparent",
                          textAlign: "center",
                          color: "white",
                          border: isEditable
                            ? "1px solid #808080"
                            : "0px solid #808080",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                        onChange={(e) =>
                          handlePercent(e.target.value, item.FullPrice)
                        }
                        Prefix={getSymbolFromCurrency(currency)}
                      />
                    ) : (
                      <Typography sx={{ color: "white" }}>
                        {parseFloat(
                          (item.FullPrice * 12 * (1 - discount / 100)).toFixed(
                            2
                          )
                        )}
                        {getSymbolFromCurrency(currency)}
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {settings?.noMonthly || settings == null ? null : (
          <TextField
            disabled={!isEditable}
            label="Enter discount Annual subscription versus Monthly subscription"
            value={discount === 0 ? "" : parseFloat(discount).toFixed(2)}
            autoComplete="off"
            sx={{
              margin: "2%",
              marginTop: "5%",
              marginBottom: "5%",
              width: "80%",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#808080",
              },
              "& .MuiInputBase-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(128, 128, 128, 1)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(128, 128, 128, 1)",
              },
            }}
            style={{ color: "red" }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue === "" || !isNaN(newValue)) {
                if (!discountEntered) {
                  setDiscount(
                    newValue === "" ? 0 : parseFloat(newValue).toFixed(2)
                  );
                }
                console.log(newValue);
                setDiscountEntered(false);
              }
            }}
            name="versusMonthly"
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
        )}
      </TableContainer>
    </Grid>
  );
};

export default PricePlansTable;
