/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { getData } from "country-list";

import { Grid, TextField, Typography, Autocomplete } from "@mui/material";
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});
const countries = getData().map((d) => ({
  value: d.code,
  label: d.name,
}));

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function RenewalsPreferenceComponent() {
  const [values, setValues] = React.useState({
    subsPeriod: "",
    trialPeriod: "",
    multiYear: "",
    highIncome: "",
    threshold: "",
    lowIncome: "",
    premium2annually: "",
    premium3annually: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "2%",
        marginBottom: "5%",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid item xs={12} sx={{ margin: "2% 0 2% 0" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            paddingLeft: "1%",
            color: "white",
          }}
        >
          {" "}
          Renewals Preference
        </Typography>
      </Grid>
      <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
        <TextField
          label="Paid Subscription Activation period (in Months)"
          value={values.subsPeriod}
          placeholder="Enter number the month"
          sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              fill: "rgba(128, 128, 128, 1)",
            },
          }}
          onChange={handleChange}
          name="subsPeriod"
          fullWidth
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
        />
      </Grid>
      <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
        <TextField
          label="Trial Period in Months (less than activation period)"
          fullWidth
          placeholder="Enter number the day"
          value={values.trialPeriod}
          sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              fill: "rgba(128, 128, 128, 1)",
            },
          }}
          onChange={handleChange}
          name="trialPeriod"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
        />
      </Grid>
      <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
        <TextField
          label="Months before the end of the annual/multi-year subscription for customer contact"
          fullWidth
          placeholder="Enter number the month"
          value={values.multiYear}
          sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              fill: "rgba(128, 128, 128, 1)",
            },
          }}
          onChange={handleChange}
          name="multiYear"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
        />
      </Grid>

      <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
        <Autocomplete
          freeSolo
          multiple
          name="highIncome"
          fullWidth
          options={countries.map((country) => country.label)}
          onChange={(event, newValue) => {
            setValues({
              ...values,
              highIncome: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="High Income Countries"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <span
                key={index}
                {...getTagProps({ index })}
                style={{
                  backgroundColor: "#1F1F1F",
                  padding: "4px 8px",
                  margin: "4px",
                  borderRadius: "100px",
                  color: "white",
                }}
              >
                {option}
              </span>
            ))
          }
        />
      </Grid>
      <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
        <TextField
          label="Revenue Threshold for Low Revenue"
          fullWidth
          placeholder="Enter number the month"
          value={values.thresHold}
          sx={{
            "& .MuiInputBase-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(128, 128, 128, 1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(128, 128, 128, 1)",
            },
            "& .MuiSelect-icon": {
              fill: "rgba(128, 128, 128, 1)",
            },
          }}
          onChange={handleChange}
          name="thresHold"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
        />
      </Grid>
      <Grid item xs={11} lg={5.7} sx={{ margin: "2% 0 2% 0" }}>
        <Autocomplete
          freeSolo
          multiple
          name="lowIncome"
          fullWidth
          options={countries.map((country) => country.label)}
          onChange={(event, newValue) => {
            setValues({
              ...values,
              lowIncome: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Low/Medium Income Countries"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiSelect-icon": {
                  fill: "rgba(128, 128, 128, 1)",
                },
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <span
                key={index}
                {...getTagProps({ index })}
                style={{
                  backgroundColor: "#1F1F1F",
                  padding: "4px 8px",
                  margin: "4px",
                  borderRadius: "100px",
                  color: "white",
                }}
              >
                {option}
              </span>
            ))
          }
        />
      </Grid>
    </Grid>
  );
}
