import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

const FinancialComponent = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        padding: "5%",
        height: "470px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <Grid item xs={12} sx={{ marginTop: "10%" }}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "20px ", fontWeight: 600 ,color:"white"}}>
            $1,999,00 MRR
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 ,color:"#808080"}}>
            10% since 30 days ago
          </Typography>
        </Grid>
        <Divider width="100%" sx={{ marginTop: "5%", marginBottom: "5%",backgroundColor:"#808080" }} />
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "20px ", fontWeight: 600 ,color:"white"}}>
          $14,388,00 ARR
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 ,color:"#808080"}}>
            10% since 30 days ago
          </Typography>
        </Grid>
        <Divider width="100%" sx={{ marginTop: "5%", marginBottom: "5%",backgroundColor:"#808080" }} />
      </Grid>
      <Grid item xs={12}    >
        <Typography
          sx={{
            fontSize: "20px ",
            fontWeight: 600,
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
            color:"white"
          }}
        >
          Renewal Date:{" "}
          <Typography sx={{ fontSize: "12px", fontWeight: 400,color:"#808080" }}>
            Jan 14, 2023 7:00 PM
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FinancialComponent;
