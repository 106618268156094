import axios from 'axios';

export function createSubscriptionPlan(token, data) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plan/create/`, data, config);
}

export function subscriptionPlanSettings(token, data) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.put(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plans/settings/`, data, config);
}

export function getSubscriptionPlanSettings(token) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plans/settings`, config);
}
export function listSubscriptionPlans(token) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plan/`, config);
}

export function listSubscriptionModels(token) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-model/`, config);
}

export function getSubscriptionPlan(token, id) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plan/${id}/`, config);
}

export function saveSubscriptionPlan(token, id, data) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.put(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plan/${id}/`, data, config);
}

export function deleteSubscriptionPlan(token, id) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.delete(`${process.env.REACT_APP_API_URL}/api/negotiation/subscription-plan/${id}/`, config);
}