import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Checkbox, Button, FormGroup, FormControlLabel, Box, Select, MenuItem } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { deleteExperiment, getExperiment, updateExperiment } from '../../../api/experiment';
import { listSubscriptionPlans } from '../../../api/subscription.plans';
import ArmSetsPage from '../../ArmSet/ArmSetsPage/arm.sets.page';
import DataStorage from "../../../storage";

function ShowExperimentPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get('message');

  const [message, setMessage] = useState(message_ || '');

  const { id } = useParams();
  const navigate = useNavigate();

  const [experiment, setExperiment] = useState(null);
  const [name, setName] = useState('');
  const [active, setActive] = useState(false);
  const [filterMonthlySubscriptionCustomers, setFilterMonthlySubscriptionCustomers] = useState(false);
  const [filterTrialPlanCustomers, setFilterTrialPlanCustomers] = useState(false);
  const [filterLowIncomeCountryCustomers, setFilterLowIncomeCountryCustomers] = useState(false);
  const [filterHighIncomeCountryCustomers, setFilterHighIncomeCountryCustomers] = useState(false);
  const [forStrategy1, setForStrategy1] = useState(false);
  const [forStrategy2, setForStrategy2] = useState(false);
  const [forStrategy3, setForStrategy3] = useState(false);
  const [forStrategy4, setForStrategy4] = useState(false);
  const [subPlans, setSubPlans] = useState([]);
  const [selectedSubPlan, setSelectedSubPlan] = useState('');

  useEffect(() => {
    if (!DataStorage.get('token')) {
      navigate('/login');
    } else {
      fetchExperiment();
      fetchSubscriptionPlans();
    }
  }, []);

  const fetchExperiment = async () => {
    try {
      const response = await getExperiment(DataStorage.get('token'), id);
      setExperiment(response.data);
      setName(response.data.name);
      setActive(response.data.active);
      setFilterMonthlySubscriptionCustomers(response.data.filterMonthlySubscriptionCustomers);
      setFilterTrialPlanCustomers(response.data.filterTrialPlanCustomers);
      setFilterLowIncomeCountryCustomers(response.data.filterLowIncomeCountryCustomers);
      setFilterHighIncomeCountryCustomers(response.data.filterHighIncomeCountryCustomers);
      setForStrategy1(response.data.forStrategy1);
      setForStrategy2(response.data.forStrategy2);
      setForStrategy3(response.data.forStrategy3);
      setForStrategy4(response.data.forStrategy4);
      setSelectedSubPlan(response.data.subPlan);
    } catch (error) {
      setMessage("An error occured.");
    }
  };

  const fetchSubscriptionPlans = async () => {
    try {
      const response = await listSubscriptionPlans(DataStorage.get('token'));
      setSubPlans(response.data);
    } catch (error) {
      setMessage("An error occured.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      ...experiment,
      name,
      active,
      filterMonthlySubscriptionCustomers,
      filterTrialPlanCustomers,
      filterLowIncomeCountryCustomers,
      filterHighIncomeCountryCustomers,
      forStrategy1,
      forStrategy2,
      forStrategy3,
      forStrategy4,
      subPlan: selectedSubPlan,
    };

    try {
      await updateExperiment(DataStorage.get('token'), id, formData);
      navigate(`/show-experiment/${id}`);
    } catch (error) {
      console.error('Error updating experiment:', error);
    }
  };

  const handleDelete = () => {
    deleteExperiment(DataStorage.get('token'), id)
        .then(()=>navigate(`/experiments`))
        .catch(()=>setMessage('An error occurred.'));
  };

  if (!experiment || subPlans.length === 0) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container component="main" sx={{ width: '100%' }} style={{display:'flex', flexDirection:'row'}}>
      <Container sx={{ width: '100%' }}>
        <Typography component="h1" variant="h5">
          Edit Experiment
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={active} onChange={() => setActive(!active)} />}
              label="Active"
            />
            <Box mt={2} />
            <FormControlLabel
              control={<Checkbox checked={filterMonthlySubscriptionCustomers} onChange={() => setFilterMonthlySubscriptionCustomers(!filterMonthlySubscriptionCustomers && !filterTrialPlanCustomers)} />}
              label="Apply to only monthly subscription customers"
            />
            <FormControlLabel
              control={<Checkbox checked={filterTrialPlanCustomers} onChange={() => setFilterTrialPlanCustomers(!filterTrialPlanCustomers && !filterMonthlySubscriptionCustomers)} />}
              label="Apply to only trial plan customers"
            />
            <Box mt={2} />
            <FormControlLabel
              control={<Checkbox checked={filterLowIncomeCountryCustomers} onChange={() => setFilterLowIncomeCountryCustomers(!filterLowIncomeCountryCustomers && !filterHighIncomeCountryCustomers)} />}
              label="Apply to only low-income country customers"
            />
            <FormControlLabel
              control={<Checkbox checked={filterHighIncomeCountryCustomers} onChange={() => setFilterHighIncomeCountryCustomers(!filterHighIncomeCountryCustomers && !filterLowIncomeCountryCustomers)} />}
              label="Apply to only high-income country customers"
            />
          </FormGroup>
          <Box mt={2} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={forStrategy1} onChange={() => setForStrategy1(!forStrategy1)} />}
              label="For Subscribe x 1-Bootstrapper(-) strategy"
            />
            <FormControlLabel
              control={<Checkbox checked={forStrategy2} onChange={() => setForStrategy2(!forStrategy2)} />}
              label="For Subscribe x 1-Bootstrapper strategy"
            />
            <FormControlLabel
              control={<Checkbox checked={forStrategy3} onChange={() => setForStrategy3(!forStrategy3)} />}
              label="For Subscribe x 1-Bootstrapper(+) strategy"
            />
            <FormControlLabel
              control={<Checkbox checked={forStrategy4} onChange={() => setForStrategy4(!forStrategy4)} />}
              label="For Subscribe x 1 strategy"
            />
          </FormGroup>
          <Box mt={2} />
          <FormGroup>
            <label>Subscription Plan:</label>
            <Select
              value={selectedSubPlan}
              onChange={(e) => setSelectedSubPlan(e.target.value)}
              fullWidth
            >
              {subPlans.map((subPlan) => (
                <MenuItem key={subPlan.id} value={subPlan.id}>
                  {subPlan.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <Box mt={2} />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Update Experiment
          </Button>
          <Box mt={1} />
          <Button fullWidth variant="contained" color="error" onClick={()=>handleDelete()}>
            Delete Experiment
          </Button>
        </form>
      </Container>
      <Container sx={{ width: '100%' }}>
        <ArmSetsPage
          armSets={experiment.arm_sets}
          experimentId={experiment.id}
          setMessage={setMessage}
        />
      </Container>
    </Container>
  );
}

export default ShowExperimentPage;
