import React, { useState, useEffect } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box, Pagination, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createArmSet, listArmSets } from '../../../api/arm.sets';
import DataStorage from "../../../storage";

function ArmSetsPage({armSets, experimentId, setMessage}) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of arm sets per page
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get('token')) {
        navigate('/login');
    }
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleNewArmSet = () => {
    createArmSet(DataStorage.get('token'), experimentId)
        .then(resp => navigate(`/show-arm-set/${resp.data.id}`))
        .catch(()=>setMessage('An error occurred.'));
  };

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentArmSets = armSets.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Container component="main" maxWidth="md">
      <div>
        <Typography component="h1" variant="h5">
          Arm Sets
        </Typography>
        {armSets.length === 0 ? (
          <Typography variant="body1">
            There are no arm sets available for this experiment.
          </Typography>
        ) : (
          <>
            <List>
              {currentArmSets.map((armSet) => (
                <Link
                  key={armSet.id}
                  className="armset-link"
                  onClick={() => navigate(`/show-arm-set/${armSet.id}`)}
                >
                  <ListItemText primary={armSet.name} />
                </Link>
              ))}
            </List>
            {armSets.length > itemsPerPage && (
              <Box mt={2}>
                <Pagination
                  count={Math.ceil(armSets.length / itemsPerPage)}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            )}
          </>
        )}
        <Box mt={2} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewArmSet}
        >
          New Arm Set
        </Button>
      </div>
    </Container>
  );
}

export default ArmSetsPage;
