/** @format */

import React, { useState } from "react";
import { Grid } from "@mui/material";
import CardComponent from "./CardComponent";
import SearchComponent from "./SearchComponent";
import ModalComponent from "./ModalComponent";

const ExperimentsReports = () => {
  const [modal, setModal] = useState(false);
  const experiments = [
    {
      id: 1,
      experimentName: "Experiment Name 1",
      freePlan: "$840",
      trialCustomer: "Tom Adams",
      targetGroup: "High Income, Negotiation 1",
      total: "134",
      status: "completed",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 2,
      experimentName: "Experiment Name 2",
      freePlan: "$430",
      trialCustomer: "John Doe",
      targetGroup: "High Income, Negotiation 1",
      total: "134",
      status: "ongoing",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 3,
      experimentName: "Experiment Name 3",
      freePlan: "$600",
      trialCustomer: "Alice Smith",
      targetGroup: "Low Income, Negotiation 2",
      total: "123",
      status: "completed",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 4,
      experimentName: "Experiment Name 4",
      freePlan: "$720",
      trialCustomer: "Bob Johnson",
      targetGroup: "Medium Income, Negotiation 3",
      total: "156",
      status: "ongoing",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 5,
      experimentName: "Experiment Name 5",
      freePlan: "$500",
      trialCustomer: "Emma Davis",
      targetGroup: "High Income, Negotiation 1",
      total: "145",
      status: "completed",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 6,
      experimentName: "Experiment Name 6",
      freePlan: "$920",
      trialCustomer: "Michael Wilson",
      targetGroup: "Low Income, Negotiation 2",
      total: "132",
      status: "ongoing",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 7,
      experimentName: "Experiment Name 7",
      freePlan: "$750",
      trialCustomer: "Olivia Anderson",
      targetGroup: "Medium Income, Negotiation 3",
      total: "163",
      status: "completed",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 8,
      experimentName: "Experiment Name 8",
      freePlan: "$820",
      trialCustomer: "James Brown",
      targetGroup: "High Income, Negotiation 1",
      total: "128",
      status: "ongoing",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 9,
      experimentName: "Experiment Name 9",
      freePlan: "$690",
      trialCustomer: "Sophia Lee",
      targetGroup: "Low Income, Negotiation 2",
      total: "152",
      status: "completed",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
    {
      id: 10,
      experimentName: "Experiment Name 10",
      freePlan: "$550",
      trialCustomer: "William Moore",
      targetGroup: "Medium Income, Negotiation 3",
      total: "138",
      status: "ongoing",
      paidCustomers: "Some Text",
      planUpgrades: "Some Text",
      purchase: "Some Text",
    },
  ];

  return (
    <Grid container sx={{ padding: "1%",paddingTop:"0" }}>
      <Grid item xs={12} md={6} sx={{ marginTop: { xs: "3%", lg: "0" } }}>
        <CardComponent
          value="215"
          text="Ongoing Experiments"
          date="6 Feb - 12 Feb"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ marginTop: { xs: "3%", lg: "0" }, paddingLeft: "1%" }}
      >
        <CardComponent
          value="128"
          text="Completed experiments"
          date="6 Feb - 12 Feb"
          selectable={true}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: { xs: "3%", lg: "0" },
        }}
      >
        <SearchComponent experiments={experiments} setModal={setModal} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: { xs: "3%", lg: "0" },
        }}
      >
        <ModalComponent
          experiments={experiments}
          open={modal}
          setModal={setModal}
        />
      </Grid>
    </Grid>
  );
};

export default ExperimentsReports;
