import axios from 'axios';

export function createValueFeature(token, data) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/api/negotiation/value-feature/create/`, data, config);
}

export function listValueFeatures(token) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/value-feature/`, config);
}

export function getValueFeature(token, id) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/value-feature/${id}/`, config);
}

export function saveValueFeature(token, id, data) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.put(`${process.env.REACT_APP_API_URL}/api/negotiation/value-feature/${id}/`, data, config);
}

export function deleteValueFeature(token, id) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.delete(`${process.env.REACT_APP_API_URL}/api/negotiation/value-feature/${id}/`, config);
}

export function getSubPlanValFeat(token) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.get(`${process.env.REACT_APP_API_URL}/api/negotiation/sub-plan-val-feat/`, config);
}

export function saveSubPlanValFeat(token, data) {
    const config = {
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/api/negotiation/sub-plan-val-feat/`, data, config);
}