/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Divider,
  Typography,
  Stack,
  Popover,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { listValueFeatures } from "../../api/value.feature";
import { deleteValueFeature, saveValueFeature } from "../../api/value.feature";
import MessageBanner from "../../components/messageBanner";
import DataStorage from "../../storage";
import Modal from "../../components/InstrunctionsModalComponent/InstrunctionsModalComponent";

function ValueFeaturesPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");
  const [message, setMessage] = useState(message_ || "");
  const [filteredValFeats, setFilteredValFeats] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  useEffect(() => {
    if (!DataStorage.get("token")) {
      navigate("/login");
    } else {
      listValueFeatures(DataStorage.get("token"))
        .then((resp) => {
          setFilteredValFeats(resp.data);
        })
        .catch(() => setMessage("An error occurred."));
    }
  }, []);

  const handleDelete = async () => {
    try {
      for (const item of selectedFeatures) {
        await deleteValueFeature(DataStorage.get("token"), item);
      }

      const resp = await listValueFeatures(DataStorage.get("token"));
      setFilteredValFeats(resp.data);
    } catch (error) {
      setMessage("An error occurred.");
    } finally {
      setSelectedFeatures([]);
    }
  };
  const handleSave = () => {
    filteredValFeats.map((item) =>
      saveValueFeature(DataStorage.get("token"), item.id, item)
    );
  };

  const handleChange = (e, index) => {
    setFilteredValFeats((prevFilteredValFeats) => {
      const updatedFilteredValFeats = [...prevFilteredValFeats];
      updatedFilteredValFeats[index] = {
        ...updatedFilteredValFeats[index],
        [e.target.name]: e.target.value,
      };
      return updatedFilteredValFeats;
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const handleCheckboxChange = (userId) => {
    if (selectedFeatures.includes(userId)) {
      setSelectedFeatures(selectedFeatures.filter((id) => id !== userId));
    } else {
      setSelectedFeatures([...selectedFeatures, userId]);
    }
  };

  const typographyRef = useRef(null);
  const typographyRef2 = useRef(null);
  const [typographyHeight, setTypographyHeight] = useState(null);
  const [typographyHeight2, setTypographyHeight2] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const typographyElement = typographyRef.current;

      if (typographyElement) {
        const height = typographyElement.clientHeight;
        setTypographyHeight(height);
      }
      const typographyElement2 = typographyRef2.current;

      if (typographyElement2) {
        const height2 = typographyElement2.clientHeight;
        setTypographyHeight2(height2);
      }
    };

    // Olay dinleyicisini ekleyin
    window.addEventListener("resize", handleResize);

    // useEffect sona erdiğinde olay dinleyicisini kaldırın
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        backgroundColor: "#171717",
        alignContent: "flex-start",
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: "#2E2E2E",
              alignItems: "center",
              borderRadius: "100px",
              marginTop: "1%",
              marginRight: "1%",
              padding: 1,
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <circle cx="17" cy="17" r="17" fill="#8E99AB" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                x="8"
                y="8"
              >
                <path
                  d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </svg>

            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "16px",
                marginTop: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, display: "inline-flex", gap: "5px" }}
              >
                Good Morning, <Typography> Mounir Ouhadi</Typography>
              </Typography>
              <TextField
                label="Search profile options"
                variant="outlined"
                value={searchText}
                autoComplete="off"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //  handleSearch();
                  }
                }}
                style={{
                  borderRadius: "5px",
                  width: "90%",
                  borderWidth: "1px",
                  borderColor: "#BFC0C2",
                }}
                InputProps={{
                  sx: {
                    color: "#C3C8CF",
                    fontWeight: "600",
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#BFC0C2",
                      },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton /*onClick={handleSearch}*/>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                    "&.Mui-focused": { color: "#00000099" },
                  },
                }}
              />
              <Divider sx={{ width: "90%" }} />
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/reset-password")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M14.1667 7.49995C14.1667 7.07343 14.0039 6.64691 13.6785 6.32149C13.3531 5.99605 12.9265 5.83333 12.5 5.83333M12.5 12.5C15.2614 12.5 17.5 10.2614 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5C9.73858 2.5 7.5 4.73858 7.5 7.5C7.5 7.72807 7.51527 7.95256 7.54484 8.17253C7.59348 8.53432 7.6178 8.71521 7.60143 8.82966C7.58438 8.94888 7.56267 9.01312 7.50391 9.11825C7.4475 9.21917 7.34809 9.31857 7.14928 9.51739L2.89052 13.7761C2.7464 13.9203 2.67433 13.9923 2.6228 14.0764C2.57711 14.151 2.54344 14.2323 2.52303 14.3173C2.5 14.4132 2.5 14.5151 2.5 14.719V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H5.83333V15.8333H7.5V14.1667H9.16667L10.4826 12.8507C10.6814 12.6519 10.7808 12.5525 10.8818 12.4961C10.9869 12.4373 11.0511 12.4156 11.1703 12.3986C11.2848 12.3822 11.4657 12.4065 11.8275 12.4552C12.0474 12.4847 12.2719 12.5 12.5 12.5Z"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Change Password
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "rgba(128, 128, 128, 1)",
                  justifyContent: "flex-start",
                }}
                onClick={() => navigate("/logout")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ marginRight: "3%" }}
                >
                  <path
                    d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                    stroke="rgba(128, 128, 128, 1)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Logout
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          margin: "1%",
          marginBottom: "0px",
          backgroundColor: "#1F1F1F",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          minHeight: "100vh",
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ padding: "2%", paddingLeft: "0" }}>
            <Grid container xs={12} sx={{ width: "100%", alignItems: "center" }}>
              <Grid item xs={12} lg={7} sx={{ paddingLeft: "2%" }}>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "600", color: "white" }}
                >
                  Value Feature Economics
                </Typography>
              </Grid>

              <Grid
                item
                lg={2}
                xs={4}
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                  marginLeft: "auto",
                  paddingRight: "2%",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#B9D2FF",
                    textDecoration: "underline ",
                    "&.MuiTypography-root": {
                      userSelect: "none",
                    },
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  Instructions & help
                </Typography>
              </Grid>
              <Grid item lg={1} xs={3}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#B9D2FF",
                    textTransform: "none",
                    width: "100%",
                    color: "black",
                    height: "100%",
                    "&:hover": {
                      backgroundColor: "#8E99AB",
                    },
                  }}
                  onClick={() => navigate("/new-value-feature")}
                >
                  Add New
                </Button>
              </Grid>
              <Grid item lg={1} xs={3}>
                <Button
                  onClick={() =>
                    !isEditable
                      ? setIsEditable(!isEditable)
                      : (handleSave(), setIsEditable(!isEditable))
                  }
                  variant="standard"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    height: "100%",
                    color: "#B9D2FF",
                    "&:disabled": {
                      color: "#808080",
                    },
                  }}
                >
                  {!isEditable ? "  Edit" : "Save"}
                </Button>
              </Grid>
              <Grid item lg={1} xs={3}>
                <Button
                  disabled={Object.keys(selectedFeatures).length === 0}
                  variant="standard"
                  onClick={() => handleDelete()}
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    height: "100%",
                    color: "#B9D2FF",
                    "&:disabled": {
                      color: "#808080",
                    },
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Divider
            sx={{
              width: "100%",
              marginBottom: "2%",
              backgroundColor: "#808080",
            }}
          />
          {filteredValFeats.map((valfeat, index) => (
            <Grid item xs={12} sx={{ padding: "1%" }}>
              <Grid
                container
                sx={{
                  padding: "20px",
                  backgroundColor: "#2E2E2E",
                  borderRadius: "12px",
                }}
              >
                <Grid
                  lg={0.5}
                  xs={2}
                  sx={{
                    alignItem: "center",
                    display: "flex",
                    paddingRight: "1%",
                    marginBottom: { xs: "2%" },
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#808080",
                      "&.Mui-checked": {
                        color: "#B9D2FF",
                      },
                    }}
                    checked={selectedFeatures.includes(valfeat.id)}
                    onClick={() => handleCheckboxChange(valfeat.id)}
                  />
                </Grid>

                <Grid item lg={2} xs={10} sx={{ marginBottom: { xs: "5%" } }}>
                  <Typography
                    sx={{
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: 400,
                      height: { lg: `${typographyHeight}px ` },
                    }}
                  >
                    Description:
                  </Typography>
                  {!isEditable ? (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginTop: { lg: "5px" },
                        color: "white",
                        wordWrap: "break-word",
                        paddingRight: "1%",
                      }}
                    >
                      {valfeat.description}
                    </Typography>
                  ) : (
                    <TextField
                      sx={{
                        width: "90%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(128, 128, 128, 1)",
                        },
                        "& .MuiInputBase-root": {
                          color: "white",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(128, 128, 128, 1)",
                        },
                      }}
                      placeholder="Enter"
                      name="description"
                      value={valfeat.description}
                      onChange={(e) => handleChange(e, index)}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Grid>

                <Grid container lg={7} xs={12}>
                  <Grid item xs={6} lg={5}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: `${typographyHeight}px `,
                      }}
                    >
                      Full Annual billed price to customer:
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.priceToUserPerUnit}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="priceToUserPerUnit"
                        value={valfeat.priceToUserPerUnit}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} lg={5}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      ref={typographyRef}
                    >
                      Full Annual billed price to customer for Unlimited:
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.unlimitedPriceToUserPerUnit}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="unlimitedPriceToUserPerUnit"
                        value={valfeat.unlimitedPriceToUserPerUnit}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    lg={2}
                    sx={{ marginTop: { xs: "2%", lg: "0" } }}
                  >
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: {
                          xs: `${typographyHeight2}px `,
                          lg: `${typographyHeight}px`,
                        },
                      }}
                    >
                      Unit
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.unit}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="unit"
                        value={valfeat.unit}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3} sx={{ marginTop: "2%" }}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: `${typographyHeight2}px`,
                      }}
                    >
                      Multiplier:
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.resolution}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="resolution"
                        value={valfeat.resolution}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={2} sx={{ marginTop: "2%" }}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: `${typographyHeight2}px`,
                      }}
                    >
                      Max Value:
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.maxValue}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="maxValue"
                        value={valfeat.maxValue}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} sx={{ marginTop: "2%" }}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: `${typographyHeight2}px`,
                      }}
                    >
                      Min Value:
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.minValue}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="minValue"
                        value={valfeat.minValue}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ marginTop: "2%" }}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      ref={typographyRef2}
                    >
                      Volume discount Threshold (Units):
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.discountThresholdForHighAmount}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="discountThresholdForHighAmount"
                        value={valfeat.discountThresholdForHighAmount}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3} sx={{ marginTop: "2%" }}>
                    <Typography
                      sx={{
                        color: "#808080",
                        fontSize: "14px",
                        fontWeight: 400,
                        height: `${typographyHeight2}px`,
                      }}
                    >
                      Volume discount:
                    </Typography>

                    {!isEditable ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginTop: "5px",
                          color: "white",
                        }}
                      >
                        {valfeat.discountForHighAmount}
                      </Typography>
                    ) : (
                      <TextField
                        sx={{
                          width: "90%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(128, 128, 128, 1)",
                          },
                          "& .MuiInputBase-root": {
                            color: "white",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(128, 128, 128, 1)",
                          },
                        }}
                        placeholder="Enter"
                        name="discountForHighAmount"
                        value={valfeat.discountForHighAmount}
                        onChange={(e) => handleChange(e, index)}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                </Grid>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    backgroundColor: "#808080",
                    display: { xs: "none", lg: "block" },
                  }}
                />
                <Grid item lg={2.4} xs={8} sx={{ paddingLeft: "2%" }}>
                  <Typography
                    sx={{
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginTop: { xs: "5%" },
                    }}
                  >
                    Value to Customer:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginTop: "5px",
                      color: "white",
                      wordWrap: "break-word",
                    }}
                  >
                    {valfeat.valueToUser}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12}>
            <MessageBanner message={message} setMessage={setMessage} />
          </Grid>
          <Modal open={openModal} handleClose={handleCloseModal} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ValueFeaturesPage;
