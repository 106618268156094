import axios from 'axios';

export function signup(companyName, email, password) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signup/`, {
        name: companyName,
        email,
        password,
    });
}

export function login(email, password) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login/`, {
        email,
        password,
    });
}

export function resetPasswordRequest(email) {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/auth/reset-password-request/`, {
        params: {
            email,
        }
    });
}

export function resetPassword(token, password) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/auth/reset-password/`, {
        token,
        password,
    });
}

export function googleLogin(code) {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/auth/google/login/`, {
        params: {
            code,
        }
    });
}