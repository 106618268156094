/** @format */

import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Collapse,
  Divider,
} from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import {
  listSubscriptionPlans,
  saveSubscriptionPlan,
} from "../../api/subscription.plans";
import DataStorage from "../../storage";

const Component = () => {
  const [allOpen, setAllOpen] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(new Array(data.length).fill(false));

  useEffect(() => {
    listSubscriptionPlans(DataStorage.get("token")).then(
      (resp) => (
        setData(resp.data),
        console.log(
          resp.data.map(
            (item) => item.table["Full Price"]["Annual Subscription"]
          )
        )
      )
    );
  }, []);

  const handleToggle = (index) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };

  const handleAllToggle = () => {
    setAllOpen(!allOpen);
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            marginBottom: "1%",
            backgroundColor: "#2E2E2E",
            borderRadius: "12px",
            padding: "2%",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontWeight: 600, color: "white" }}
          >
            All Plans: {data.length}
          </Typography>
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "white",
              textTransform: "none",
              backgroundColor: "rgba(185, 210, 255, 0.05)",
              "&:hover": {
                backgroundColor: "#8E99AB",
              },
            }}
            variant="contained"
            onClick={() => handleAllToggle()}
            endIcon={
              allOpen ? (
                <KeyboardDoubleArrowUpIcon />
              ) : (
                <KeyboardDoubleArrowDownIcon />
              )
            }
            disableElevation
          >
            {allOpen ? "Collapse All" : "Expand All"}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {" "}
        <Collapse in={allOpen} sx={{ overflowX: "auto" }}>
          {data?.map((plan, index) => (
            <Grid item xs={12} key={index}>
              <Grid
                container
                justifyContent="space-between"
                sx={{ marginBottom: "1%" }}
              >
                <Typography
                  sx={{ fontSize: "24px", fontWeight: 600, color: "white" }}
                >
                  {" "}
                  ID: {plan.id}
                </Typography>
                <Button
                  sx={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: "#808080",
                    textTransform: "none",
                  }}
                  onClick={() => handleToggle(index)}
                  endIcon={
                    open[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }
                >
                  {open[index] ? "Collapse" : "Expand "}
                </Button>
              </Grid>
              <Grid
                container
                sx={{
                  backgroundColor: "#2E2E2E",
                  borderRadius: "12px",
                }}
              >
                <Grid item xs={12}>
                  <Collapse in={open[index]}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ border: "0px  " }}></TableCell>
                            {plan.table["Full Price"][
                              "Monthly Subscription"
                            ] ? (
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "1px solid rgba(0, 0, 0, 0.1)",
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  borderTop: 0,
                                  color: "white",
                                }}
                              >
                                Monthly Subscription
                              </TableCell>
                            ) : null}
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                fontSize: "20px",
                                fontWeight: 600,
                                borderTop: 0,
                                color: "white",
                              }}
                            >
                              Annual Subscription
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                fontSize: "20px",
                                fontWeight: 600,
                                borderTop: 0,
                                color: "white",
                              }}
                            >
                              Two Years Subscription
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                fontSize: "20px",
                                fontWeight: 600,
                                borderTop: 0,
                                color: "white",
                              }}
                            >
                              Three Years Subscription
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontSize: "34px",
                                fontWeight: 600,
                                marginLeft: "auto",
                                marginRight: "auto",
                                border: "0px",
                                color: "white",
                              }}
                            >
                              {plan.name}
                            </TableCell>
                            {plan.table["Full Price"][
                              "Monthly Subscription"
                            ] ? (
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(0, 0, 0, 0.1)",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  borderBottom: 0,
                                  verticalAlign: "top",
                                }}
                              >
                                {Object.entries(
                                  plan.table["Full Price"][
                                    "Monthly Subscription"
                                  ]
                                ).map(([key, value]) => (
                                  <div
                                    key={key}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",

                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        color: "#FFFFFF",
                                      }}
                                    >
                                      {key}
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                          alignSelf: "flex-start",
                                          display: "flex",
                                          color: "#B9D2FF",
                                        }}
                                      >
                                        {value}
                                      </Typography>
                                    </Typography>
                                  </div>
                                ))}
                              </TableCell>
                            ) : null}

                            <TableCell
                              sx={{
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                fontSize: "16px",
                                fontWeight: 600,
                                borderBottom: 0,
                                verticalAlign: "top",
                              }}
                            >
                              {plan.table["Full Price"]["Annual Subscription"]
                                ? Object.entries(
                                    plan.table["Full Price"][
                                      "Annual Subscription"
                                    ]
                                  ).map(([key, value]) => (
                                    <div
                                      key={key}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          color: "#FFFFFF",
                                          textAlign: "center",
                                        }}
                                      >
                                        {key}
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#B9D2FF",
                                          }}
                                        >
                                          {value}
                                        </Typography>
                                      </Typography>
                                    </div>
                                  ))
                                : null}
                            </TableCell>

                            <TableCell
                              sx={{
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                fontSize: "16px",
                                fontWeight: 600,
                                borderBottom: 0,
                                verticalAlign: "top",
                              }}
                            >
                              {plan.table["Full Price"][
                                "Two Years Subscription"
                              ]
                                ? Object.entries(
                                    plan.table["Full Price"][
                                      "Two Years Subscription"
                                    ]
                                  ).map(([key, value]) => (
                                    <div
                                      key={key}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          color: "#FFFFFF",
                                          textAlign: "center",
                                        }}
                                      >
                                        {key}
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#B9D2FF",
                                          }}
                                        >
                                          {value}
                                        </Typography>
                                      </Typography>
                                    </div>
                                  ))
                                : null}
                            </TableCell>

                            <TableCell
                              sx={{
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                fontSize: "16px",
                                fontWeight: 600,
                                borderBottom: 0,
                                verticalAlign: "top",
                              }}
                            >
                              {plan.table["Full Price"][
                                "Three Years Subscription"
                              ]
                                ? Object.entries(
                                    plan.table["Full Price"][
                                      "Three Years Subscription"
                                    ]
                                  ).map(([key, value]) => (
                                    <div
                                      key={key}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "8px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          color: "#FFFFFF",
                                          textAlign: "center",
                                        }}
                                      >
                                        {key}
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#B9D2FF",
                                          }}
                                        >
                                          {value}
                                        </Typography>
                                      </Typography>
                                    </div>
                                  ))
                                : null}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  width: "100%",
                  marginBottom: "1%",
                  marginTop: "1%",
                  backgroundColor: "#808080",
                }}
              />
            </Grid>
          ))}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default Component;
