/** @format */

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Routes from react-router-dom
import SignupPage from "./screens/signUpPage/signupPage";
import LoginPage from "./screens/loginPage/loginPage";
import LogoutPage from "./components/logout.page";
import DashboardPage from "./components/dashboard.page";
import ResetPasswordRequestPage from "./components/reset.password.request.page";
import ResetPasswordPage from "./components/reset.password.page";
import CustomersPage from "./screens/customersPage/customersPage";
import NewCustomerPage from "./screens/AddCustomerPage/AddNewCustomerPage";
import ShowCustomerPage from "./screens/showCustomerPage/showCustomerPage";
import SubscriptionPlansPage from "./components/subscription.plans.page";
import NewSubscriptionPlanPage from "./components/new.subscription.plan.page";
import ShowSubscriptionPlanPage from "./components/show.subscription.plan.page";
import ValueFeaturesPage from "./screens/ValueFeaturesPage/ValueFeaturesPage";
import NewValueFeaturePage from "./components/value.feature.page";
import SubscriptionPackageBuilderPage from "./screens/SubscriptionPackageBuilder/SubscriptionPackageBuilderPage";
import OfferPage from "./screens/SubscriberPage/offer.page";
import LandingPage from "./screens/landingPage/LandingPage";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import "./stylesguide.css";
import NewExperimentPage from "./screens/Experiment/CreateExperimentPage/new.experiment.page";
import ExperimentsPage from "./screens/ExperimentsPage/ExperimentsPage";
import ShowExperimentPage from "./screens/Experiment/ShowExperimentPage/show.experiment.page";
import ShowArmSetPage from "./screens/ArmSet/ShowArmSetPage/show.arm.set.page";
import SubscriptionPlanSettings from "./screens/SubscriptionPlanSettings/subscriptionPlanSettings";
import CustomerHealth from "./screens/CustomerHeath/CustomerHealth";
import PricingRenewalPage from "./screens/PricingRenewalPage/PricingRenewalPage";
import {
  Toolbar,
  Button,
  Box,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom/dist";
import ReportsPage from "./screens/ReportsPage/ReportsPage";
import SubscriberPage from "./screens/SubscriberPage/SubscriberPage";

const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "center ",
    padding: "0 20px",
  },
  navButtons: {
    gap: "20px",
    aligItems: "center",
  },
  brand: {
    fontSize: "2rem",
    fontWeight: "600",
    color: "black",
    textDecoration: "none",
    top: "50%",
    left: "50%",
  },
  content: {
    padding: "20px",
  },
  button: {
    borderRadius: "5px",
    margin: "5%",
    minHeight: "10%",
    width: "90%",
    color: "#808080",
    fontWeight: "700",
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  selectedButton: {
    borderRadius: "5px",
    margin: "5%",
    minHeight: "10%",
    width: "90%",
    color: "#FFFFFF",
    fontWeight: "700",
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
};
function RenderRoutes(props) {
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 240;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();
  const currentPage = location.pathname;

  const pagesWithoutNavigationBar = [
    "/",
    "/login",
    "/signup",
    "/reset-password-request",
    "/reset-password",
  ];
  if (pagesWithoutNavigationBar.includes(location.pathname)) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/reset-password-request"
          element={<ResetPasswordRequestPage />}
        />
        <Route path="//reset-password" element={<ResetPasswordPage />} />
      </Routes>
    );
  } else {
    return (
      <div className="App">
        <div style={{ display: "flex" }}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: currentPage.includes("offer/") ? "none": { xs: "block", sm: "none" },

              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#171717",
                borderWidth: "0px",
              },
            }}
          >
            <Toolbar style={styles.toolbar}>
              <Typography
                variant="h6"
                component="a"
                href="/value-features"
                className="brand"
                style={styles.brand}
              >
                <svg
                  width="131"
                  height="25"
                  viewBox="0 0 131 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.608 24.448C6.176 24.448 0.96 19.392 0.96 12.8C0.96 6.208 6.176 1.152 12.608 1.152C19.04 1.152 24.256 6.208 24.256 12.8C24.256 19.392 19.04 24.448 12.608 24.448ZM12.608 19.456C16.256 19.456 19.136 16.8 19.136 12.8C19.136 8.8 16.256 6.144 12.608 6.144C8.96 6.144 6.08 8.8 6.08 12.8C6.08 16.8 8.96 19.456 12.608 19.456ZM35.7788 5.76C33.9868 5.6 32.8028 6.144 32.8028 8H35.7788V12.608H32.8028V24H28.0028V12.608H25.8588V8H28.0028C28.0028 3.52 30.4668 0.831999 35.7788 1.152V5.76ZM46.4975 5.76C44.7055 5.6 43.5215 6.144 43.5215 8H46.4975V12.608H43.5215V24H38.7215V12.608H36.5775V8H38.7215C38.7215 3.52 41.1855 0.831999 46.4975 1.152V5.76ZM52.0793 17.92C52.6873 19.584 54.1593 20.16 55.9193 20.16C57.2313 20.16 58.3513 19.648 58.9913 18.944L62.8313 21.152C61.2633 23.328 58.8953 24.448 55.8553 24.448C50.3833 24.448 46.9913 20.768 46.9913 16C46.9913 11.232 50.4473 7.552 55.5033 7.552C60.1753 7.552 63.6313 11.168 63.6313 16C63.6313 16.672 63.5673 17.312 63.4393 17.92H52.0793ZM51.9833 14.336H58.8633C58.3513 12.48 56.9113 11.808 55.4713 11.808C53.6473 11.808 52.4313 12.704 51.9833 14.336ZM71.1538 10.848C71.7938 8.704 73.9378 7.68 75.9538 7.68V13.12C73.9698 12.8 71.1538 13.6 71.1538 16.768V24H66.3538V8H71.1538V10.848ZM82.9335 24L76.7575 1.6H82.1335L86.0055 17.28L90.3255 1.6H94.4855L98.8055 17.28L102.678 1.6H108.054L101.878 24H96.0535L92.4055 10.784L88.7575 24H82.9335ZM111.91 6.592C110.342 6.592 109.03 5.28 109.03 3.712C109.03 2.144 110.342 0.831999 111.91 0.831999C113.478 0.831999 114.79 2.144 114.79 3.712C114.79 5.28 113.478 6.592 111.91 6.592ZM109.51 24V8H114.31V24H109.51ZM123.775 19.52H130.143V24H117.343V20.8L123.391 12.48H117.663V8H129.823V11.2L123.775 19.52Z"
                    fill="white"
                  />
                </svg>
              </Typography>
            </Toolbar>
            <div style={styles.navButtons}>
              <Button
                style={
                  currentPage === "/value-features"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/value-features")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0003 14.1667C10.0003 16.4679 11.8658 18.3333 14.167 18.3333C16.4682 18.3333 18.3337 16.4679 18.3337 14.1667C18.3337 11.8655 16.4682 10 14.167 10C11.8658 10 10.0003 11.8655 10.0003 14.1667ZM10.0003 14.1667C10.0003 13.2285 10.3104 12.3627 10.8337 11.6663V4.16667M10.0003 14.1667C10.0003 14.8545 10.167 15.5033 10.4621 16.0751C9.76005 16.6681 8.13853 17.0833 6.25033 17.0833C3.71902 17.0833 1.66699 16.3371 1.66699 15.4167V4.16667M10.8337 4.16667C10.8337 5.08714 8.78163 5.83333 6.25033 5.83333C3.71902 5.83333 1.66699 5.08714 1.66699 4.16667M10.8337 4.16667C10.8337 3.24619 8.78163 2.5 6.25033 2.5C3.71902 2.5 1.66699 3.24619 1.66699 4.16667M1.66699 11.6667C1.66699 12.5871 3.71902 13.3333 6.25033 13.3333C8.07449 13.3333 9.64976 12.9458 10.3875 12.3848M10.8337 7.91667C10.8337 8.83714 8.78163 9.58333 6.25033 9.58333C3.71902 9.58333 1.66699 8.83714 1.66699 7.91667"
                    stroke={
                      currentPage === "/value-features" ? "white" : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Value Features
              </Button>{" "}
              <Button
                style={
                  currentPage === "/subscription-packages"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/subscription-packages")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_157_2900)">
                    <path
                      d="M5.00033 12.4998L6.66699 14.1665L10.417 10.4165M6.66699 6.6665V4.33317C6.66699 3.39975 6.66699 2.93304 6.84865 2.57652C7.00844 2.26292 7.2634 2.00795 7.57701 1.84816C7.93353 1.6665 8.40024 1.6665 9.33366 1.6665H15.667C16.6004 1.6665 17.0671 1.6665 17.4236 1.84816C17.7372 2.00795 17.9922 2.26292 18.152 2.57652C18.3337 2.93304 18.3337 3.39975 18.3337 4.33317V10.6665C18.3337 11.5999 18.3337 12.0666 18.152 12.4232C17.9922 12.7368 17.7372 12.9917 17.4236 13.1515C17.0671 13.3332 16.6004 13.3332 15.667 13.3332H13.3337M4.33366 18.3332H10.667C11.6004 18.3332 12.0671 18.3332 12.4236 18.1515C12.7372 17.9917 12.9922 17.7368 13.152 17.4232C13.3337 17.0666 13.3337 16.5999 13.3337 15.6665V9.33317C13.3337 8.39975 13.3337 7.93304 13.152 7.57652C12.9922 7.26292 12.7372 7.00795 12.4236 6.84816C12.0671 6.6665 11.6004 6.6665 10.667 6.6665H4.33366C3.40024 6.6665 2.93353 6.6665 2.57701 6.84816C2.2634 7.00795 2.00844 7.26292 1.84865 7.57652C1.66699 7.93304 1.66699 8.39975 1.66699 9.33317V15.6665C1.66699 16.5999 1.66699 17.0666 1.84865 17.4232C2.00844 17.7368 2.2634 17.9917 2.57701 18.1515C2.93353 18.3332 3.40024 18.3332 4.33366 18.3332Z"
                      stroke={
                        currentPage === "/subscription-packages"
                          ? "white"
                          : "#808080"
                      }
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_157_2900">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Subscription Packages
              </Button>
              <Button
                style={
                  currentPage === "/customers" ||
                  currentPage.includes("show-customer")
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/customers")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3337 17.5V15.8333C18.3337 14.2801 17.2713 12.9751 15.8337 12.605M12.917 2.7423C14.1386 3.23679 15.0003 4.43443 15.0003 5.83333C15.0003 7.23224 14.1386 8.42988 12.917 8.92437M14.167 17.5C14.167 15.9469 14.167 15.1703 13.9133 14.5577C13.5749 13.741 12.926 13.092 12.1093 12.7537C11.4967 12.5 10.7201 12.5 9.16699 12.5H6.66699C5.11385 12.5 4.33728 12.5 3.72471 12.7537C2.90795 13.092 2.25904 13.741 1.92073 14.5577C1.66699 15.1703 1.66699 15.9469 1.66699 17.5M11.2503 5.83333C11.2503 7.67428 9.75794 9.16667 7.91699 9.16667C6.07604 9.16667 4.58366 7.67428 4.58366 5.83333C4.58366 3.99238 6.07604 2.5 7.91699 2.5C9.75794 2.5 11.2503 3.99238 11.2503 5.83333Z"
                    stroke={currentPage === "/customers" ? "white" : "#808080"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Customers
              </Button>
              <Button
                style={
                  currentPage === "/customer-health"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/customer-health")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.917 9.58333H12.0837L10.8337 12.0833L9.16699 7.08333L7.91699 9.58333H7.08366M9.99462 4.27985C8.32849 2.332 5.55011 1.80804 3.46257 3.59168C1.37503 5.37532 1.08113 8.35748 2.72049 10.467C3.95873 12.0604 7.4764 15.2591 9.12367 16.7291C9.42648 16.9993 9.57789 17.1344 9.75518 17.1876C9.9091 17.2338 10.0801 17.2338 10.2341 17.1876C10.4113 17.1344 10.5628 16.9993 10.8656 16.7291C12.5128 15.2591 16.0305 12.0604 17.2687 10.467C18.9081 8.35748 18.6501 5.35656 16.5267 3.59168C14.4032 1.8268 11.6607 2.332 9.99462 4.27985Z"
                    stroke={
                      currentPage === "/customer-health" ? "white" : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Customer Health Score
              </Button>
              <Button
                style={
                  currentPage === "/utility-function"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/utility-function")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_157_2908)">
                    <path
                      d="M10.0003 1.6665C11.0947 1.6665 12.1783 1.88205 13.1894 2.30084C14.2004 2.71963 15.1191 3.33346 15.8929 4.10728C16.6667 4.8811 17.2805 5.79977 17.6993 6.81081C18.1181 7.82186 18.3337 8.90549 18.3337 9.99984M10.0003 1.6665V9.99984M10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984M10.0003 1.6665C14.6027 1.6665 18.3337 5.39747 18.3337 9.99984M18.3337 9.99984L10.0003 9.99984M18.3337 9.99984C18.3337 11.3149 18.0224 12.6113 17.4254 13.7831C16.8283 14.9548 15.9625 15.9687 14.8985 16.7416L10.0003 9.99984"
                      stroke={
                        currentPage === "/utility-function"
                          ? "white"
                          : "#808080"
                      }
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_157_2908">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Utility Function
              </Button>
              <Button
                style={
                  currentPage === "/offerwiz-subscriber"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/offerwiz-subscriber")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M13.3337 17.4997V16.4997C13.3337 15.0995 13.3337 14.3995 13.0612 13.8647C12.8215 13.3943 12.439 13.0118 11.9686 12.7722C11.4339 12.4997 10.7338 12.4997 9.33366 12.4997H5.66699C4.26686 12.4997 3.5668 12.4997 3.03202 12.7722C2.56161 13.0118 2.17916 13.3943 1.93948 13.8647C1.66699 14.3995 1.66699 15.0995 1.66699 16.4997V17.4997M13.3337 4.99967L15.0003 6.66634L18.3337 3.33301M10.417 6.24967C10.417 7.86051 9.11116 9.16634 7.50033 9.16634C5.8895 9.16634 4.58366 7.86051 4.58366 6.24967C4.58366 4.63884 5.8895 3.33301 7.50033 3.33301C9.11116 3.33301 10.417 4.63884 10.417 6.24967Z"
                    stroke={
                      currentPage === "/offerwiz-subscriber"
                        ? "white"
                        : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                OfferWiz Subscriber
              </Button>
              <Button
                style={
                  currentPage === "/experiments"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/experiments")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.66667 10.8333V14.1667M13.3333 9.16667V14.1667M10 5.83333V14.1667M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                    stroke={
                      currentPage === "/experiments" ? "white" : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Experiments
              </Button>
              <Button
                style={
                  currentPage === "/reports"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/reports")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M11.6663 1.8916V5.33372C11.6663 5.80043 11.6663 6.03378 11.7572 6.21204C11.8371 6.36885 11.9645 6.49633 12.1213 6.57622C12.2996 6.66705 12.533 6.66705 12.9997 6.66705H16.4418M13.333 10.8337H6.66634M13.333 14.167H6.66634M8.33301 7.50033H6.66634M11.6663 1.66699H7.33301C5.93288 1.66699 5.23281 1.66699 4.69803 1.93948C4.22763 2.17916 3.84517 2.56161 3.60549 3.03202C3.33301 3.5668 3.33301 4.26686 3.33301 5.66699V14.3337C3.33301 15.7338 3.33301 16.4339 3.60549 16.9686C3.84517 17.439 4.22763 17.8215 4.69803 18.0612C5.23281 18.3337 5.93288 18.3337 7.33301 18.3337H12.6663C14.0665 18.3337 14.7665 18.3337 15.3013 18.0612C15.7717 17.8215 16.1542 17.439 16.3939 16.9686C16.6663 16.4339 16.6663 15.7338 16.6663 14.3337V6.66699L11.6663 1.66699Z"
                    stroke={currentPage === "/reports" ? "white" : "#808080"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Reports
              </Button>
            </div>
          </Drawer>
          {/*------------------------------------------------------------------------------------------------------ */}

          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              height: "100vh",
              display:  currentPage.includes("offer/") ? "none": { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#171717",
                borderWidth: "0px",
              },
            }}
            open
          >
            <Toolbar style={styles.toolbar}>
              <Typography
                variant="h6"
                component="a"
                href="/value-features"
                className="brand"
                style={styles.brand}
              >
                <svg
                  width="131"
                  height="25"
                  viewBox="0 0 131 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.608 24.448C6.176 24.448 0.96 19.392 0.96 12.8C0.96 6.208 6.176 1.152 12.608 1.152C19.04 1.152 24.256 6.208 24.256 12.8C24.256 19.392 19.04 24.448 12.608 24.448ZM12.608 19.456C16.256 19.456 19.136 16.8 19.136 12.8C19.136 8.8 16.256 6.144 12.608 6.144C8.96 6.144 6.08 8.8 6.08 12.8C6.08 16.8 8.96 19.456 12.608 19.456ZM35.7788 5.76C33.9868 5.6 32.8028 6.144 32.8028 8H35.7788V12.608H32.8028V24H28.0028V12.608H25.8588V8H28.0028C28.0028 3.52 30.4668 0.831999 35.7788 1.152V5.76ZM46.4975 5.76C44.7055 5.6 43.5215 6.144 43.5215 8H46.4975V12.608H43.5215V24H38.7215V12.608H36.5775V8H38.7215C38.7215 3.52 41.1855 0.831999 46.4975 1.152V5.76ZM52.0793 17.92C52.6873 19.584 54.1593 20.16 55.9193 20.16C57.2313 20.16 58.3513 19.648 58.9913 18.944L62.8313 21.152C61.2633 23.328 58.8953 24.448 55.8553 24.448C50.3833 24.448 46.9913 20.768 46.9913 16C46.9913 11.232 50.4473 7.552 55.5033 7.552C60.1753 7.552 63.6313 11.168 63.6313 16C63.6313 16.672 63.5673 17.312 63.4393 17.92H52.0793ZM51.9833 14.336H58.8633C58.3513 12.48 56.9113 11.808 55.4713 11.808C53.6473 11.808 52.4313 12.704 51.9833 14.336ZM71.1538 10.848C71.7938 8.704 73.9378 7.68 75.9538 7.68V13.12C73.9698 12.8 71.1538 13.6 71.1538 16.768V24H66.3538V8H71.1538V10.848ZM82.9335 24L76.7575 1.6H82.1335L86.0055 17.28L90.3255 1.6H94.4855L98.8055 17.28L102.678 1.6H108.054L101.878 24H96.0535L92.4055 10.784L88.7575 24H82.9335ZM111.91 6.592C110.342 6.592 109.03 5.28 109.03 3.712C109.03 2.144 110.342 0.831999 111.91 0.831999C113.478 0.831999 114.79 2.144 114.79 3.712C114.79 5.28 113.478 6.592 111.91 6.592ZM109.51 24V8H114.31V24H109.51ZM123.775 19.52H130.143V24H117.343V20.8L123.391 12.48H117.663V8H129.823V11.2L123.775 19.52Z"
                    fill="white"
                  />
                </svg>
              </Typography>
            </Toolbar>
            <div style={styles.navButtons}>
              <Button
                style={
                  currentPage === "/value-features"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/value-features")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0003 14.1667C10.0003 16.4679 11.8658 18.3333 14.167 18.3333C16.4682 18.3333 18.3337 16.4679 18.3337 14.1667C18.3337 11.8655 16.4682 10 14.167 10C11.8658 10 10.0003 11.8655 10.0003 14.1667ZM10.0003 14.1667C10.0003 13.2285 10.3104 12.3627 10.8337 11.6663V4.16667M10.0003 14.1667C10.0003 14.8545 10.167 15.5033 10.4621 16.0751C9.76005 16.6681 8.13853 17.0833 6.25033 17.0833C3.71902 17.0833 1.66699 16.3371 1.66699 15.4167V4.16667M10.8337 4.16667C10.8337 5.08714 8.78163 5.83333 6.25033 5.83333C3.71902 5.83333 1.66699 5.08714 1.66699 4.16667M10.8337 4.16667C10.8337 3.24619 8.78163 2.5 6.25033 2.5C3.71902 2.5 1.66699 3.24619 1.66699 4.16667M1.66699 11.6667C1.66699 12.5871 3.71902 13.3333 6.25033 13.3333C8.07449 13.3333 9.64976 12.9458 10.3875 12.3848M10.8337 7.91667C10.8337 8.83714 8.78163 9.58333 6.25033 9.58333C3.71902 9.58333 1.66699 8.83714 1.66699 7.91667"
                    stroke={
                      currentPage === "/value-features" ? "white" : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Value Features
              </Button>{" "}
              <Button
                style={
                  currentPage === "/subscription-packages"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/subscription-packages")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_157_2900)">
                    <path
                      d="M5.00033 12.4998L6.66699 14.1665L10.417 10.4165M6.66699 6.6665V4.33317C6.66699 3.39975 6.66699 2.93304 6.84865 2.57652C7.00844 2.26292 7.2634 2.00795 7.57701 1.84816C7.93353 1.6665 8.40024 1.6665 9.33366 1.6665H15.667C16.6004 1.6665 17.0671 1.6665 17.4236 1.84816C17.7372 2.00795 17.9922 2.26292 18.152 2.57652C18.3337 2.93304 18.3337 3.39975 18.3337 4.33317V10.6665C18.3337 11.5999 18.3337 12.0666 18.152 12.4232C17.9922 12.7368 17.7372 12.9917 17.4236 13.1515C17.0671 13.3332 16.6004 13.3332 15.667 13.3332H13.3337M4.33366 18.3332H10.667C11.6004 18.3332 12.0671 18.3332 12.4236 18.1515C12.7372 17.9917 12.9922 17.7368 13.152 17.4232C13.3337 17.0666 13.3337 16.5999 13.3337 15.6665V9.33317C13.3337 8.39975 13.3337 7.93304 13.152 7.57652C12.9922 7.26292 12.7372 7.00795 12.4236 6.84816C12.0671 6.6665 11.6004 6.6665 10.667 6.6665H4.33366C3.40024 6.6665 2.93353 6.6665 2.57701 6.84816C2.2634 7.00795 2.00844 7.26292 1.84865 7.57652C1.66699 7.93304 1.66699 8.39975 1.66699 9.33317V15.6665C1.66699 16.5999 1.66699 17.0666 1.84865 17.4232C2.00844 17.7368 2.2634 17.9917 2.57701 18.1515C2.93353 18.3332 3.40024 18.3332 4.33366 18.3332Z"
                      stroke={
                        currentPage === "/subscription-packages"
                          ? "white"
                          : "#808080"
                      }
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_157_2900">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Subscription Packages
              </Button>
              <Button
                style={
                  currentPage === "/customers" ||
                  currentPage.includes("show-customer")
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/customers")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3337 17.5V15.8333C18.3337 14.2801 17.2713 12.9751 15.8337 12.605M12.917 2.7423C14.1386 3.23679 15.0003 4.43443 15.0003 5.83333C15.0003 7.23224 14.1386 8.42988 12.917 8.92437M14.167 17.5C14.167 15.9469 14.167 15.1703 13.9133 14.5577C13.5749 13.741 12.926 13.092 12.1093 12.7537C11.4967 12.5 10.7201 12.5 9.16699 12.5H6.66699C5.11385 12.5 4.33728 12.5 3.72471 12.7537C2.90795 13.092 2.25904 13.741 1.92073 14.5577C1.66699 15.1703 1.66699 15.9469 1.66699 17.5M11.2503 5.83333C11.2503 7.67428 9.75794 9.16667 7.91699 9.16667C6.07604 9.16667 4.58366 7.67428 4.58366 5.83333C4.58366 3.99238 6.07604 2.5 7.91699 2.5C9.75794 2.5 11.2503 3.99238 11.2503 5.83333Z"
                    stroke={currentPage === "/customers" ? "white" : "#808080"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Customers
              </Button>
              <Button
                style={
                  currentPage === "/customer-health"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/customer-health")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.917 9.58333H12.0837L10.8337 12.0833L9.16699 7.08333L7.91699 9.58333H7.08366M9.99462 4.27985C8.32849 2.332 5.55011 1.80804 3.46257 3.59168C1.37503 5.37532 1.08113 8.35748 2.72049 10.467C3.95873 12.0604 7.4764 15.2591 9.12367 16.7291C9.42648 16.9993 9.57789 17.1344 9.75518 17.1876C9.9091 17.2338 10.0801 17.2338 10.2341 17.1876C10.4113 17.1344 10.5628 16.9993 10.8656 16.7291C12.5128 15.2591 16.0305 12.0604 17.2687 10.467C18.9081 8.35748 18.6501 5.35656 16.5267 3.59168C14.4032 1.8268 11.6607 2.332 9.99462 4.27985Z"
                    stroke={
                      currentPage === "/customer-health" ? "white" : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Customer Health Score
              </Button>
              <Button
                style={
                  currentPage === "/utility-function"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/utility-function")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_157_2908)">
                    <path
                      d="M10.0003 1.6665C11.0947 1.6665 12.1783 1.88205 13.1894 2.30084C14.2004 2.71963 15.1191 3.33346 15.8929 4.10728C16.6667 4.8811 17.2805 5.79977 17.6993 6.81081C18.1181 7.82186 18.3337 8.90549 18.3337 9.99984M10.0003 1.6665V9.99984M10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984M10.0003 1.6665C14.6027 1.6665 18.3337 5.39747 18.3337 9.99984M18.3337 9.99984L10.0003 9.99984M18.3337 9.99984C18.3337 11.3149 18.0224 12.6113 17.4254 13.7831C16.8283 14.9548 15.9625 15.9687 14.8985 16.7416L10.0003 9.99984"
                      stroke={
                        currentPage === "/utility-function"
                          ? "white"
                          : "#808080"
                      }
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_157_2908">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Utility Function
              </Button>
              <Button
                style={
                  currentPage === "/offerwiz-subscriber"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/offerwiz-subscriber")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M13.3337 17.4997V16.4997C13.3337 15.0995 13.3337 14.3995 13.0612 13.8647C12.8215 13.3943 12.439 13.0118 11.9686 12.7722C11.4339 12.4997 10.7338 12.4997 9.33366 12.4997H5.66699C4.26686 12.4997 3.5668 12.4997 3.03202 12.7722C2.56161 13.0118 2.17916 13.3943 1.93948 13.8647C1.66699 14.3995 1.66699 15.0995 1.66699 16.4997V17.4997M13.3337 4.99967L15.0003 6.66634L18.3337 3.33301M10.417 6.24967C10.417 7.86051 9.11116 9.16634 7.50033 9.16634C5.8895 9.16634 4.58366 7.86051 4.58366 6.24967C4.58366 4.63884 5.8895 3.33301 7.50033 3.33301C9.11116 3.33301 10.417 4.63884 10.417 6.24967Z"
                    stroke={
                      currentPage === "/offerwiz-subscriber"
                        ? "white"
                        : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                OfferWiz Subscriber
              </Button>
              <Button
                style={
                  currentPage === "/experiments"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/experiments")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.66667 10.8333V14.1667M13.3333 9.16667V14.1667M10 5.83333V14.1667M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                    stroke={
                      currentPage === "/experiments" ? "white" : "#808080"
                    }
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Experiments
              </Button>
              <Button
                style={
                  currentPage === "/reports"
                    ? styles.selectedButton
                    : styles.button
                }
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/reports")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M11.6663 1.8916V5.33372C11.6663 5.80043 11.6663 6.03378 11.7572 6.21204C11.8371 6.36885 11.9645 6.49633 12.1213 6.57622C12.2996 6.66705 12.533 6.66705 12.9997 6.66705H16.4418M13.333 10.8337H6.66634M13.333 14.167H6.66634M8.33301 7.50033H6.66634M11.6663 1.66699H7.33301C5.93288 1.66699 5.23281 1.66699 4.69803 1.93948C4.22763 2.17916 3.84517 2.56161 3.60549 3.03202C3.33301 3.5668 3.33301 4.26686 3.33301 5.66699V14.3337C3.33301 15.7338 3.33301 16.4339 3.60549 16.9686C3.84517 17.439 4.22763 17.8215 4.69803 18.0612C5.23281 18.3337 5.93288 18.3337 7.33301 18.3337H12.6663C14.0665 18.3337 14.7665 18.3337 15.3013 18.0612C15.7717 17.8215 16.1542 17.439 16.3939 16.9686C16.6663 16.4339 16.6663 15.7338 16.6663 14.3337V6.66699L11.6663 1.66699Z"
                    stroke={currentPage === "/reports" ? "white" : "#808080"}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Reports
              </Button>
            </div>
          </Drawer>
          <Box
            sx={{
              minHeight: "100vh",
              width: "100%",
              zIndex: 0,
            }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              sx={{
                position: "absolute",
                zIndex: 1,
                color: "white",
                marginLeft: "1%",
                display: currentPage.includes("offer/") ? "none": { xs: "block", lg: "none" },
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Routes>
              <Route path="/logout" element={<LogoutPage />} />
              <Route
                path="/subscription-plans/settings"
                element={<SubscriptionPlanSettings />}
              />

              <Route path="/dashboard" element={<DashboardPage />} />

              <Route
                path="/reset-password-request"
                element={<ResetPasswordRequestPage />}
              />
              <Route path="/reset-password" element={<ResetPasswordPage />} />

              <Route path="/customers" element={<CustomersPage />} />
              <Route path="/new-customer" element={<NewCustomerPage />} />
              <Route path="/show-customer/:id" element={<ShowCustomerPage />} />
              <Route path="/customer-health/:id" element={<CustomerHealth />} />
              <Route path="/customer-health" element={<CustomerHealth />} />
              <Route
                path="/subscription-packages"
                element={<SubscriptionPackageBuilderPage />}
              />
              <Route
                path="/utility-function"
                element={<PricingRenewalPage />}
              />
              <Route path="/offerwiz-subscriber" element={<SubscriberPage />} />
              <Route path="/reports" element={<ReportsPage />} />

              <Route
                path="/subscription-plans"
                element={<SubscriptionPlansPage />}
              />
              <Route
                path="/new-subscription-plan"
                element={<NewSubscriptionPlanPage />}
              />
              <Route
                path="/show-subscription-plan/:id"
                element={<ShowSubscriptionPlanPage />}
              />

              <Route path="/value-features" element={<ValueFeaturesPage />} />
              <Route
                path="/new-value-feature"
                element={<NewValueFeaturePage />}
              />
              <Route
                path="/show-value-feature/:id"
                element={<NewValueFeaturePage />}
              />

              <Route path="/new-experiment" element={<NewExperimentPage />} />
              <Route path="/experiments" element={<ExperimentsPage />} />
              <Route
                path="/show-experiment/:id"
                element={<ShowExperimentPage />}
              />

              <Route path="/show-arm-set/:id" element={<ShowArmSetPage />} />

              <Route path="/offer/:token" element={<OfferPage />} />
            </Routes>
          </Box>
        </div>
      </div>
    );
  }
}

const App = () => {
  return (
    <Router>
      <RenderRoutes />
    </Router>
  );
};

export default App;
