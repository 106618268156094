/** @format */

// ExperimentsComponent.js

import React, { useState } from "react";
import {
  Grid,
  Checkbox,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@mui/material";
import Divider from "@mui/material/Divider";
const users = [
  {
    id: 1,
    name: "John Doe",
    date: "20/08/2023",
    status: "Completed",

    paidPlanPurchased: "Yes",
    additionalFeatPurchased: "Yes",
  },
  {
    id: 2,
    name: "Jane Smith",
    date: "20/08/2023",
    status: "Ongoing",

    paidPlanPurchased: "No",
    additionalFeatPurchased: "No",
  },
];

function ExperimentsComponent() {
  return (
    <Grid
      container
      sx={{
        marginBottom: "16px",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "2%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fonstSize: "20px",
              fontWeight: 600,
              margin: "1em",
            }}
          >
            Experiments
          </Typography>
          <Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="#101828"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                stroke="#101828"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                stroke="#101828"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
        </Grid>
        <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
        <Grid item xs={12}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#808080",
              fontSize: "14px",
              fontWeight: 400,
              margin: "1em",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ marginRight: "8px" }}
            >
              <g clip-path="url(#clip0_157_3682)">
                <path
                  d="M7.57484 7.49984C7.77076 6.94289 8.15746 6.47326 8.66647 6.17411C9.17547 5.87497 9.77392 5.76562 10.3558 5.86543C10.9377 5.96524 11.4655 6.26777 11.8457 6.71944C12.226 7.17111 12.434 7.74277 12.4332 8.33317C12.4332 9.99984 9.93317 10.8332 9.93317 10.8332M9.99984 14.1665H10.0082M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
                  stroke="#808080"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_157_3682">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Was the customer part of an experiment?
          </Typography>
          <Typography
            sx={{
              marginLeft: 5,
              fontSize: "16px",
              fontWeight: 600,
              color: "white",
            }}
          >
            Yes*
          </Typography>
        </Grid>
        <Divider width="100%" sx={{ backgroundColor: "#808080" }} />
        <Grid
          item
          md={12}
          sx={{
            color: "white",
            fonstSize: "20px",
            fontWeight: 600,
            margin: "1em",
            marginRight: "0",
            overflowX:"auto"
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Experiment Result:
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Paid Plan purchased
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        Additional Features Purchased
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#808080",
                        }}
                      >
                        ID
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "white",
                          }}
                        >
                          {user.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "white",
                          }}
                        >
                          {user.date}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            alignItems: "center",
                            display: "flex",
                            color: "white",
                          }}
                        >
                          {user.status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            backgroundColor: "#1F1F1F80",
                            borderRadius: "100px",
                            padding: "10px",
                            width: "40px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            color:
                              user.paidPlanPurchased === "Yes"
                                ? " #47D647"
                                : "#D32F2F",
                          }}
                        >
                          {user.paidPlanPurchased}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            backgroundColor: "#1F1F1F80",
                            borderRadius: "100px",
                            padding: "10px",
                            width: "40px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            color:
                              user.additionalFeatPurchased === "Yes"
                                ? " #47D647"
                                : "#D32F2F",
                          }}
                        >
                          {user.additionalFeatPurchased}{" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(128, 128, 128, 0.2)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "white",
                          }}
                        >
                          {user.id}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Grid>
  );
}

export default ExperimentsComponent;
