/** @format */

import React, { useState } from "react";
import { Grid, Typography, TextField, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {string} props.text
 * @param {string} props.date - date
 * @param {string} props.selectIcon "tick", "file","close"
 */

const MultiCardComponent = ({ value, text, date, selectIcon }) => {

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        padding: "5%",
        minHeight: "25vh",
      }}
    >
      <Grid item xs={12} sx={{ flexDirection: "row", display: "flex" }}>
        <Grid item xs={12} lg={4}>
          <Typography
            sx={{
              borderRadius: "8px",
              height: "48px",
              width: "48px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#1F1F1F",
            }}
          >
            {selectIcon === "tick" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 12L11 14L15.5 9.5M7.33377 3.8187C8.1376 3.75455 8.90071 3.43846 9.51447 2.91542C10.9467 1.69486 13.0533 1.69486 14.4855 2.91542C15.0993 3.43846 15.8624 3.75455 16.6662 3.8187C18.5421 3.96839 20.0316 5.45794 20.1813 7.33377C20.2455 8.1376 20.5615 8.90071 21.0846 9.51447C22.3051 10.9467 22.3051 13.0533 21.0846 14.4855C20.5615 15.0993 20.2455 15.8624 20.1813 16.6662C20.0316 18.5421 18.5421 20.0316 16.6662 20.1813C15.8624 20.2455 15.0993 20.5615 14.4855 21.0846C13.0533 22.3051 10.9467 22.3051 9.51447 21.0846C8.90071 20.5615 8.1376 20.2455 7.33377 20.1813C5.45794 20.0316 3.96839 18.5421 3.8187 16.6662C3.75455 15.8624 3.43846 15.0993 2.91542 14.4855C1.69486 13.0533 1.69486 10.9467 2.91542 9.51447C3.43846 8.90071 3.75455 8.1376 3.8187 7.33377C3.96839 5.45794 5.45794 3.96839 7.33377 3.8187Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : selectIcon === "file" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : selectIcon === "close" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={3.5} sx={{ marginLeft: "auto" }}>
          <Typography
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "#1F1F1F",
              color: "white",
              fontSize: "14px",
              fontWeight: 500,
              verticalAlign: "center",
              textAlign: "center",
              padding: "4px",
            }}
          >
            {date}{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "6%" }}>
        <Typography
          sx={{ fontSize: "34px", fontWeight: 600, whiteSpace: "nowrap",color:"white" }}
        >
          {value}
        </Typography>
        <Typography sx={{ fontSize: "14", fontWeight: 500, width: "100%",color:"#808080" }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MultiCardComponent;
