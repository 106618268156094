/** @format */

import React, { useState, useEffect } from "react";


import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  startDummyNegotiation,
} from "../../api/negotiation";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  Typography,
  InputLabel,
  Container,
  Input,
} from "@mui/material";
import { listSubscriptionModels } from "../../api/subscription.plans";
import MessageBanner from "../../components/messageBanner";
import DataStorage from "../../storage";

const renewalStrategies = [
  "Subscribe x 1-Bootsrapper(-)",
  "Subscribe x 1-Bootsrapper",
  "Subscribe x 1-Bootsrapper(+)",
  "Subscribe x 1",
  "Subscribe x 2",
  "Subscribe x 3",
];

const billingPeriods = ["Monthly", "Annual", "Two year", "Three year"];

const SubscriberPage = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");

  const [message, setMessage] = useState(message_ || "");
  const [subscriptionModels, setSubscriptionModels] = useState(null);
  const [renewalStrategy, setRenewalStrategy] = useState(renewalStrategies[0]);
  const [subscriptionModel, setSubscriptionModel] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState(billingPeriods[0]);
  const [specialDiscount, setSpecialDiscount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    listSubscriptionModels(DataStorage.get("token"))
      .then((resp) => {
        setSubscriptionModels(resp.data);
        setSubscriptionModel(resp.data[0].id);
      })
      .catch(() => setMessage("An error occured."));
  }, []);

  console.log(subscriptionModels);

  const handleLaunch = () => {
    startDummyNegotiation(
      DataStorage.get("token"),
      subscriptionModel,
      billingPeriod,
      {},
      renewalStrategy,
      specialDiscount,
    )
      .then((resp) => navigate(`/offer/${resp.data.token}`))
      .catch(() => setMessage("An error occured."));
  };

  const handleBillingPeriodChange = (e) => {
    let currentModel = subscriptionModels.find(
      (sm) => sm.id == subscriptionModel
    );
    let billingPeriod = e.target.value;
    console.log(billingPeriod, currentModel.interval);
    if (
      (billingPeriod == "Annual" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Three year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Two year")
    ) {
      setMessage("Billing period must be less than subscription term");
      return;
    }
    if (billingPeriod == "Two year" && currentModel.interval == "Three year") {
      setMessage("Three year plans has no two year billing");
      return;
    }
    setBillingPeriod(e.target.value);
  };

  const handleSubModelChange = (e) => {
    let currentModel = subscriptionModels.find((sm) => sm.id == e.target.value);
    console.log(billingPeriod, currentModel.interval);
    if (
      (billingPeriod == "Annual" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Two year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Monthly") ||
      (billingPeriod == "Three year" && currentModel.interval == "Annual") ||
      (billingPeriod == "Three year" && currentModel.interval == "Two year")
    ) {
      setMessage("Billing period must be less than subscription term");
      return;
    }
    if (billingPeriod == "Two year" && currentModel.interval == "Three year") {
      setMessage("Three year plans has no two year billing");
      return;
    }
    setSubscriptionModel(e.target.value);
  };

  const handleSpecialDiscountChange = (e) => {
    setSpecialDiscount(e.target.value);
  }

  const handleRenewalStrategyChange = (e) => {
    setRenewalStrategy(e.target.value);
  };

  return (
    <Container maxWidth="md" align="center">
      <Typography variant="h6" gutterBottom>
        Customer Negotiations
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select a renewal strategy</InputLabel>
        <Select
          label="Select a renewal strategy     "
          name="subcription"
          value={renewalStrategy}
          onChange={handleRenewalStrategyChange}
        >
          {renewalStrategies?.map((renewalStrategy, index) => (
            <MenuItem key={index} value={renewalStrategy}>
              {renewalStrategy}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select a subscription model</InputLabel>
        <Select
          label="Select a subscription model    "
          name="subcription"
          value={subscriptionModel}
          onChange={handleSubModelChange}
        >
          {subscriptionModels?.map((subModel, index) => (
            <MenuItem key={index} value={subModel.id}>
              {subModel.plan.name + " - " + subModel.interval}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select a billing period</InputLabel>
        <Select
          label="Select a billing period    "
          name="billingPeriod"
          value={billingPeriod}
          onChange={handleBillingPeriodChange}
        >
          {billingPeriods.map((period, index) => (
            <MenuItem key={index} value={period}>
              {period}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Input Special Discount</InputLabel>
        <Input
          label="Apply Special Discount"
          name="specialDiscount"
          value={specialDiscount}
          onChange={handleSpecialDiscountChange}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLaunch}
        style={{ marginTop: "10px" }}
      >
        Launch AI Subscriber
      </Button>

      <MessageBanner message={message} setMessage={setMessage} />
    </Container>
  );
};

export default SubscriberPage;
