/** @format */

// ExperimentsComponent.js

import React, { useState } from "react";
import {
  Grid,
  Checkbox,
  Typography,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Divider from "@mui/material/Divider";
const users = [
  {
    id: 1,
    faeture: "Function 1, Function 1",
    Additions: "152",
    Subtractions: "196",
    Unlimited: "392",
    additionalRevenue: "194",
  },
  {
    id: 2,
    faeture: "Function 2, Function 2",
    Additions: "120",
    Subtractions: "180",
    Unlimited: "300",
    additionalRevenue: "150",
  },
  {
    id: 3,
    faeture: "Function 3, Function 3",
    Additions: "180",
    Subtractions: "160",
    Unlimited: "340",
    additionalRevenue: "160",
  },
  {
    id: 4,
    faeture: "Function 4, Function 4",
    Additions: "200",
    Subtractions: "220",
    Unlimited: "420",
    additionalRevenue: "220",
  },
  {
    id: 5,
    faeture: "Function 5, Function 5",
    Additions: "100",
    Subtractions: "150",
    Unlimited: "250",
    additionalRevenue: "150",
  },
  {
    id: 6,
    faeture: "Function 6, Function 6",
    Additions: "130",
    Subtractions: "170",
    Unlimited: "300",
    additionalRevenue: "140",
  },
];

function TableComponent({ date }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("annually");

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedFeatures([]);
    } else {
      setSelectedFeatures(users.map((user) => user.id));
    }
    setSelectAll(!selectAll);
  };

  const toggleSelectUser = (userId) => {
    if (selectedFeatures.includes(userId)) {
      setSelectedFeatures(selectedFeatures.filter((id) => id !== userId));
    } else {
      setSelectedFeatures([...selectedFeatures, userId]);
    }
    console.log(selectedFeatures);
  };
  return (
    <Grid
      container
      sx={{
        marginTop: "3%",
        backgroundColor: "#2E2E2E",
        borderRadius: "12px",
        width: "99%",
        marginBottom: "5%",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fonstSize: "16px",
              fontWeight: 400,
              margin: "1em",
            }}
          >
            Total amounts of added features, upgraded to unlimited per customer
            per subscription term
          </Typography>

          <Grid
            item
            xs={12}
            lg={3}
            sx={{ marginLeft: "auto", flexDirection: "row", display: "flex" }}
          >
            <TextField
              select
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
              size="small"
              sx={{
                width: "100%",
                borderRadius: "4px",
                backgroundColor: "#1F1F1F",
                "& .MuiSelect-root": {
                  color: "white",
                  fontSize: "14px",
                  fontWeight: 500,
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            >
              <MenuItem value="annually">Annually</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </TextField>
            <Typography
              sx={{
                width: "100%",
                borderRadius: "4px",
                backgroundColor: "#1F1F1F",
                color: "white",
                fontSize: "14px",
                fontWeight: 500,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                padding: "4px",
                marginLeft: "2%",
              }}
            >
              {date}
            </Typography>
          </Grid>
        </Grid>
        <Divider width="100%" />
        <Grid
          item
          md={12}
          sx={{
            color: "#101828",
            fonstSize: "20px",
            fontWeight: 600,
            padding: "2%",
            paddingTop: "0",
            overflowX:"auto"
          }}
        >
          <Table sx={{ backgroundColor: "rgba(200, 209, 230, 0)" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    "&.MuiTableCell-root": {
                      borderBottom: "1px solid #808080",
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#808080" }}
                  >
                    <Checkbox
                      checked={selectAll}
                      onChange={toggleSelectAll}
                      color="primary"
                      sx={{color: "#808080" }}
                    />
                    Feature
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    "&.MuiTableCell-root": {
                      borderBottom: "1px solid #808080",
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#808080" }}
                  >
                    Additions
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    "&.MuiTableCell-root": {
                      borderBottom: "1px solid #808080",
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#808080" }}
                  >
                    Subtractions
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    "&.MuiTableCell-root": {
                      borderBottom: "1px solid #808080",
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#808080" }}
                  >
                    Unlimited
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    "&.MuiTableCell-root": {
                      borderBottom: "1px solid #808080",
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 700, color: "#808080" }}
                  >
                    Total Net additional Revenue
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        borderBottom: "1px solid #808080",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 400,color:"white" }}>
                      <Checkbox
                        checked={selectedFeatures.includes(user.id)}
                        onChange={() => toggleSelectUser(user.id)}
                        color="primary"
                      sx={{color: "#808080" }}

                      />
                      {user.faeture}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        borderBottom: "1px solid #808080",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 400,color:"white" }}>
                      {user.Additions}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        borderBottom: "1px solid #808080",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        alignItems: "center",
                        display: "flex",color:"white"
                      }}
                    >
                      {user.Subtractions}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        borderBottom: "1px solid #808080",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        alignItems: "center",
                        display: "flex",color:"white"
                      }}
                    >
                      {user.Unlimited}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        borderBottom: "1px solid #808080",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        alignItems: "center",
                        display: "flex",color:"white"
                      }}
                    >
                      {user.additionalRevenue}{" "}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Grid>
  );
}

export default TableComponent;
