// components/Signup.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPasswordRequest } from '../api/authentication';
import queryString from 'query-string';
import MessageBanner from './messageBanner';
import DataStorage from "../storage";

function ResetPasswordRequestPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const message_ = searchParams.get('message');

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(message_ || '');

    const navigate = useNavigate();

    useEffect(()=>{
        if (DataStorage.get('token')){
            navigate('/dashboard');
        }
    }, []);

    const handleResetPasswordRequest = (e) => {
        e.preventDefault();
        resetPasswordRequest(email)
            .then(resp=>{
                const message = 'Reset password link is sent to your email address';
                navigate('/login?' + queryString.stringify({message, email}));
            })
            .catch(error=>{
                setMessage('An error occured');
            });
    };

    return (
        <div>
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPasswordRequest}>
            <div>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <button type="submit">Send Reset Password Email</button>
                <button onClick={()=>navigate('/login')}>Login</button>
            </div>
        </form>
        <MessageBanner message={message}/>
        </div>
    );
};

export default ResetPasswordRequestPage;