import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Checkbox, Button, FormGroup, FormControlLabel, Box, Select, MenuItem } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { deleteArmSet, getArmSet, updateArmSet } from '../../../api/arm.sets';
import MessageBanner from '../../../components/messageBanner';
import DataStorage from "../../../storage";

function ShowArmSetPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get('message');

  const [message, setMessage] = useState(message_ || '');

  const { id } = useParams();
  const navigate = useNavigate();

  const [armSet, setArmSet] = useState(null);
  const [name, setName] = useState('');
  const [FullPrice, setFullPrice] = useState(0.0);
  const [d1, setD1] = useState(0.0);
  const [p1, setP1] = useState(0.0);
  const [valueFeatures, setValueFeatures] = useState({});


  useEffect(() => {
    if (!DataStorage.get('token')) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    getArmSet(DataStorage.get('token'), id)
        .then(response => {
            setArmSet(response.data);
            setName(response.data.name);
            setFullPrice(response.data.FullPrice);
            setD1(response.data.D1);
            setP1(response.data.P1);
            setValueFeatures(response.data.valueFeatures)
        })
        .catch(()=>setMessage('An error occurred.'));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      ...armSet,
      name,
      FullPrice,
      D1: d1,
      P1: p1,
      valueFeatures,
    };

    updateArmSet(DataStorage.get('token'), id, formData)
        .then(resp=>setArmSet(resp.data))
        .catch(()=>setMessage('An error occurred.'));
  };

  const handleDelete = () => {
    if (armSet.name == 'Baseline') {
        setMessage('You are not allowed to delete baseline.');
        return;
    }
    deleteArmSet(DataStorage.get('token'), id)
        .then(()=>navigate(`/show-experiment/${armSet.experiment}`))
        .catch(()=>setMessage('An error occurred.'));
  };

  if (!armSet) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container component="main" maxWidth="md">
      <div>
        <Typography component="h1" variant="h5">
          Edit ArmSet
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Full Price"
            type="number"
            fullWidth
            value={FullPrice}
            onChange={(e) => setFullPrice(parseFloat(e.target.value))}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="D1"
            type="number"
            fullWidth
            value={d1}
            onChange={(e) => setD1(parseFloat(e.target.value))}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="P1"
            type="number"
            fullWidth
            value={p1}
            onChange={(e) => setP1(parseFloat(e.target.value))}
            margin="normal"
            variant="outlined"
          />
          <Box mt={2} />
          {valueFeatures.map((valFeat, index)=>(
            <TextField
                label={`${valFeat.description} (Price To User Per Unit Annually)`}
                type="number"
                fullWidth
                value={valFeat.priceToUserPerUnit}
                onChange={(e) => {
                    const newFeatures = [...valueFeatures];
                    newFeatures[index].priceToUserPerUnit = parseFloat(e.target.value);
                    setValueFeatures(newFeatures);
                }}
                margin="normal"
                variant="outlined"
            />
          ))}
          <Box mt={2} />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Update ArmSet
          </Button>
          <Box mt={1} />
          <Button fullWidth variant="contained" color="error" onClick={()=>handleDelete()}>
            Delete ArmSet
          </Button>
        </form>
      </div>
      <MessageBanner
        message={message}
      />
    </Container>
  );
}

export default ShowArmSetPage;
