import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; // Set your API base URL here

export function getExperiment(token, experimentId) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.get(`${API_BASE_URL}/api/experiments/${experimentId}/`, config);
}

export function createExperiment(token, data) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.post(`${API_BASE_URL}/api/experiments/create/`, data, config);
}

export function updateExperiment(token, experimentId, data) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.put(`${API_BASE_URL}/api/experiments/${experimentId}/`, data, config);
}

export function partialUpdateExperiment(token, experimentId, data) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.patch(`${API_BASE_URL}/api/experiments/${experimentId}/`, data, config);
}

export function deleteExperiment(token, experimentId) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.delete(`${API_BASE_URL}/api/experiments/${experimentId}/`, config);
}

export function listExperiments(token) {
  const config = {
    headers: {
      'Authorization': `Token ${token}`
    }
  };

  return axios.get(`${API_BASE_URL}/api/experiments/`, config);
}