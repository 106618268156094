/** @format */

import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Divider,
  MenuItem,
  TableRow,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  Chip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { listSubscriptionPlans } from "../../../api/subscription.plans";
import { listValueFeatures } from "../../../api/value.feature";
import DataStorage from "../../../storage";
import MessageBanner from "../../../components/messageBanner";
import { useLocation } from "react-router-dom";
import { CheckBox } from "@mui/icons-material";
import ArmSets from "./Armsets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix="%"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ExperimentSetUp = ({ setCreateExperiment,showResult }) => {
  const [selectedPlanOrFeatures, setSelectedPlanOrFeatures] = useState(0);
  const [subscriptionPlans, setSubscriptionPlans] = useState();
  const [valueFeatures, setValueFeatures] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message_ = searchParams.get("message");
  const [message, setMessage] = useState(message_ || "");
  const [experimentSetUp, setExperimentSetUp] = useState({
    experimentName: "",
    id: "",
    targetCustomerGroup: "",
    experimentTraffic: "",
    experimentGoal: "",
    experimentType: "",
  });

  useEffect(() => {
    listSubscriptionPlans(DataStorage.get("token")).then((resp) => {
      setSubscriptionPlans(resp.data);
    });
    listValueFeatures(DataStorage.get("token"))
      .then((resp) => {
        setValueFeatures(resp.data);
      })
      .catch(() => setMessage("An error occured."));
  }, []);
  const handleChange = (e) => {
    setExperimentSetUp({ ...experimentSetUp, [e.target.name]: e.target.value });
  };
  const handleAutoCompleteChange = (newValue) => {
    setExperimentSetUp({
      ...experimentSetUp,
      targetCustomerGroup: newValue.map((item) => item.label),
    });
  };
  const groups = [
    { label: "Customers on a free plan" },
    { label: "Customers on a trial plan" },
  ];

  return (
    <>
      <Grid item xs={12} sx={{ margin: "1%" }}>
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
            padding: "1%",
          }}
        >
          {" "}
          <Grid item xs={12} sx={{ margin: "1%", display: "flex" }}>
            <Chip
              onClick={() => setCreateExperiment(false)}
              sx={{
                width: "3em", // Örneğin, 2em genişlik
                height: "3em",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#808080",
                marginRight: "1%",
                paddingLeft: "0.8%",
                "& .MuiChip-icon": {
                  color: "white",
                },
              }}
              icon={<ArrowBackIcon />}
            />
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "white" }}
            >
              Experiments Set up
            </Typography>
          </Grid>
          <Divider
            sx={{
              width: "102%",
              backgroundColor: "#808080",
              marginLeft: "-1%",
            }}
          />
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <TextField
              label="Experiment Name"
              name="experimentName"
              autoComplete="off"
              value={experimentSetUp.experimentName}
              onChange={(e) => handleChange(e)}
              placeholder="Enter experiment name"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <Autocomplete
              multiple
              options={groups}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              name="targetCustomerGroup"
              onChange={(e, newValue) => (
                handleAutoCompleteChange(newValue), console.log(newValue)
              )}
              sx={{
                "& .MuiAutocomplete-tag": {
                  color: "white",
                },
                "& .MuiChip-deleteIcon": {
                  color: " #808080",
                },
                "& .MuiAutocomplete-clearIndicator": { display: "none" },
                "& .MuiAutocomplete-popupIndicator": { color: "#808080" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Experiment target customer group"
                  name="targetCustomerGroup"
                  onChange={(e) => handleChange(e)}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputBase-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(128, 128, 128, 1)",
                    },
                  }}
                />
              )}
            />{" "}
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <TextField
              label="Experiment ID"
              name="id"
              autoComplete="off"
              value={experimentSetUp.id}
              onChange={(e) => handleChange(e)}
              placeholder="Enter experiment name"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ padding: "1%", paddingTop: "2%" }}>
            <TextField
              label="Experiment Traffic"
              name="experimentTraffic"
              autoComplete="off"
              value={experimentSetUp.experimentTraffic}
              onChange={(e) => handleChange(e)}
              placeholder="Enter experiment Traffic"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputBase-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(128, 128, 128, 1)",
                },
              }}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Grid>
          <Grid container xs={6} sx={{ alignItems: "center", padding: "1%" }}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#808080", marginBottom: "2%" }}
              >
                Select Experiment Goal:
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Button
                name="experimentGoal"
                value="conversion"
                onClick={(e) => handleChange(e)}
                variant="contained"
                sx={{
                  backgroundColor:
                    experimentSetUp.experimentGoal === "conversion"
                      ? "#B9D2FF"
                      : "rgba(255, 255, 255, 0.1)",
                  color:
                    experimentSetUp.experimentGoal === "conversion"
                      ? "black"
                      : "#B9D2FF",
                  textTransform: "none",
                  border: "1px solid #B9D2FF",
                  minWidth: "50%",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  fontWeight: 600,
                }}
              >
                Maximize Conversion
              </Button>

              <Button
                name="experimentGoal"
                onClick={(e) => handleChange(e)}
                variant="contained"
                value="revenue"
                sx={{
                  backgroundColor:
                    experimentSetUp.experimentGoal === "revenue"
                      ? "#B9D2FF"
                      : "rgba(255, 255, 255, 0.1)",
                  color:
                    experimentSetUp.experimentGoal === "revenue"
                      ? "black"
                      : "#B9D2FF",
                  textTransform: "none",
                  border: "1px solid #B9D2FF",
                  minWidth: "50%",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  fontWeight: 600,
                  borderLeft: 0,
                }}
              >
                Maximize Average Revenue
              </Button>
            </Grid>
          </Grid>
          <Grid container xs={6} sx={{ alignItems: "center", padding: "1%" }}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontWeight: 500, color: "#808080", marginBottom: "2%" }}
              >
                Select Experiment Type:
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Button
                name="experimentType"
                value="full"
                onClick={(e) => handleChange(e)}
                variant="contained"
                sx={{
                  backgroundColor:
                    experimentSetUp.experimentType === "full"
                      ? "#B9D2FF"
                      : "rgba(255, 255, 255, 0.1)",
                  color:
                    experimentSetUp.experimentType === "full"
                      ? "black"
                      : "#B9D2FF",
                  textTransform: "none",
                  border: "1px solid #B9D2FF",
                  minWidth: "50%",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  fontWeight: 600,
                }}
              >
                Full Plan
              </Button>

              <Button
                name="experimentType"
                onClick={(e) => handleChange(e)}
                variant="contained"
                value="features"
                sx={{
                  backgroundColor:
                    experimentSetUp.experimentType === "features"
                      ? "#B9D2FF"
                      : "rgba(255, 255, 255, 0.1)",
                  color:
                    experimentSetUp.experimentType === "features"
                      ? "black"
                      : "#B9D2FF",
                  textTransform: "none",
                  border: "1px solid #B9D2FF",
                  minWidth: "50%",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  fontWeight: 600,
                  borderLeft: 0,
                }}
              >
                Value Features
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
            padding: "2%",
            paddingTop: "1%",
            paddingBottom: "1%",
          }}
        >
          <Grid item xs={6}>
            {" "}
            <Typography
              sx={{ color: "white", fontSize: "24px", fontWeight: 600 }}
            >
              {experimentSetUp.experimentType == "features"
                ? "Select value feature pricing to experiment:"
                : "Select Subscription Plan:"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ justifyContent: "flex-end", display: "flex" }}
          >
            {" "}
            <TextField
              select
              value={selectedPlanOrFeatures}
              onChange={(e) => {
                setSelectedPlanOrFeatures(e.target.value);
              }}
              sx={{
                minWidth: "40%",
                borderRadius: "4px",
                backgroundColor: "#1F1F1F",
                "& .MuiInputBase-root": {
                  color: "white",
                },
                "& .MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              {experimentSetUp.experimentType == "features"
                ? valueFeatures?.map((feature, index) => (
                    <MenuItem value={index}>{feature.description}</MenuItem>
                  ))
                : subscriptionPlans?.map((plan, index) => (
                    <MenuItem value={index}>{plan.name}</MenuItem>
                  ))}
            </TextField>{" "}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sx={{ flexDirection: "row", display: "flex" }}>
            {" "}
            <Typography
              sx={{
                width: "10%",
                padding: "2%",
                paddingTop: "1%",
                paddingBottom: "1%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  width: "fit-content",
                  backgroundColor: "#171717",
                  borderRadius: "8px",
                  padding: "8%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "24px", fontWeight: 600 }}
              >
                {experimentSetUp.experimentType == "features"
                  ? valueFeatures && valueFeatures.length > 0
                    ? valueFeatures[selectedPlanOrFeatures].description
                    : ""
                  : subscriptionPlans && subscriptionPlans.length > 0
                  ? subscriptionPlans[selectedPlanOrFeatures].name
                  : ""}
              </Typography>
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                      }}
                    >
                      Monthly Subscription
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                        borderRight: 0,
                      }}
                      colSpan={2}
                    >
                      Annual Subscription
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                      }}
                    >
                      Billed Monthly
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                      }}
                    >
                      Billed Monthly
                    </TableCell>{" "}
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                        borderRight: 0,
                      }}
                    >
                      Billed Annually
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        borderBottom: 0,
                        textAlign: "center",
                      }}
                    >
                      <CheckBox />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        borderBottom: 0,
                        textAlign: "center",
                      }}
                    >
                      <CheckBox />
                    </TableCell>{" "}
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        borderBottom: 0,
                        textAlign: "center",
                        borderRight: 0,
                      }}
                    >
                      <CheckBox />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: "white",
            marginBottom: "1%",
            marginTop: "3%",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Select Premiums & Discounts:
        </Typography>
        <Grid
          container
          sx={{
            marginBottom: "16px",
            backgroundColor: "#2E2E2E",
            borderRadius: "8px",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sx={{ flexDirection: "row", display: "flex" }}>
            {" "}
            <Typography
              sx={{
                width: "10%",
                padding: "2%",
                paddingTop: "0.5%",
                paddingBottom: "0.5%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  width: "fit-content",
                  backgroundColor: "#171717",
                  borderRadius: "8px",
                  padding: "8%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "24px", fontWeight: 600 }}
              >
                {experimentSetUp.experimentType == "features"
                  ? valueFeatures && valueFeatures.length > 0
                    ? valueFeatures[selectedPlanOrFeatures].description
                    : ""
                  : subscriptionPlans && subscriptionPlans.length > 0
                  ? subscriptionPlans[selectedPlanOrFeatures].name
                  : ""}
              </Typography>
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                        width: "33.3%",
                      }}
                    >
                      Min Price Discount
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                        width: "33.3%",
                      }}
                    >
                      Discount Annual subscription versus Monthly subscription
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        textAlign: "center",
                        width: "33.3%",
                        borderRight: 0,
                      }}
                    >
                      Premium over annual billing for annual subscription billed
                      monthly
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        borderBottom: 0,
                        textAlign: "center",
                      }}
                    >
                      <CheckBox />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        borderBottom: 0,
                        textAlign: "center",
                      }}
                    >
                      <CheckBox />
                    </TableCell>{" "}
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "rgba(128, 128, 128, 1)",
                        border: "1px solid rgba(128, 128, 128, 0.2)",
                        borderTop: 0,
                        borderBottom: 0,
                        textAlign: "center",
                        borderRight: 0,
                      }}
                    >
                      <CheckBox />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <ArmSets
          plan={
            experimentSetUp.experimentType == "features"
              ? valueFeatures && valueFeatures.length > 0
                ? valueFeatures[selectedPlanOrFeatures].description
                : "Value Features 1"
              : subscriptionPlans && subscriptionPlans.length > 0
              ? subscriptionPlans[selectedPlanOrFeatures].name
              : "Plan 1"
          }
        />
        <MessageBanner message={message} />
      </Grid>
    </>
  );
};

export default ExperimentSetUp;
