/** @format */

import {
  Grid,
  Dialog,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import React from "react";
const ViewValuesModal = ({ open, handleClose, plan }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          backgroundColor: "#1F1F1F",
          borderRadius: "12px",
        },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#1F1F1F",
          borderRadius: "12px",
          alignItems: "center",
    
        }}
      >
        <Typography sx={{fontSize:"24px",fontWeight:600,color:"white",padding:"2%"}}>Values ArmSet 2</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",

                    borderLeft: 0,
                  }}
                >
                  ID:2
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",

                    textAlign: "center",
                  }}
                >
                  Monthly Subscription Price{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    textAlign: "center",
                  }}
                >
                  Annual Subscription billed annually Price{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  Annual subscription billed monthly Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    alignItems: "center",
                    display: "flex",
                    borderTop: 0,
                    borderLeft: 0,
                    gap: 1
                  }}
                >
                  {plan}
                  <Typography
                    sx={{
                      color: "white",
                      backgroundColor: "#2E2E2E",
                      borderRadius: "100px",
                      alignItems: "center",
                      display: "flex",
                      padding:"2%",
                      gap:2
                    }}
                  >
                    {" "}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#808080" />
                      <text
                        x="47%"
                        y="50%"
                        dominant-baseline="middle"
                        text-anchor="middle"
                        font-size="12"
                        fill="white"
                      >
                        1
                      </text>
                    </svg>
                    Variation
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  30$
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                  }}
                >
                  30$
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  30$
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "rgba(128, 128, 128, 1)",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    borderLeft: 0,gap:1,
                  }}
                >
                  Min Price Discount
                  <Typography
                    sx={{
                      color: "white",
                      backgroundColor: "#2E2E2E",
                      borderRadius: "100px",
                      alignItems: "center",
                      display: "flex",
                      padding:"2%",
                      gap:2
                    }}
                  >
                    {" "}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#808080" />
                      <text
                        x="47%"
                        y="50%"
                        dominant-baseline="middle"
                        text-anchor="middle"
                        font-size="12"
                        fill="white"
                      >
                        1
                      </text>
                    </svg>
                    Variation
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                  }}
                >
                  30$
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                  }}
                >
                  30$
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "white",
                    border: "1px solid rgba(128, 128, 128, 0.2)",
                    borderTop: 0,
                    borderBottom: 0,
                    textAlign: "center",
                    borderRight: 0,
                  }}
                >
                  30$
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Dialog>
  );
};

export default ViewValuesModal;
