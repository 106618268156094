/** @format */

import axios from "axios";

export function createNegotiation(
  token,
  customer_id,
  renewalStrategy,
  renew = false
) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const data = {
    renew,
    renewalStrategy,
  };

  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/negotiation/${customer_id}/`,
    data,
    config
  );
}

export function createDummyCustomer(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/negotiaton-dummy-customer/`,
    config
  );
}

export function startDummyNegotiation(
  token,
  subscriptionModel,
  billingPeriod,
  customizations,
  renewalStrategy,
  specialDiscount,
) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const data = {
    subscriptionModel,
    billingPeriod,
    customizations,
    renewalStrategy,
    specialDiscount,
  };

  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/negotiation/negotiaton-dummy-customer/`,
    data,
    config
  );
}

export function listNegotiations(token, customer_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/${customer_id}/`,
    config
  );
}

export function getNegotiationByToken(token) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/bytoken/negotiation/${token}/`
  );
}

export function getValueFeaturesByToken(token) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/bytoken/value-feature/${token}/`
  );
}

export function nextStepByToken(token, data) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/negotiation/bytoken/next-step/${token}/`,
    data
  );
}

export function getOffersByToken(token) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/bytoken/offers/${token}/`
  );
}

export function acceptOfferByToken(token, offerPieceId) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/negotiation/bytoken/accept-offer/${token}/`,
    { offerPieceId }
  );
}
export function getOrderedValueFeaturesByToken(token) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/negotiation/bytoken/ordered-value-features/${token}/`
  );
}
